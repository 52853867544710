import { Button } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { copyrightYear } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { Anchor } from '../../sergeimeza/uikit-react'

const ISMSLogo = () => (
  <img className="w-14" src="/images/PT_ISMS.jpg" alt="isms" />
)

const SignInPage = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const handleSignInBtn = useCallback(() => {
    history.push('/login')
  }, [history])

  const isUsingJp = isJapanese(i18n)

  return (
    <div
      className="w-full h-screen welcome-background"
      style={{
        background: 'url(/images/Welcome.png) no-repeat center center fixed',
      }}
    >
      <div className="flex w-full justify-center pt-10">
        <ruby className="welcome-text-ja">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {isUsingJp && 'ようこそ'}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <rt className="welcome-text-en pt-30">Welcome</rt>
        </ruby>
      </div>
      <div>
        <div className="agreement-text text-center text-white px-3">
          <p className="text-10px">
            {t('続行することで、Pestalozzi Technology株式会社の')}
            <Anchor
              href="https://pestalozzi-tech.com/alpha-terms/"
              className="underline"
            >
              <span className="px-1">{t('サービス利用規約')}</span>
            </Anchor>
            {t('および、')}
            <Anchor
              href="https://pestalozzi-tech.com/privacy-policy/"
              className="underline"
            >
              <span className="px-1">{t('個人情報保護ポリシー')}</span>
            </Anchor>
            {t('に同意します。')}
          </p>
        </div>
        <div className="absolute welcome-button-wrapper bottom-6">
          <Button
            className="rounded-5px welcome-button"
            onClick={handleSignInBtn}
          >
            {t('はじめる')}
          </Button>
        </div>
        <div className="absolute right-3 bottom-2 welcome-sponsor">
          <div className="flex flex-col justify-center items-center">
            <ISMSLogo />
            <div className="flex flex-col justify-center mx-auto mt-1 text-center-f text-xxs text-black">
              <span>
                {t('Pestalozzi Technology株式会社にて認証取得')} <br />
                {t('スポーツ分野におけるアプリケーションの設計・開発')} <br />
                {t('・販売及びサービス提供において認証取得')}
              </span>
            </div>
            <p className="text-black text-xxs">
              Copyright © Pestalozzi Technology {copyrightYear}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignInPage
