import { AnswerType, Question, StudentQuestionExtraText } from '../types'

const customizedExtra25SchoolNames = [
  "宇都宮市立中央小学校",
  "宇都宮市立東小学校",
  "宇都宮市立西小学校",
  "宇都宮市立簗瀬小学校",
  "宇都宮市立西原小学校",
  "宇都宮市立戸祭小学校",
  "宇都宮市立今泉小学校",
  "宇都宮市立昭和小学校",
  "宇都宮市立陽南小学校",
  "宇都宮市立桜小学校",
  "宇都宮市立錦小学校",
  "宇都宮市立細谷小学校",
  "宇都宮市立峰小学校",
  "宇都宮市立富士見小学校",
  "宇都宮市立泉が丘小学校",
  "宇都宮市立平石中央小学校",
  "宇都宮市立平石北小学校",
  "宇都宮市立清原中央小学校",
  "宇都宮市立清原南小学校",
  "宇都宮市立清原北小学校",
  "宇都宮市立清原東小学校",
  "宇都宮市立横川中央小学校",
  "宇都宮市立横川東小学校",
  "宇都宮市立横川西小学校",
  "宇都宮市立瑞穂野北小学校",
  "宇都宮市立瑞穂野南小学校",
  "宇都宮市立豊郷中央小学校",
  "宇都宮市立豊郷南小学校",
  "宇都宮市立豊郷北小学校",
  "宇都宮市立国本中央小学校",
  "宇都宮市立国本西小学校",
  "宇都宮市立城山中央小学校",
  "宇都宮市立明保小学校",
  "宇都宮市立城山西小学校",
  "宇都宮市立城山東小学校",
  "宇都宮市立富屋小学校",
  "宇都宮市立篠井小学校",
  "宇都宮市立姿川中央小学校",
  "宇都宮市立姿川第一小学校",
  "宇都宮市立姿川第二小学校",
  "宇都宮市立雀宮中央小学校",
  "宇都宮市立雀宮東小学校",
  "宇都宮市立雀宮南小学校",
  "宇都宮市立緑が丘小学校",
  "宇都宮市立宮の原小学校",
  "宇都宮市立御幸小学校",
  "宇都宮市立宝木小学校",
  "宇都宮市立城東小学校",
  "宇都宮市立陽東小学校",
  "宇都宮市立海道小学校",
  "宇都宮市立石井小学校",
  "宇都宮市立御幸が原小学校",
  "宇都宮市立五代小学校",
  "宇都宮市立陽光小学校",
  "宇都宮市立瑞穂台小学校",
  "宇都宮市立晃宝小学校",
  "宇都宮市立新田小学校",
  "宇都宮市立西が岡小学校",
  "宇都宮市立上戸祭小学校",
  "宇都宮市立上河内東小学校",
  "宇都宮市立上河内西小学校",
  "宇都宮市立上河内中央小学校",
  "宇都宮市立岡本小学校",
  "宇都宮市立白沢小学校",
  "宇都宮市立田原小学校",
  "宇都宮市立岡本西小学校",
  "宇都宮市立岡本北小学校",
  "宇都宮市立田原西小学校",
  "宇都宮市立ゆいの杜小学校",
  "宇都宮市立一条中学校",
  "宇都宮市立陽北中学校",
  "宇都宮市立旭中学校",
  "宇都宮市立陽南中学校",
  "宇都宮市立陽西中学校",
  "宇都宮市立星が丘中学校",
  "宇都宮市立陽東中学校",
  "宇都宮市立泉が丘中学校",
  "宇都宮市立宮の原中学校",
  "宇都宮市立清原中学校",
  "宇都宮市立横川中学校",
  "宇都宮市立瑞穂野中学校",
  "宇都宮市立豊郷中学校",
  "宇都宮市立国本中学校",
  "宇都宮市立城山中学校",
  "宇都宮市立姿川中学校",
  "宇都宮市立雀宮中学校",
  "宇都宮市立晃陽中学校",
  "宇都宮市立鬼怒中学校",
  "宇都宮市立宝木中学校",
  "宇都宮市立若松原中学校",
  "宇都宮市立上河内中学校",
  "宇都宮市立古里中学校",
  "宇都宮市立田原中学校",
  "宇都宮市立河内中学校",
  '福井県ALPHAデモ学校（小学校）',
  '山梨県ALPHAデモ学校（小学校）'
]

export const getExtraQuestion = (schoolName: string, question: {
  extra?: Record<string, StudentQuestionExtraText>,
  extra25?: Record<string, StudentQuestionExtraText>
}) => {
  return customizedExtra25SchoolNames.includes(schoolName)
    ? question.extra25
    : question.extra
}

/**
 * Copy from `./tools/data-array-to-models`
 */
export const allQuestions: Question[] = [
  {
    id: 1,
    name: '所属状況',
    question: '運動部やスポーツクラブに入っていますか',
    options: { 入っている: 1, 入っていない: 2 },
    kanjiQuestion: [
      { kanji: '運動部', hina: 'うんどうぶ' },
      { kanji: '入', hina: 'はい' },
    ],
    kanjiOption: {
      '1': [{ kanji: '入', hina: 'はい' }],
      '2': [{ kanji: '入', hina: 'はい' }],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 2,
    name: '所属状況',
    question: '運動部やスポーツクラブへの所属状況',
    options: { 所属している: 1, 所属していない: 2 },
    kanjiQuestion: [
      { kanji: '運動部', hina: 'うんどうぶ' },
      { kanji: '所属状況', hina: 'しょぞくじょうきょう' },
    ],
    kanjiOption: {
      '1': [{ kanji: '所属', hina: 'しょぞく' }],
      '2': [{ kanji: '所属', hina: 'しょぞく' }],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 3,
    name: '所属の種類',
    question:
      'あなたはどのようなところに所属して運動やスポーツを行なっていますか',
    options: {
      運動部に所属している: 1,
      スポーツクラブに所属している: 2,
      運動部とスポーツクラブに所属している: 3,
      どちらにも所属していない: 4,
    },
    kanjiQuestion: [
      { kanji: '所属', hina: 'しょぞく' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '行', hina: 'おこ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '運動部', hina: 'うんどうぶ' },
        { kanji: '所属', hina: 'しょぞく' },
      ],
      '2': [{ kanji: '所属', hina: 'しょぞく' }],
      '3': [
        { kanji: '運動部', hina: 'うんどうぶ' },
        { kanji: '所属', hina: 'しょぞく' },
      ],
      '4': [{ kanji: '所属', hina: 'しょぞく' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 4,
    name: '運動の頻度',
    question:
      '運動・スポーツはどのくらいしていますか（学校の体育の授業を除く）',
    options: { 週3日以上: 1, '週1~2日程度': 2, '月1~2日程度': 3, しない: 4 },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '除', hina: 'のぞ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '3': [
        { kanji: '月', hina: 'つき' },
        { kanji: '日', hina: 'にち' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 5,
    name: '運動の頻度',
    question:
      '運動・スポーツはどのくらいしていますか（学校の体育の授業を除く）',
    options: { 週3日以上: 1, '週1~2日程度': 2, '月1~3日程度': 3, しない: 4 },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '除', hina: 'のぞ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '3': [
        { kanji: '月', hina: 'つき' },
        { kanji: '日', hina: 'にち' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 6,
    name: '1日の運動時間',
    question:
      '運動やスポーツをするときには1日にどのくらいの時間しますか（学校の体育の授業を除く）',
    options: {
      '30分未満': 1,
      '30分以上1時間未満': 2,
      '1時間以上2時間未満': 3,
      '2時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '除', hina: 'のぞ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分', hina: 'ふん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '分', hina: 'ふん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 7,
    name: '学校内での運動の頻度',
    question:
      '学校にいるとき、授業以外（授業の始まる前、休み時間、放課後）に運動（体を動かす遊びを含む）やスポーツをどのくらいしていますか',
    options: {
      週に3日以上: 1,
      '週に1~2日程度': 2,
      '月に1~3日程度': 3,
      しない: 4,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '以外', hina: 'いがい' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '始', hina: 'はじ' },
      { kanji: '前', hina: 'まえ' },
      { kanji: '休', hina: 'やす' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '放課後', hina: 'ほうかご' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '3': [
        { kanji: '月', hina: 'つき' },
        { kanji: '日', hina: 'にち' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 8,
    name: '学校内での運動の時間',
    question:
      '学校にいるとき、授業以外（授業の始まる前、休み時間、放課後）に運動（体を動かす遊びを含む）やスポーツを1日にどれくらいの時間しますか\n※「しない」と回答した人は「回答なし」を選択してください',
    options: { '1~29分': 1, '30~59分': 2, '1時間以上': 3, 回答なし: 4 },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '以外', hina: 'いがい' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '始', hina: 'はじ' },
      { kanji: '前', hina: 'まえ' },
      { kanji: '休', hina: 'やす' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '放課後', hina: 'ほうかご' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [{ kanji: '分', hina: 'ふん' }],
      '2': [{ kanji: '分', hina: 'ふん' }],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '4': [{ kanji: '回答', hina: 'かいとう' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 9,
    name: '学校外での運動の頻度',
    question:
      '学校に行く前や学校から帰った後（平日で学校にいない時間）に、運動（体を動かす遊びを含む）やスポーツをどのくらい行っていますか',
    options: {
      週に3日以上: 1,
      '週に1~2日程度': 2,
      '月に1~3日程度': 3,
      しない: 4,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '行', hina: 'い' },
      { kanji: '前', hina: 'まえ' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '帰', hina: 'かえ' },
      { kanji: '後', hina: 'あと' },
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '行', hina: 'おこな' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '3': [
        { kanji: '月', hina: 'つき' },
        { kanji: '日', hina: 'にち' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 10,
    name: '学校外での運動の時間',
    question:
      '学校に行く前や学校から帰った後（平日で学校にいない時間）に、運動（体を動かす遊びを含む）やスポーツを1日にどれくらいの時間しますか\n※「しない」と回答した人は「回答なし」を選択してください',
    options: { '1~29分': 1, '30~59分': 2, '1時間以上': 3, 回答なし: 4 },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '行', hina: 'い' },
      { kanji: '前', hina: 'まえ' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '帰', hina: 'かえ' },
      { kanji: '後', hina: 'あと' },
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [{ kanji: '分', hina: 'ふん' }],
      '2': [{ kanji: '分', hina: 'ふん' }],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '4': [{ kanji: '回答', hina: 'かいとう' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 11,
    name: '1日の運動時間',
    question:
      '運動やスポーツをするときは、1日どのくらいの時間しますか（学校の体育の授業を除く）\n※「しない」と回答した人は「回答なし」を選択してください',
    options: { '0~29分': 1, '30~59分': 2, '1時間以上': 3, 回答なし: 4 },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '除', hina: 'のぞ' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [{ kanji: '分', hina: 'ふん' }],
      '2': [{ kanji: '分', hina: 'ふん' }],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '4': [{ kanji: '回答', hina: 'かいとう' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 12,
    name: '休日の運動',
    question: '土曜日や日曜日に運動やスポーツをしていますか',
    options: { している: 1, していない: 2 },
    kanjiQuestion: [
      { kanji: '土曜日', hina: 'どようび' },
      { kanji: '日曜日', hina: 'にちようび' },
      { kanji: '運動', hina: 'うんどう' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 13,
    name: '休日の運動頻度',
    question:
      '土曜日と日曜日に運動（体を動かす遊びを含む）やスポーツをどれくらいしていますか',
    options: {
      '2日（土曜日と日曜日の両方）': 1,
      '1日（土曜日か日曜日のどちらか1日）': 2,
      '0日（ほとんどしていない）': 3,
    },
    kanjiQuestion: [
      { kanji: '土曜日', hina: 'どようび' },
      { kanji: '日曜日', hina: 'にちようび' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '日', hina: 'にち' },
        { kanji: '土曜日', hina: 'どようび' },
        { kanji: '日曜日', hina: 'にちようび' },
        { kanji: '両方', hina: 'りょうほう' },
      ],
      '2': [
        { kanji: '日', hina: 'にち' },
        { kanji: '土曜日', hina: 'どようび' },
        { kanji: '日曜日', hina: 'にちようび' },
        { kanji: '日', hina: 'にち' },
      ],
      '3': [{ kanji: '日', hina: 'にち' }],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 14,
    name: '休日の運動時間',
    question:
      '土曜日と日曜日に運動（体を動かす遊びを含む）やスポーツを1日にどれくらいの時間していますか\n※「0日（ほとんどしていない）」と回答した人は「回答なし」を選択してください',
    options: {
      '1~29分': 1,
      '30~59分': 2,
      '1時間~1時間59分': 3,
      '2時間以上': 4,
      回答なし: 5,
    },
    kanjiQuestion: [
      { kanji: '土曜日', hina: 'どようび' },
      { kanji: '日曜日', hina: 'にちようび' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '日', hina: 'にち' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [{ kanji: '分', hina: 'ふん' }],
      '2': [{ kanji: '分', hina: 'ふん' }],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '分', hina: 'ふん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 15,
    name: '休日の運動',
    question:
      '休み時間や放課後、休日などに自分から進んで運動するようにしていますか',
    options: {
      している: 1,
      少ししている: 2,
      あまりしていない: 3,
      していない: 4,
    },
    kanjiQuestion: [
      { kanji: '休', hina: 'やす' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '放課後', hina: 'ほうかご' },
      { kanji: '休日', hina: 'きゅうじつ' },
      { kanji: '自分', hina: 'じぶん' },
      { kanji: '進', hina: 'すす' },
      { kanji: '運動', hina: 'うんどう' },
    ],
    kanjiOption: {
      '1': [],
      '2': [{ kanji: '少', hina: 'すこ' }],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 16,
    name: '朝食の有無',
    question: '朝食は食べますか',
    options: { 毎日食べる: 1, 時々食べない: 2, 毎日食べない: 3 },
    kanjiQuestion: [
      { kanji: '朝食', hina: 'ちょうしょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '食', hina: 'た' },
      ],
      '3': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 17,
    name: '朝昼晩3食食べるか',
    question: '朝昼晩3食きちんと食べていますか',
    options: { きちんと食べる: 1, 時々食べない: 2, 毎日食べない: 3 },
    kanjiQuestion: [
      { kanji: '朝昼晩', hina: 'あさひるばん' },
      { kanji: '食', hina: 'しょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [{ kanji: '食', hina: 'た' }],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '食', hina: 'た' },
      ],
      '3': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 18,
    name: '規則正しい食事は大切か',
    question: '規則正しく1日3度食事することは大切であると思いますか',
    options: { 思う: 1, やや思う: 2, あまり思わない: 3, 思わない: 4 },
    kanjiQuestion: [
      { kanji: '規則', hina: 'きそく' },
      { kanji: '正', hina: 'ただ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '度', hina: 'ど' },
      { kanji: '食事', hina: 'しょくじ' },
      { kanji: '大切', hina: 'たいせつ' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [{ kanji: '思', hina: 'おも' }],
      '2': [{ kanji: '思', hina: 'おも' }],
      '3': [{ kanji: '思', hina: 'おも' }],
      '4': [{ kanji: '思', hina: 'おも' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 19,
    name: '好き嫌いしないで食べているか',
    question: '好き嫌いをしないで食べていますか',
    options: {
      食べている: 1,
      だいたい食べている: 2,
      あまり食べていない: 3,
      食べていない: 4,
    },
    kanjiQuestion: [
      { kanji: '好', hina: 'す' },
      { kanji: '嫌', hina: 'きら' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [{ kanji: '食', hina: 'た' }],
      '2': [{ kanji: '食', hina: 'た' }],
      '3': [{ kanji: '食', hina: 'た' }],
      '4': [{ kanji: '食', hina: 'た' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 20,
    name: '食事のマナーに関心があるか',
    question: '食事のマナーに関心がありますか',
    options: { ある: 1, ややある: 2, あまりない: 3, ない: 4 },
    kanjiQuestion: [
      { kanji: '食事', hina: 'しょくじ' },
      { kanji: '関心', hina: 'かんしん' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 21,
    name: '1日の睡眠時間',
    question: '1日の睡眠時間はどのくらいですか',
    options: { '6時間未満': 1, '6時間以上8時間未満': 2, '8時間以上': 3 },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '睡眠時間', hina: 'すいみんじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 22,
    name: '快眠できている日数',
    question: 'ぐっすり眠る（よく眠る）ことができますか',
    options: { 週に6日以上: 1, '週に4~5日': 2, '週に2~3日': 3, 週に1日以下: 4 },
    kanjiQuestion: [
      { kanji: '眠', hina: 'ねむ' },
      { kanji: '眠', hina: 'ねむ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
      ],
      '3': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
      ],
      '4': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '以下', hina: 'いか' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 23,
    name: '平日の就寝時間',
    question: '平日の就寝時間は何時ですか',
    options: {
      午後9時前: 1,
      午後9時から午後10時前: 2,
      午後10時から午後11時前: 3,
      午後11時から午前0時前: 4,
      午前0時から午前1時前: 5,
      午前1時以降: 6,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '就寝時間', hina: 'しゅうしんじかん' },
      { kanji: '何時', hina: 'なんじ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '午後', hina: 'ごご' },
        { kanji: '時', hina: 'じ' },
        { kanji: '前', hina: 'まえ' },
      ],
      '2': [
        { kanji: '午後', hina: 'ごご' },
        { kanji: '時', hina: 'じ' },
        { kanji: '午後', hina: 'ごご' },
        { kanji: '時', hina: 'じ' },
        { kanji: '前', hina: 'まえ' },
      ],
      '3': [
        { kanji: '午後', hina: 'ごご' },
        { kanji: '時', hina: 'じ' },
        { kanji: '午後', hina: 'ごご' },
        { kanji: '時', hina: 'じ' },
        { kanji: '前', hina: 'まえ' },
      ],
      '4': [
        { kanji: '午後', hina: 'ごご' },
        { kanji: '時', hina: 'じ' },
        { kanji: '午前', hina: 'ごぜん' },
        { kanji: '時', hina: 'じ' },
        { kanji: '前', hina: 'まえ' },
      ],
      '5': [
        { kanji: '午前', hina: 'こぜん' },
        { kanji: '時', hina: 'じ' },
        { kanji: '午前', hina: 'ごぜん' },
        { kanji: '時', hina: 'じ' },
        { kanji: '前', hina: 'まえ' },
      ],
      '6': [
        { kanji: '午前', hina: 'ごぜん' },
        { kanji: '時', hina: 'じ' },
        { kanji: '以降', hina: 'いこう' },
      ],
      '7': [],
      '8': [],
    },
  },
  {
    id: 24,
    name: '早寝早起きを心がけているか',
    question: '早寝早起きを心がけていますか',
    options: {
      心がけている: 1,
      少し心がけている: 2,
      あまり心がけていない: 3,
      心がけていない: 4,
    },
    kanjiQuestion: [
      { kanji: '早寝早起', hina: 'はやねはやお' },
      { kanji: '心', hina: 'こころ' },
    ],
    kanjiOption: {
      '1': [{ kanji: '心', hina: 'こころ' }],
      '2': [
        { kanji: '少し', hina: 'すこ' },
        { kanji: '心', hina: 'こころ' },
      ],
      '3': [{ kanji: '心', hina: 'こころ' }],
      '4': [{ kanji: '心', hina: 'こころ' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 25,
    name: '1日のテレビ視聴時間',
    question:
      '1日どのくらいテレビをみますか（ゲーム、スマートフォン、パソコンなどを含む）',
    options: {
      '1時間未満': 1,
      '1時間以上2時間未満': 2,
      '2時間以上3時間未満': 3,
      '3時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 26,
    name: '1日の携帯電話の使用時間',
    question:
      '1日にどれくらいの時間、携帯電話やスマートフォンで、通話やメール、及びインターネットをしますか',
    options: {
      使用しない: 1,
      '1時間未満': 2,
      '1時間以上2時間未満': 3,
      '2時間以上3時間未満': 4,
      '3時間以上': 5,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '携帯電話', hina: 'けいたいでんわ' },
      { kanji: '通話', hina: 'つうわ' },
      { kanji: '及', hina: 'およ' },
    ],
    kanjiOption: {
      '1': [{ kanji: '使用', hina: 'しよう' }],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 27,
    name: '保護者との運動・スポーツ時間',
    question:
      '一週間にどれくらいの時間、保護者と一緒に運動やスポーツしたり、運動やスポーツの会話をしたり、スポーツ観戦（テレビも含む）をしたりしていますか（運動部やスポーツクラブへの送迎時間は含まない）',
    options: {
      '30分未満': 1,
      '30分以上1時間未満': 2,
      '1時間以上1時間30未満': 3,
      '1時間30分以上2時間未満': 4,
      '2時間以上2時間30分未満': 5,
      '2時間30分以上': 6,
    },
    kanjiQuestion: [
      { kanji: '一週間', hina: 'いっしゅうかん' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '保護者', hina: 'ほごしゃ' },
      { kanji: '一緒', hina: 'いっしょ' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '会話', hina: 'かいわ' },
      { kanji: '観戦', hina: 'かんせん' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '運動部', hina: 'うんどうぶ' },
      { kanji: '送迎時間', hina: 'そうげいじかん' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '分', hina: 'ぷん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '分', hina: 'ぷん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '6': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '分', hina: 'ぷん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '7': [],
      '8': [],
    },
  },
  {
    id: 28,
    name: 'かぜをひく頻度',
    question: 'よくかぜをひきますか',
    options: {
      '月に2～3回ひく': 1,
      月に1回くらいひく: 2,
      季節に1回くらいひく: 3,
      '年に1～2回くらいひく': 4,
    },
    kanjiQuestion: [],
    kanjiOption: {
      '1': [
        { kanji: '月', hina: 'つき' },
        { kanji: '回', hina: 'かい' },
      ],
      '2': [
        { kanji: '月', hina: 'つき' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
        { kanji: '季節', hina: 'きせつ' },
        { kanji: '回', hina: 'かい' },
      ],
      '4': [
        { kanji: '年', hina: 'ねん' },
        { kanji: '回', hina: 'かい' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 29,
    name: '異常があればすぐに治療しているか',
    question: '健康診断で指摘されたところは、早めに治療していますか',
    options: {
      している: 1,
      ある程度している: 2,
      ほとんどしていない: 3,
      していない: 4,
    },
    kanjiQuestion: [
      { kanji: '健康診断', hina: 'けんこうしんんだん' },
      { kanji: '指摘', hina: 'してき' },
      { kanji: '早', hina: 'はや' },
      { kanji: '治療', hina: 'ちりょう' },
    ],
    kanjiOption: {
      '1': [],
      '2': [{ kanji: '程度', hina: 'ていど' }],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 30,
    name: '健康や体力に自信があるか',
    question: '健康や体力に自信がありますか',
    options: {
      自信がある: 1,
      やや自信がある: 2,
      やや自信がない: 3,
      自信がない: 4,
    },
    kanjiQuestion: [
      { kanji: '健康', hina: 'けんこう' },
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '自信', hina: 'じしん' },
    ],
    kanjiOption: {
      '1': [{ kanji: '自信', hina: 'じしん' }],
      '2': [{ kanji: '自信', hina: 'じしん' }],
      '3': [{ kanji: '自信', hina: 'じしん' }],
      '4': [{ kanji: '自信', hina: 'じしん' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 31,
    name: '体力は必要だと思うか',
    question: '体力は必要だと思いますか',
    options: {
      とても必要だと思う: 1,
      必要だと思う: 2,
      あまり必要だと思わない: 3,
      必要だと思わない: 4,
    },
    kanjiQuestion: [
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '必要', hina: 'ひつよう' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '必要', hina: 'ひつよう' },
        { kanji: '思', hina: 'おも' },
      ],
      '2': [
        { kanji: '必要', hina: 'ひつよう' },
        { kanji: '思', hina: 'おも' },
      ],
      '3': [
        { kanji: '必要', hina: 'ひつよう' },
        { kanji: '思', hina: 'おも' },
      ],
      '4': [
        { kanji: '必要', hina: 'ひつよう' },
        { kanji: '思', hina: 'おも' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 32,
    name: '体育の授業は好きか',
    question: '体育の授業は好きですか',
    options: { たいへん好き: 1, 好き: 2, あまり好きではない: 3, きらい: 4 },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [{ kanji: '好', hina: 'す' }],
      '2': [{ kanji: '好', hina: 'す' }],
      '3': [{ kanji: '好', hina: 'す' }],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 33,
    name: '体育の授業は楽しいか',
    question: '体育の授業は楽しいですか',
    options: { 楽しい: 1, やや楽しい: 2, あまり楽しくない: 3, 楽しくない: 4 },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '楽', hina: 'たの' },
    ],
    kanjiOption: {
      '1': [{ kanji: '楽', hina: 'たの' }],
      '2': [{ kanji: '楽', hina: 'たの' }],
      '3': [{ kanji: '楽', hina: 'たの' }],
      '4': [{ kanji: '楽', hina: 'たの' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 34,
    name: '運動は好きか',
    question: '運動やスポーツをすることは好きですか',
    options: { 大変好き: 1, まあまあ好き: 2, ふつう: 3, きらい: 4 },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '大変', hina: 'たいへん' },
        { kanji: '好', hina: 'す' },
      ],
      '2': [{ kanji: '好', hina: 'す' }],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 35,
    name: '運動は好きか',
    question: '運動やスポーツをすることは好きですか',
    options: { 好き: 1, やや好き: 2, ややきらい: 3, きらい: 4 },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [{ kanji: '好', hina: 'す' }],
      '2': [{ kanji: '好', hina: 'す' }],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 36,
    name: '運動は得意か',
    question: '運動やスポーツをすることが得意ですか',
    options: { 得意: 1, やや得意: 2, やや不得意: 3, 不得意: 4 },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '得意', hina: 'とくい' },
    ],
    kanjiOption: {
      '1': [{ kanji: '得意', hina: 'とくい' }],
      '2': [{ kanji: '得意', hina: 'とくい' }],
      '3': [{ kanji: '不得意', hina: 'ふとくい' }],
      '4': [{ kanji: '不得意', hina: 'ふとくい' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 37,
    name: '小学校入学以前の外遊びの頻度',
    question: '小学校入学前はどのくらい外で体を動かす遊びをしていましたか',
    options: {
      週に6日以上: 1,
      '週に4~5日程度': 2,
      '週に2~3日程度': 3,
      週に1日以下: 4,
    },
    kanjiQuestion: [
      { kanji: '小学校', hina: 'しょうがっこう' },
      { kanji: '入学前', hina: 'にゅうがくまえ' },
      { kanji: '外', hina: 'そと' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '3': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '4': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '以下', hina: 'いか' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 38,
    name: '外遊びの頻度',
    question: '外遊びをどのくらいしていますか',
    options: { 週3日以上: 1, '週1~2日程度': 2, '月1~3日程度': 3, しない: 4 },
    kanjiQuestion: [{ kanji: '外遊', hina: 'そとあそ' }],
    kanjiOption: {
      '1': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '3': [
        { kanji: '月', hina: 'げつ' },
        { kanji: '日', hina: 'か' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 39,
    name: '外遊びをする時間帯',
    question:
      'どのような時間に外遊びを行いますか\n※「しない」と答えた人は「回答なし」を選択してください',
    options: {
      '始業前（登校後）': 1,
      昼休み: 2,
      放課後: 3,
      下校後: 4,
      回答なし: 5,
    },
    kanjiQuestion: [
      { kanji: '時間', hina: 'じかん' },
      { kanji: '外遊', hina: 'そとあそ' },
      { kanji: '行', hina: 'おこな' },
      { kanji: '答', hina: 'こた' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '始業前', hina: 'しぎょうまえ' },
        { kanji: '登校後', hina: 'とうこうご' },
      ],
      '2': [
        { kanji: '昼', hina: 'ひる' },
        { kanji: '休', hina: 'やす' },
      ],
      '3': [{ kanji: '放課後', hina: 'ほうかご' }],
      '4': [{ kanji: '下校後', hina: 'げこうご' }],
      '5': [{ kanji: '回答', hina: 'かいとう' }],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 40,
    name: '最後までやり遂げたいと思うか',
    question: 'なんでも最後までやり遂げたいと思う',
    options: {
      とてもそう思う: 1,
      まあそう思う: 2,
      あまりそう思わない: 3,
      まったくそう思わない: 4,
    },
    kanjiQuestion: [
      { kanji: '最後', hina: 'さいご' },
      { kanji: '遂', hina: 'と' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [{ kanji: '思', hina: 'おも' }],
      '2': [{ kanji: '思', hina: 'おも' }],
      '3': [{ kanji: '思', hina: 'おも' }],
      '4': [{ kanji: '思', hina: 'おも' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 41,
    name: '運動能力向上に向けて目標の設定',
    question:
      '体力テストの結果や体力・運動能力の向上について、自分なりの目標を立てていますか',
    options: { 目標を立てている: 1, 目標を立てていない: 2 },
    kanjiQuestion: [
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '結果', hina: 'けっか' },
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '運動能力', hina: 'うんどうのうりょく' },
      { kanji: '向上', hina: 'こうじょう' },
      { kanji: '自分', hina: 'じぶん' },
      { kanji: '目標', hina: 'もくひょう' },
      { kanji: '立', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '目標', hina: 'もくひょう' },
        { kanji: '立', hina: 'た' },
      ],
      '2': [
        { kanji: '目標', hina: 'もくひょう' },
        { kanji: '立', hina: 'た' },
      ],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 42,
    name: '通学時の交通手段',
    question: 'どのような交通手段で通学していますか',
    options: {
      徒歩: 1,
      バスや電車などの公共交通機関: 2,
      '自家用車・バイク（家族等の送迎）': 3,
      その他: 4,
    },
    kanjiQuestion: [
      { kanji: '交通手段', hina: 'こうつうしゅだん' },
      { kanji: '通学', hina: 'つうがく' },
    ],
    kanjiOption: {
      '1': [{ kanji: '徒歩', hina: 'とほ' }],
      '2': [
        { kanji: '電車', hina: 'でんしゃ' },
        { kanji: '公共交通機関', hina: 'こうきょうきかん' },
      ],
      '3': [
        { kanji: '自家用車', hina: 'じかようしゃ' },
        { kanji: '家族', hina: 'かぞく' },
        { kanji: '等', hina: 'とう' },
        { kanji: '送迎', hina: 'そうげい' },
      ],
      '4': [{ kanji: '他', hina: 'た' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 43,
    name: '「みんながスポーツ1130県民運動」',
    question:
      '宮崎県が取り組んでいる「みんながスポーツ1130県民運動」（1週間に1回以上、30分以上は運動・スポーツをしよう）について知っていますか',
    options: { 知っている: 1, 聞いたことはある: 2, 全く知らない: 3 },
    kanjiQuestion: [
      { kanji: '宮崎県', hina: 'みやざきけん' },
      { kanji: '取', hina: 'と' },
      { kanji: '組', hina: 'く' },
      { kanji: '県民運動', hina: 'けんみんうんどう' },
      { kanji: '週間', hina: 'しゅうかん' },
      { kanji: '回', hina: 'かい' },
      { kanji: '以上', hina: 'いじょう' },
      { kanji: '分', hina: 'ぷん' },
      { kanji: '以上', hina: 'いじょう' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '知', hina: 'し' },
    ],
    kanjiOption: {
      '1': [{ kanji: '知', hina: 'し' }],
      '2': [{ kanji: '聞', hina: 'き' }],
      '3': [
        { kanji: '全', hina: 'まった' },
        { kanji: '知', hina: 'し' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 45,
    name: '所属している部活動',
    question: '学校内で所属している部活動',
    options: { '選択式（別シート参照）': 1 },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '内', hina: 'ない' },
      { kanji: '所属', hina: 'しょぞく' },
      { kanji: '部活動', hina: 'ぶかつどう' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '選択式', hina: 'せんたくしき' },
        { kanji: '別', hina: 'べつ' },
        { kanji: '参照', hina: 'さんしょう' },
      ],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
    extra: {
      '1': {
        name: '所属していない',
        hiragana: '所属していない_しょぞくしていない',
      },
      '2': {
        name: 'バレーボール',
        hiragana: 'バレーボール',
      },
      '3': {
        name: 'バスケットボール',
        hiragana: 'バスケットボール',
      },
      '4': {
        name: 'ハンドボール',
        hiragana: 'ハンドボール',
      },
      '5': {
        name: 'サッカー',
        hiragana: 'サッカー',
      },
      '6': {
        name: 'ラグビー',
        hiragana: 'ラグビー',
      },
      '7': {
        name: '陸上（走競技）',
        hiragana: '陸上（走競技）_りくじょう（そうきょうぎ）',
      },
      '8': {
        name: '陸上（跳競技）',
        hiragana: '陸上（跳競技）_りくじょう（ちょうきょうぎ）',
      },
      '9': {
        name: '陸上（投競技）',
        hiragana: '陸上（投競技）_りくじょう（とうきょうぎ）',
      },
      '10': {
        name: '水泳',
        hiragana: '水泳_すいえい',
      },
      '11': {
        name: '飛び込み',
        hiragana: '飛び込み_とびこみ',
      },
      '12': {
        name: 'アーティスティックスイミング',
        hiragana: 'アーティスティックスイミング',
      },
      '13': {
        name: '器械体操',
        hiragana: '器械体操_きかいたいそう',
      },
      '14': {
        name: '柔道',
        hiragana: '柔道_じゅうどう',
      },
      '15': {
        name: '剣道',
        hiragana: '剣道_けんどう',
      },
      '16': {
        name: 'ソフトテニス',
        hiragana: 'ソフトテニス',
      },
      '17': {
        name: '硬式テニス',
        hiragana: '硬式テニス_こうしきテニス',
      },
      '18': {
        name: '卓球',
        hiragana: '卓球_たっきゅう',
      },
      '19': {
        name: 'バドミントン',
        hiragana: 'バドミントン',
      },
      '20': {
        name: '軟式野球',
        hiragana: '軟式野球_なんしきやきゅう',
      },
      '21': {
        name: '硬式野球',
        hiragana: '硬式野球_こうしきやきゅう',
      },
      '22': {
        name: 'ソフトボール',
        hiragana: 'ソフトボール',
      },
      '23': {
        name: 'スキー（アルペン）',
        hiragana: 'スキー（アルペン）',
      },
      '24': {
        name: 'スキー（ジャンプ）',
        hiragana: 'スキー（ジャンプ）',
      },
      '25': {
        name: 'スキー（ノルディック）',
        hiragana: 'スキー（ノルディック）',
      },
      '26': {
        name: 'スケート（スピード）',
        hiragana: 'スケート（スピード）',
      },
      '27': {
        name: 'スケート（フィギュア）',
        hiragana: 'スケート（フィギュア）',
      },
      '28': {
        name: '新体操',
        hiragana: '新体操_しんたいそう',
      },
      '29': {
        name: 'ホッケー',
        hiragana: 'ホッケー',
      },
      '30': {
        name: 'レスリング',
        hiragana: 'レスリング',
      },
      '31': {
        name: '相撲',
        hiragana: '相撲_すもう',
      },
      '32': {
        name: '弓道',
        hiragana: '弓道_きゅうどう',
      },
      '33': {
        name: 'ウエイトリフティング',
        hiragana: 'ウエイトリフティング',
      },
      '34': {
        name: 'ボクシング',
        hiragana: 'ボクシング',
      },
      '35': {
        name: 'アーチェリー',
        hiragana: 'アーチェリー',
      },
      '36': {
        name: 'フェンシング',
        hiragana: 'フェンシング',
      },
      '37': {
        name: 'アイスホッケー',
        hiragana: 'アイスホッケー',
      },
      '38': {
        name: '創作ダンス',
        hiragana: '創作ダンス_そうさくダンス',
      },
      '39': {
        name: '空手',
        hiragana: '空手_からて',
      },
      '40': {
        name: '少林寺拳法',
        hiragana: '少林寺拳法_しょうりんじけんぽう',
      },
      '41': {
        name: '水球',
        hiragana: '水球_すいきゅう',
      },
      '42': {
        name: 'ボート・漕艇',
        hiragana: 'ボート・漕艇_ボート・そうてい',
      },
      '43': {
        name: '登山',
        hiragana: '登山_とざん',
      },
      '44': {
        name: 'カッター',
        hiragana: 'カッター',
      },
      '45': {
        name: '登山部',
        hiragana: '登山部_とざんぶ',
      },
      '46': {
        name: 'クライミング',
        hiragana: 'クライミング',
      },
      '47': {
        name: 'ワンゲル',
        hiragana: 'ワンゲル',
      },
      '48': {
        name: 'スキー',
        hiragana: 'スキー',
      },
      '49': {
        name: 'スケート',
        hiragana: 'スケート',
      },
      '50': {
        name: 'アメリカンフットボール',
        hiragana: 'アメリカンフットボール',
      },
      '51': {
        name: 'ゴルフ',
        hiragana: 'ゴルフ',
      },
      '52': {
        name: 'バトントワリング',
        hiragana: 'バトントワリング',
      },
      '53': {
        name: 'ヨット',
        hiragana: 'ヨット',
      },
      '54': {
        name: 'カヌー',
        hiragana: 'カヌー',
      },
      '55': {
        name: 'オリエンテーリング',
        hiragana: 'オリエンテーリング',
      },
      '56': {
        name: 'エアライフル',
        hiragana: 'エアライフル',
      },
      '57': {
        name: 'トランポリン',
        hiragana: 'トランポリン',
      },
      '58': {
        name: 'バトン',
        hiragana: 'バトン',
      },
      '59': {
        name: 'アスレティック',
        hiragana: 'アスレティック',
      },
      '60': {
        name: 'なぎなた',
        hiragana: 'なぎなた',
      },
      '61': {
        name: '応援',
        hiragana: '応援_おうえん',
      },
      '62': {
        name: '馬術',
        hiragana: '馬術_ばじゅつ',
      },
      '63': {
        name: '自転車',
        hiragana: '自転車_じてんしゃ',
      },
      '64': {
        name: 'チアリーディング',
        hiragana: 'チアリーディング',
      },
      '65': {
        name: 'フットサル',
        hiragana: 'フットサル',
      },
      '66': {
        name: '合気道',
        hiragana: '合気道_あいきどう',
      },
      '67': {
        name: '運動部マネージャー',
        hiragana: '運動部マネージャー_うんどうぶマネージャー',
      },
      '68': {
        name: 'その他運動部',
        hiragana: 'その他運動部_そのたうんどうぶ',
      },
      '69': {
        name: '音楽',
        hiragana: '音楽_おんがく',
      },
      '70': {
        name: '吹奏楽',
        hiragana: '吹奏楽_すいそうがく',
      },
      '71': {
        name: '合唱',
        hiragana: '合唱_がっしょう',
      },
      '72': {
        name: '演劇',
        hiragana: '演劇_えんげき',
      },
      '73': {
        name: '家庭科',
        hiragana: '家庭科_かていか',
      },
      '74': {
        name: '書道',
        hiragana: '書道_しょどう',
      },
      '75': {
        name: '華道',
        hiragana: '華道_かどう',
      },
      '76': {
        name: '茶道',
        hiragana: '茶道_さどう',
      },
      '77': {
        name: '科学',
        hiragana: '科学_かがく',
      },
      '78': {
        name: 'ESS',
        hiragana: 'ESS_ESS',
      },
      '79': {
        name: '囲碁・将棋',
        hiragana: '囲碁・将棋_いご・しょうぎ',
      },
      '80': {
        name: '放送',
        hiragana: '放送_ほうそう',
      },
      '81': {
        name: '新聞',
        hiragana: '新聞_しんぶん',
      },
      '82': {
        name: '写真',
        hiragana: '写真_しゃしん',
      },
      '83': {
        name: '美術',
        hiragana: '美術_びじゅつ',
      },
      '84': {
        name: '生物',
        hiragana: '生物_せいぶつ',
      },
      '85': {
        name: 'パソコン',
        hiragana: 'パソコン',
      },
      '86': {
        name: 'ボランティア',
        hiragana: 'ボランティア',
      },
      '87': {
        name: 'その他文化部',
        hiragana: 'その他文化部_そのたぶんかぶ',
      },
    },
    extra25: {
      '1': {
        name: 'サッカー',
        hiragana: 'サッカー',
      },
      '2': {
        name: '野球',
        hiragana: '野球_やきゅう',
      },
      '3': {
        name: '軟式野球',
        hiragana: '軟式野球_なんしきやきゅう',
      },
      '4': {
        name: 'バスケットボール',
        hiragana: 'バスケットボール',
      },
      '5': {
        name: 'バレーボール',
        hiragana: 'バレーボール',
      },
      '6': {
        name: 'テニス',
        hiragana: 'テニス',
      },
      '7': {
        name: 'ソフトテニス',
        hiragana: 'ソフトテニス',
      },
      '8': {
        name: 'バドミントン',
        hiragana: 'バドミントン',
      },
      '9': {
        name: '卓球',
        hiragana: '卓球_たっきゅう',
      },
      '10': {
        name: '陸上',
        hiragana: '陸上_りくじょう',
      },
      '11': {
        name: 'ハンドボール',
        hiragana: 'ハンドボール',
      },
      '12': {
        name: '剣道',
        hiragana: '剣道_けんどう',
      },
      '13': {
        name: '柔道',
        hiragana: '柔道_じゅうどう',
      },
      '14': {
        name: '空手',
        hiragana: '空手_からて',
      },
      '15': {
        name: '弓道',
        hiragana: '弓道_きゅうどう',
      },
      '16': {
        name: '水泳',
        hiragana: '水泳_すいえい',
      },
      '17': {
        name: 'ソフトボール',
        hiragana: 'ソフトボール',
      },
      '18': {
        name: '体操',
        hiragana: '体操_たいそう',
      },
      '19': {
        name: '新体操',
        hiragana: '新体操_しんたいそう',
      },
      '20': {
        name: 'アメリカンフットボール',
        hiragana: 'アメリカンフットボール',
      },
      '21': {
        name: 'ラグビー',
        hiragana: 'ラグビー',
      },
      '22': {
        name: 'スキー',
        hiragana: 'スキー',
      },
      '23': {
        name: 'スケート',
        hiragana: 'スケート',
      },
      '24': {
        name: '相撲',
        hiragana: '相撲_すもう',
      },
      '25': {
        name: 'その他（無所属含む）',
        hiragana: 'その他（無所属含む）_そのた（むしょぞくふくむ）',
      }
    },
  },
  {
    id: 46,
    name: '毎日の睡眠時間',
    question: '毎日どのくらい寝ていますか',
    options: {
      '10時間以上': 1,
      '9時間以上10時間未満': 2,
      '8時間以上9時間未満': 3,
      '7時間以上8時間未満': 4,
      '6時間以上7時間未満': 5,
      '6時間未満': 6,
    },
    kanjiQuestion: [
      { kanji: '毎日', hina: 'まいにち' },
      { kanji: '寝', hina: 'ね' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '6': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '7': [],
      '8': [],
    },
  },
  {
    id: 47,
    name: '平日のテレビ視聴時間',
    question:
      '平日（月～金曜日）について、学習以外で1日にどのくらいの時間、テレビやDVD、ゲーム機、スマートフォン、パソコンなどの画面を見ていますか',
    options: {
      '5時間以上': 1,
      '4時間以上5時間未満': 2,
      '3時間以上4時間未満': 3,
      '2時間以上3時間未満': 4,
      '1時間以上2時間未満': 5,
      '1時間未満': 6,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '月', hina: 'げつ' },
      { kanji: '金曜日', hina: 'きんようび' },
      { kanji: '学習', hina: 'がくしゅう' },
      { kanji: '以外', hina: 'いがい' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '機', hina: 'き' },
      { kanji: '画面', hina: 'がめん' },
      { kanji: '見', hina: 'み' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '6': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '7': [],
      '8': [],
    },
  },
  {
    id: 48,
    name: '好きなスポーツの有無',
    question: '好きな運動・スポーツの有無（実施する運動やスポーツ）',
    options: { '1つ以上ある': 1, ない: 2 },
    kanjiQuestion: [
      { kanji: '好', hina: 'す' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '有無', hina: 'うむ' },
      { kanji: '実施', hina: 'じっし' },
      { kanji: '運動', hina: 'うんどう' },
    ],
    kanjiOption: {
      '1': [{ kanji: '以上', hina: 'いじょう' }],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 49,
    name: '間食の有無',
    question: '間食を食べますか',
    options: { 食べない: 1, 時々食べる: 2, ほとんど毎日食べる: 3 },
    kanjiQuestion: [
      { kanji: '間食', hina: 'かんしょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [{ kanji: '食', hina: 'た' }],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '食', hina: 'た' },
      ],
      '3': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 50,
    name: '夕飯は誰と食べるか',
    question: '夕飯は誰と食べますか',
    options: {
      いつも家族で食べる: 1,
      時々子どもだけで食べる: 2,
      ほとんど毎日子どもだけで食べる: 3,
    },
    kanjiQuestion: [
      { kanji: '夕飯', hina: 'ゆうはん' },
      { kanji: '誰', hina: 'だれ' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '家族', hina: 'かぞく' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '子', hina: 'こ' },
        { kanji: '食', hina: 'た' },
      ],
      '3': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '子', hina: 'こ' },
        { kanji: '食', hina: 'た' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 51,
    name: '夕飯は誰が作るか',
    question: '夕飯は家族で作ったものを食べていますか',
    options: { 両親: 1, '自分（兄弟姉妹）': 2, 既製品を買ってきて食べる: 3 },
    kanjiQuestion: [
      { kanji: '夕飯', hina: 'ゆうはん' },
      { kanji: '家族', hina: 'かぞく' },
      { kanji: '作', hina: 'つく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [{ kanji: '両親', hina: 'りょうしん' }],
      '2': [
        { kanji: '自分', hina: 'じぶん' },
        { kanji: '兄弟姉妹', hina: 'きょうだいしまい' },
      ],
      '3': [
        { kanji: '既製品', hina: 'きせいひん' },
        { kanji: '買', hina: 'か' },
        { kanji: '食', hina: 'た' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 52,
    name: '家での勉強時間',
    question: '家で1日どのくらい勉強しますか（3～6年生は土日をのぞく）',
    options: {
      '1時間くらい': 1,
      '30分くらい': 2,
      '15分くらい': 3,
      ほとんどしない: 4,
    },
    kanjiQuestion: [
      { kanji: '家', hina: 'いえ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '勉強', hina: 'べんきょう' },
      { kanji: '年', hina: 'ねん' },
      { kanji: '生', hina: 'せい' },
      { kanji: '土日', hina: 'どにち' },
    ],
    kanjiOption: {
      '1': [{ kanji: '時間', hina: 'じかん' }],
      '2': [{ kanji: '分', hina: 'ぷん' }],
      '3': [{ kanji: '分', hina: 'ふん' }],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 53,
    name: '家での勉強時間',
    question: '家で1日どのくらい勉強しますか',
    options: { '2時間以上': 1, '1~2時間': 2, '30分～1時間': 3, '30分未満': 4 },
    kanjiQuestion: [
      { kanji: '家', hina: 'いえ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '勉強', hina: 'べんきょう' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [{ kanji: '時間', hina: 'じかん' }],
      '3': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '時間', hina: 'じかん' },
      ],
      '4': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 54,
    name: '体育のどこに楽しさを感じるか',
    question: '体育の授業で、どんなことに楽しさを感じますか',
    options: {
      身体を動かすこと: 1,
      '運動が上手くなったり、ゲームに勝ったりすること': 2,
      仲間と活動すること: 3,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '楽', hina: 'たの' },
      { kanji: '感', hina: 'かん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '身体', hina: 'からだ' },
        { kanji: '動', hina: 'うご' },
      ],
      '2': [
        { kanji: '運動', hina: 'うんどう' },
        { kanji: '上手', hina: 'うま' },
        { kanji: '勝', hina: 'か' },
      ],
      '3': [
        { kanji: '仲間', hina: 'なかま' },
        { kanji: '活動', hina: 'かつどう' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 55,
    name: '体育で教え合いはできているか',
    question: '体育の授業で、教え合いや励まし合いはできますか',
    options: {
      いつもできる: 1,
      だいたいできる: 2,
      ときどきできる: 3,
      ほとんどできない: 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '教', hina: 'おし' },
      { kanji: '合', hina: 'あ' },
      { kanji: '励', hina: 'はげ' },
      { kanji: '合', hina: 'あ' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 56,
    name: '走って行う遊びは好きか',
    question: 'おに遊びなどの、走って行う遊びは好きですか',
    options: { きらい: 1, ふつう: 2, まあまあ好き: 3, たいへん好き: 4 },
    kanjiQuestion: [
      { kanji: '遊', hina: 'あそ' },
      { kanji: '走', hina: 'はし' },
      { kanji: '行', hina: 'おこな' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [{ kanji: '好', hina: 'す' }],
      '4': [{ kanji: '好', hina: 'す' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 57,
    name: 'ボールを使った遊びは好きか',
    question: 'ドッジボールなどの、ボールを使った遊びは好きですか',
    options: { きらい: 1, ふつう: 2, まあまあ好き: 3, たいへん好き: 4 },
    kanjiQuestion: [
      { kanji: '使', hina: 'つか' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [{ kanji: '好', hina: 'す' }],
      '4': [{ kanji: '好', hina: 'す' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 58,
    name: '遊具を使った遊びは好きか',
    question: '雲梯や登り棒などの、遊具を使った遊びは好きですか',
    options: { きらい: 1, ふつう: 2, まあまあ好き: 3, たいへん好き: 4 },
    kanjiQuestion: [
      { kanji: '雲梯', hina: 'うんてい' },
      { kanji: '登', hina: 'のぼ' },
      { kanji: '棒', hina: 'ぼう' },
      { kanji: '遊具', hina: 'ゆうぐ' },
      { kanji: '使', hina: 'つか' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [{ kanji: '好', hina: 'す' }],
      '4': [{ kanji: '好', hina: 'す' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 59,
    name: 'ボール以外の用具遊びは好きか',
    question: 'ボール以外の用具（なわとび、一輪車）を使った遊びは好きですか',
    options: { きらい: 1, ふつう: 2, まあまあ好き: 3, たいへん好き: 4 },
    kanjiQuestion: [
      { kanji: '以外', hina: 'いがい' },
      { kanji: '用具', hina: 'ようぐ' },
      { kanji: '一輪車', hina: 'いちりんしゃ' },
      { kanji: '使', hina: 'つか' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [{ kanji: '好', hina: 'す' }],
      '4': [{ kanji: '好', hina: 'す' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 60,
    name: '所属している部活動',
    question: 'あなたは、次のどの活動に所属していますか',
    options: {
      運動部活動に所属: 1,
      文化部活動に所属: 2,
      '社会体育・スポーツの活動に所属': 3,
      その他: 4,
    },
    kanjiQuestion: [
      { kanji: '次', hina: 'つぎ' },
      { kanji: '活動', hina: 'かつどう' },
      { kanji: '所属', hina: 'しょぞく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '運動部', hina: 'うんどうぶ' },
        { kanji: '活動', hina: 'かつどう' },
        { kanji: '所属', hina: 'しょぞく' },
      ],
      '2': [
        { kanji: '文化部', hina: 'ぶんかぶ' },
        { kanji: '活動', hina: 'かつどう' },
        { kanji: '所属', hina: 'しょぞく' },
      ],
      '3': [
        { kanji: '社会体育', hina: 'しゃかいたいいく' },
        { kanji: '活動', hina: 'かつどう' },
      ],
      '4': [{ kanji: '他', hina: 'た' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 61,
    name: '部活動の目的',
    question: '部活動の目的は何ですか',
    options: { 友達づくり: 1, 技術の取得: 2, 精神力の向上: 3, マナーの向上: 4 },
    kanjiQuestion: [
      { kanji: '部活動', hina: 'ぶかつどう' },
      { kanji: '目的', hina: 'もくてき' },
      { kanji: '何', hina: 'なん' },
    ],
    kanjiOption: {
      '1': [{ kanji: '友達', hina: 'ともだち' }],
      '2': [
        { kanji: '技術', hina: 'ぎじゅつ' },
        { kanji: '取得', hina: 'しゅとく' },
      ],
      '3': [
        { kanji: '精神力', hina: 'せいしんりょく' },
        { kanji: '向上', hina: 'こうじょう' },
      ],
      '4': [{ kanji: '向上', hina: 'こうじょう' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 62,
    name: '勝敗について',
    question: '勝敗について\n※文化部活動の方は、「回答なし」を選択してください',
    options: {
      勝つためには自分が出場できなくても仕方がない: 1,
      全員が出場できるなら負けても仕方がない: 2,
      勝敗にはこだわらないほうがよい: 3,
      回答なし: 4,
    },
    kanjiQuestion: [
      { kanji: '勝敗', hina: 'しょうはい' },
      { kanji: '文化部', hina: 'ぶんかぶ' },
      { kanji: '活動', hina: 'かつどう' },
      { kanji: '方', hina: 'かた' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '勝', hina: 'かつ' },
        { kanji: '自分', hina: 'じぶん' },
        { kanji: '出場', hina: 'しゅつじょう' },
        { kanji: '仕方', hina: 'しかた' },
      ],
      '2': [
        { kanji: '全員', hina: 'ぜんいん' },
        { kanji: '出場', hina: 'しゅつじょう' },
        { kanji: '負', hina: 'まけ' },
        { kanji: '仕方', hina: 'しかた' },
      ],
      '3': [{ kanji: '勝敗', hina: 'しょうはい' }],
      '4': [{ kanji: '回答', hina: 'かいとう' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 63,
    name: '指導者に期待するもの',
    question: '指導者に期待するものについて',
    options: {
      技術を重視して指導してくれる指導者: 1,
      技術だけでなく生活面を重視して指導してくれる指導者: 2,
      生活面を重視して指導してくれる指導者: 3,
    },
    kanjiQuestion: [
      { kanji: '指導者', hina: 'しどうしゃ' },
      { kanji: '期待', hina: 'きたい' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '技術', hina: 'ぎじゅつ' },
        { kanji: '重視', hina: 'じゅうし' },
        { kanji: '指導', hina: 'しどう' },
        { kanji: '指導者', hina: 'しどうしゃ' },
      ],
      '2': [
        { kanji: '技術', hina: 'ぎじゅつ' },
        { kanji: '生活面', hina: 'せいかつめん' },
        { kanji: '重視', hina: 'じゅうし' },
        { kanji: '指導者', hina: 'しどうしゃ' },
      ],
      '3': [
        { kanji: '生活面', hina: 'せいかつめん' },
        { kanji: '重視', hina: 'じゅうし' },
        { kanji: '指導', hina: 'しどう' },
        { kanji: '指導者', hina: 'しどうしゃ' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 64,
    name: '活動時間について',
    question: '活動時間について',
    options: { ちょうどよい: 1, 長すぎる: 2, 短い: 3 },
    kanjiQuestion: [{ kanji: '活動時間', hina: 'かつどうじかん' }],
    kanjiOption: {
      '1': [],
      '2': [{ kanji: '長', hina: 'なが' }],
      '3': [{ kanji: '短', hina: 'みじか' }],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 65,
    name: 'マナーを日常生活で実践できているか',
    question:
      '活動で身につけたマナー（あいさつ・時間を守るなど）は、日常生活で実践できていますか',
    options: {
      よくできている: 1,
      だいたいできている: 2,
      あまりできていない: 3,
      できていない: 4,
    },
    kanjiQuestion: [
      { kanji: '活動', hina: 'かつどう' },
      { kanji: '身', hina: 'み' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '守', hina: 'まも' },
      { kanji: '日常生活', hina: 'にちじょうせいかつ' },
      { kanji: '実践', hina: 'じっせん' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 66,
    name: '活動に満足しているか',
    question: '活動に満足していますか',
    options: {
      とても満足している: 1,
      だいたい満足している: 2,
      あまり満足していない: 3,
      満足していない: 4,
    },
    kanjiQuestion: [
      { kanji: '活動', hina: 'かつどう' },
      { kanji: '満足', hina: 'まんぞく' },
    ],
    kanjiOption: {
      '1': [{ kanji: '満足', hina: 'まんぞく' }],
      '2': [{ kanji: '満足', hina: 'まんぞく' }],
      '3': [{ kanji: '満足', hina: 'まんぞく' }],
      '4': [{ kanji: '満足', hina: 'まんぞく' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 67,
    name: '運動の実施頻度',
    question: '運動の実施ひん度（体育の授業を除く）',
    options: {
      'ほとんど毎日する（週4～7日）': 1,
      '時々する（週2～3日）': 2,
      'ほとんどしない（週1日以下）': 3,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '実施', hina: 'じっし' },
      { kanji: '度', hina: 'ど' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '除', hina: 'のぞ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
      ],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
      ],
      '3': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '以下', hina: 'いか' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 68,
    name: '1日の睡眠時間',
    question: '1日の睡眠時間',
    options: {
      '10時間以上': 1,
      '8～9時間程度': 2,
      '6～7時間程度': 3,
      '6時間未満': 4,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '睡眠時間', hina: 'すいみんじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 69,
    name: '上手くなるためのコツが分かったか',
    question:
      '体育の授業では、体の動かし方やうまくなるためのコツがわかりましたか',
    options: {
      わかった: 1,
      ややわかった: 2,
      あまりわからなかった: 3,
      わからなかった: 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '方', hina: 'かた' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 70,
    name: '運動が今までよりできるようになったか',
    question:
      '体育の授業では、運動やスポーツが今までよりもうまくできるようになりましたか',
    options: {
      うまくできるようになった: 1,
      ややできるようになった: 2,
      あまりならなかった: 3,
      ならなかった: 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '今', hina: 'いま' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 71,
    name: '体育は将来の役に立つと思うか',
    question: '体育の授業で学習したことは将来役に立つと思いますか',
    options: {
      役に立つ: 1,
      やや役に立つ: 2,
      あまり役に立たない: 3,
      役に立たない: 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '学習', hina: 'がくしゅう' },
      { kanji: '将来', hina: 'しょうらい' },
      { kanji: '役', hina: 'やく' },
      { kanji: '立', hina: 'た' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '役', hina: 'やく' },
        { kanji: '立', hina: 'た' },
      ],
      '2': [
        { kanji: '役', hina: 'やく' },
        { kanji: '立', hina: 'た' },
      ],
      '3': [
        { kanji: '役', hina: 'やく' },
        { kanji: '立', hina: 'た' },
      ],
      '4': [
        { kanji: '役', hina: 'やく' },
        { kanji: '立', hina: 'た' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 72,
    name: '夜更かしせずに寝ているか',
    question: '夜更かしをせず、時刻を決めて寝ていますか',
    options: { 寝ている: 1, だいたい寝ている: 2, 寝ていない: 3 },
    kanjiQuestion: [
      { kanji: '夜更', hina: 'よふ' },
      { kanji: '時刻', hina: 'じこく' },
      { kanji: '決', hina: 'き' },
      { kanji: '寝', hina: 'ね' },
    ],
    kanjiOption: {
      '1': [{ kanji: '寝', hina: 'ね' }],
      '2': [{ kanji: '寝', hina: 'ね' }],
      '3': [{ kanji: '寝', hina: 'ね' }],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 73,
    name: '早起きを心がけ起きているか',
    question: '早起きを心がけ、時刻を決めて起きていますか',
    options: { 起きている: 1, だいたい起きている: 2, 起きていない: 3 },
    kanjiQuestion: [
      { kanji: '早起', hina: 'はやお' },
      { kanji: '心', hina: 'こころ' },
      { kanji: '時刻', hina: 'じこく' },
      { kanji: '起', hina: 'お' },
    ],
    kanjiOption: {
      '1': [{ kanji: '起', hina: 'お' }],
      '2': [{ kanji: '起', hina: 'お' }],
      '3': [{ kanji: '起', hina: 'お' }],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 74,
    name: '好き嫌いせずに食べているか',
    question: '好ききらいをせず、残さず食べていますか',
    options: { 食べている: 1, だいたい食べている: 2, よく残す: 3 },
    kanjiQuestion: [
      { kanji: '好', hina: 'す' },
      { kanji: '残', hina: 'のこ' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [{ kanji: '食', hina: 'た' }],
      '2': [{ kanji: '食', hina: 'た' }],
      '3': [{ kanji: '残', hina: 'のこ' }],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 75,
    name: '夜の学習時間',
    question: '夜、1日どのくらい家庭学習をしていますか',
    options: {
      '30分未満': 1,
      '30分以上〜1時間未満': 2,
      '1時間以上〜2時間未満': 3,
      '2時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '夜', hina: 'よる' },
      { kanji: '日', hina: 'にち' },
      { kanji: '家庭学習', hina: 'かていがくしゅう' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 76,
    name: '運動は大切だと思うか',
    question:
      '運動（体を動かす遊びをふくむ）やスポーツをすることは大切だと思いますか',
    options: {
      とてもそう思う: 1,
      まあそう思う: 2,
      あまりそう思わない: 3,
      まったくそう思わない: 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '大切', hina: 'たいせつ' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [{ kanji: '思', hina: 'おも' }],
      '2': [{ kanji: '思', hina: 'おも' }],
      '3': [{ kanji: '思', hina: 'おも' }],
      '4': [{ kanji: '思', hina: 'おも' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 77,
    name: '学校生活は楽しいか',
    question: '学校生活は楽しいですか',
    options: { 楽しい: 1, やや楽しい: 2, あまり楽しくない: 3, 楽しくない: 4 },
    kanjiQuestion: [
      { kanji: '学校生活', hina: 'がっこうせいかつ' },
      { kanji: '楽', hina: 'たの' },
    ],
    kanjiOption: {
      '1': [{ kanji: '楽', hina: 'たの' }],
      '2': [{ kanji: '楽', hina: 'たの' }],
      '3': [{ kanji: '楽', hina: 'たの' }],
      '4': [{ kanji: '楽', hina: 'たの' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 78,
    name: '通学時の交通手段',
    question: 'どのような交通手段で通学していますか',
    options: {
      徒歩: 1,
      バスや電車などの公共交通機関: 2,
      '自家用車（家族などの送迎）': 3,
      自転車: 4,
      'バイク（高校生対象）': 5,
      'その他【　　】': 6,
    },
    kanjiQuestion: [
      { kanji: '交通手段', hina: 'こうつうしゅだん' },
      { kanji: '通学', hina: 'つうがく' },
    ],
    kanjiOption: {
      '1': [{ kanji: '徒歩', hina: 'とほ' }],
      '2': [
        { kanji: '電車', hina: 'でんしゃ' },
        { kanji: '公共交通機関', hina: 'こうきょうきかん' },
      ],
      '3': [
        { kanji: '自家用車', hina: 'じかようしゃ' },
        { kanji: '家族', hina: 'かぞく' },
        { kanji: '送迎', hina: 'そうげい' },
      ],
      '4': [{ kanji: '自転車', hina: 'じてんしゃ' }],
      '5': [
        { kanji: '高校生', hina: 'こうこうせい' },
        { kanji: '対象', hina: 'たいしょう' },
      ],
      '6': [{ kanji: '他', hina: 'た' }],
      '7': [],
      '8': [],
    },
  },
  {
    id: 79,
    name: '1日の運動時間',
    question:
      '学校の体育の授業を除いて、運動やスポーツ（体を動かす遊びを含む）を1日にどのくらいの時間していますか',
    options: {
      '0分': 1,
      '1分～30分未満': 2,
      '30分以上～1時間未満': 3,
      '1時間以上～2時間未満': 4,
      '2時間以上': 5,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '除', hina: 'のぞ' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
    ],
    kanjiOption: {
      '1': [{ kanji: '分', hina: 'ふん' }],
      '2': [
        { kanji: '分', hina: 'ふん' },
        { kanji: '分', hina: 'ふん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '分', hina: 'ふん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 80,
    name: '運動が嫌いな理由',
    question:
      '嫌いな理由は何ですか（前の質問で「ややきらい」「きらい」と回答した方のみ回答してください）',
    options: {
      '苦手・うまくできない': 1,
      はずかしい: 2,
      こわい: 3,
      きつい: 4,
      汗をかくのが嫌: 5,
      回答しない: 6,
    },
    kanjiQuestion: [
      { kanji: '嫌', hina: 'きら' },
      { kanji: '理由', hina: 'りゆう' },
      { kanji: '何', hina: 'なん' },
      { kanji: '前', hina: 'まえ' },
      { kanji: '質問', hina: 'しつもん' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '方', hina: 'かた' },
      { kanji: '回答', hina: 'かいとう' },
    ],
    kanjiOption: {
      '1': [{ kanji: '苦手', hina: 'にがて' }],
      '2': [],
      '3': [],
      '4': [],
      '5': [
        { kanji: '汗', hina: 'あせ' },
        { kanji: '嫌', hina: 'きら' },
      ],
      '6': [{ kanji: '回答', hina: 'かいとう' }],
      '7': [],
      '8': [],
    },
  },
  {
    id: 81,
    name: '1週間の運動時間',
    question:
      '体育の授業以外で1週間に合計どのくらいの時間、運動やスポーツをしますか',
    options: {
      '0分': 1,
      '1~59分': 2,
      '60~209分': 3,
      '210~419分': 4,
      '420分': 5,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '以外', hina: 'いがい' },
      { kanji: '週間', hina: 'しゅうかん' },
      { kanji: '合計', hina: 'ごうけい' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
    ],
    kanjiOption: {
      '1': [{ kanji: '分', hina: 'ふん' }],
      '2': [{ kanji: '分', hina: 'ふん' }],
      '3': [{ kanji: '分', hina: 'ふん' }],
      '4': [{ kanji: '分', hina: 'ふん' }],
      '5': [{ kanji: '分', hina: 'ふん' }],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 82,
    name: '1日のパソコン・携帯電話の使用時間',
    question: '1日のパソコンや携帯電話の利用時間（メールも含む）',
    options: {
      '1時間未満': 1,
      '1時間以上2時間未満': 2,
      '2時間以上3時間未満': 3,
      '3時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '携帯電話', hina: 'けいたいでんわ' },
      { kanji: '利用時間', hina: 'りようじかん' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 83,
    name: '登校方法',
    question: '登校方法',
    options: {
      徒歩: 1,
      自転車: 2,
      'スクールバス・路線バス・電車': 3,
      自家用車: 4,
    },
    kanjiQuestion: [{ kanji: '登校方法', hina: 'とうこうほうほう' }],
    kanjiOption: {
      '1': [{ kanji: '徒歩', hina: 'とほ' }],
      '2': [{ kanji: '自転車', hina: 'じてんしゃ' }],
      '3': [
        { kanji: '路線', hina: 'ろせん' },
        { kanji: '電車', hina: 'でんしゃ' },
      ],
      '4': [{ kanji: '自家用車', hina: 'じかようしゃ' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 84,
    name: '運動ができるようになった理由',
    question: '運動ができるようになった理由',
    options: {
      先生にコツやポイントを教えてもらった: 1,
      友達に教えてもらった: 2,
      自分で本を読んだり動画をみたりして工夫した: 3,
      運動の仕方が分かるようになったりできるようになったことがない: 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '理由', hina: 'りゆう' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '先生', hina: 'せんせい' },
        { kanji: '教', hina: 'おし' },
      ],
      '2': [
        { kanji: '友達', hina: 'ともだち' },
        { kanji: '教', hina: 'おし' },
      ],
      '3': [
        { kanji: '自分', hina: 'じぶん' },
        { kanji: '本', hina: 'ほん' },
        { kanji: '読', hina: 'よ' },
        { kanji: '動画', hina: 'どうが' },
        { kanji: '工夫', hina: 'くふう' },
      ],
      '4': [
        { kanji: '運動', hina: 'うんどう' },
        { kanji: '仕方', hina: 'しかた' },
        { kanji: '分', hina: 'わ' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 85,
    name: '運動の頻度',
    question:
      '運動・スポーツはどのくらいしていますか（学校の体育の授業を除く）',
    options: {
      'ほとんど毎日する（週4〜7日）': 1,
      '時々する（週2〜3日）': 2,
      'ほとんどしない（週1日以下）': 3,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '除', hina: 'のぞ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '3': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '以下', hina: 'いか' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 86,
    name: '朝食の有無',
    question: '朝食は食べますか',
    options: { 毎日とる: 1, '時々とる（週2〜3日）': 2, ほとんどとらない: 3 },
    kanjiQuestion: [
      { kanji: '朝食', hina: 'ちょうしょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [{ kanji: '毎日', hina: 'まいにち' }],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 87,
    name: '1日の睡眠時間',
    question: '1日の睡眠時間はどのくらいですか',
    options: {
      '10時間以上': 1,
      '8〜9時間程度': 2,
      '6~7時間程度': 3,
      '6時間未満': 4,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '睡眠時間', hina: 'すいみんじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 88,
    name: '体育の授業は楽しいか',
    question: '体育の授業は楽しいですか',
    options: { 楽しい: 1, やや楽しい: 2, あまり楽しくない: 3, 楽しくない: 4 },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '楽', hina: 'たの' },
    ],
    kanjiOption: {
      '1': [{ kanji: '楽', hina: 'たの' }],
      '2': [{ kanji: '楽', hina: 'たの' }],
      '3': [{ kanji: '楽', hina: 'たの' }],
      '4': [{ kanji: '楽', hina: 'たの' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 89,
    name: '入っていない理由',
    question:
      ' 【「入っていない」と回答した人のみ】\n運動部やスポーツクラブに「入っていない」と答えた人はその理由を教えてください。',
    options: {
      塾等の習い事がある: 1,
      運動部やスポーツクラブに関心がない: 2,
      興味のある種目の運動部やスポーツクラブがない: 3,
      運動部やスポーツクラブを続ける自信がない: 4,
      自由な時間が必要: 5,
      その他: 6,
    },
    kanjiQuestion: [
      { kanji: '入', hina: 'はい' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '運動部', hina: 'うんどうぶ' },
      { kanji: '入', hina: 'はい' },
      { kanji: '答', hina: 'こた' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '理由', hina: 'りゆう' },
      { kanji: '教', hina: 'おし' },
      { kanji: '入', hina: 'はい' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '塾', hina: 'じゅく' },
        { kanji: '等', hina: 'など' },
        { kanji: '習', hina: 'なら' },
        { kanji: '事', hina: 'ごと' },
      ],
      '2': [
        { kanji: '運動部', hina: 'うんどうぶ' },
        { kanji: '関心', hina: 'かんしん' },
      ],
      '3': [
        { kanji: '興味', hina: 'きょうみ' },
        { kanji: '種目', hina: 'しゅもく' },
        { kanji: '運動部', hina: 'うんどうぶ' },
      ],
      '4': [
        { kanji: '運動部', hina: 'うんどうぶ' },
        { kanji: '続', hina: 'つづ' },
        { kanji: '自信', hina: 'じしん' },
      ],
      '5': [
        { kanji: '自由', hina: 'じゆう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '必要', hina: 'ひつよう' },
      ],
      '6': [{ kanji: '他', hina: 'た' }],
    },
    isOptional: true
  },
  {
    id: 90,
    name: '所属していない理由',
    question:
      ' 【「所属していない」と回答した人のみ】\n運動部やスポーツクラブに「所属していない」と答えた人はその理由を教えてください。',
    options: {
      文化系のクラブに所属している: 1,
      塾等の習い事がある: 2,
      運動部やスポーツクラブに関心がない: 3,
      興味のある種目の運動部やスポーツクラブがない: 4,
      運動部やスポーツクラブを続ける自信がない: 5,
      自由な時間が必要: 6,
      その他: 7,
    },
    kanjiQuestion: [
      { kanji: '運動部', hina: 'うんどうぶ' },
      { kanji: '所属', hina: 'しょぞく' },
      { kanji: '答', hina: 'こた' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '理由', hina: 'りゆう' },
      { kanji: '教', hina: 'おし' },
      { kanji: '所属', hina: 'しょぞく' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '文化系', hina: 'ぶんかけい' },
        { kanji: '所属', hina: 'しょぞく' },
      ],
      '2': [
        { kanji: '塾', hina: 'じゅく' },
        { kanji: '等', hina: 'など' },
        { kanji: '習', hina: 'なら' },
        { kanji: '事', hina: 'ごと' },
      ],
      '3': [
        { kanji: '運動部', hina: 'うんどうぶ' },
        { kanji: '関心', hina: 'かんしん' },
      ],
      '4': [
        { kanji: '興味', hina: 'きょうみ' },
        { kanji: '種目', hina: 'しゅもく' },
        { kanji: '運動部', hina: 'うんどうぶ' },
      ],
      '5': [
        { kanji: '運動部', hina: 'うんどうぶ' },
        { kanji: '続', hina: 'つづ' },
        { kanji: '自信', hina: 'じしん' },
      ],
      '6': [
        { kanji: '自由', hina: 'じゆう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '必要', hina: 'ひつよう' },
      ],
      '7': [{ kanji: '他', hina: 'た' }],
    },
    isOptional: true
  },
  {
    id: 91,
    name: '朝食の有無',
    question: '朝食は毎日食べますか（学校や休みの日も含む）',
    options: {
      毎日食べる: 1,
      食べない日もある: 2,
      食べない日が多い: 3,
      食べない: 4,
    },
    kanjiQuestion: [
      { kanji: '朝食', hina: 'ちょうしょく' },
      { kanji: '毎日', hina: 'まいにち' },
      { kanji: '食', hina: 'た' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '休', hina: 'やす' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
      ],
      '3': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '多', hina: 'おお' },
      ],
      '4': [{ kanji: '食', hina: 'た' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 92,
    name: '体育の授業は楽しいか',
    question: '保健体育の授業は楽しいですか',
    options: { 楽しい: 1, やや楽しい: 2, あまり楽しくない: 3, 楽しくない: 4 },
    kanjiQuestion: [
      { kanji: '保健体育', hina: 'ほけんたいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '楽', hina: 'たの' },
    ],
    kanjiOption: {
      '1': [{ kanji: '楽', hina: 'たの' }],
      '2': [{ kanji: '楽', hina: 'たの' }],
      '3': [{ kanji: '楽', hina: 'たの' }],
      '4': [{ kanji: '楽', hina: 'たの' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 93,
    name: '1日のテレビ視聴時間',
    question: '1日にどのくらいテレビを見ますか',
    options: {
      '1時間未満': 1,
      '1時間以上2時間未満': 2,
      '2時間以上3時間未満': 3,
      '3時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '見', hina: 'み' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 94,
    name: '画面の視聴時間',
    question:
      '1日にどのくらいパソコン（タブレット型を含む）・携帯電話・携帯ゲーム機を見ますか',
    options: {
      '1時間未満': 1,
      '1時間以上2時間未満': 2,
      '2時間以上3時間未満': 3,
      '3時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '型', hina: 'がた' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '携帯電話', hina: 'けいたいでんわ' },
      { kanji: '携帯', hina: 'けいたい' },
      { kanji: '機', hina: 'き' },
      { kanji: '見', hina: 'み' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 95,
    name: '学校内での運動の時間',
    question:
      '学校にいるとき、授業以外（授業の始まる前、休み時間、放課後）に運動（体を動かす遊びを含む）やスポーツを1日にどれくらいの時間しますか\n※「しない」と回答した人は「回答なし」を選択してください',
    options: {
      '1~29分': 1,
      '30~59分': 2,
      '1時間以上2時間未満': 3,
      '2時間以上': 4,
      回答なし: 5,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '始', hina: 'はじ' },
      { kanji: '前', hina: 'まえ' },
      { kanji: '休', hina: 'やす' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '放課後', hina: 'ほうかご' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [{ kanji: '分', hina: 'ふん' }],
      '2': [{ kanji: '分', hina: 'ふん' }],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [{ kanji: '回答', hina: 'かいとう' }],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 96,
    name: '学校外での運動の時間',
    question:
      '学校に行く前や学校から帰った後（平日で学校にいない時間）に、運動（体を動かす遊びを含む）やスポーツを1日にどれくらいの時間しますか\n※「しない」と回答した人は「回答なし」を選択してください',
    options: {
      '1~29分': 1,
      '30~59分': 2,
      '1時間以上2時間未満': 3,
      '2時間以上': 4,
      回答なし: 5,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '行', hina: 'い' },
      { kanji: '前', hina: 'まえ' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '帰', hina: 'かえ' },
      { kanji: '後', hina: 'あと' },
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [{ kanji: '分', hina: 'ふん' }],
      '2': [{ kanji: '分', hina: 'ふん' }],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [{ kanji: '回答', hina: 'かいとう' }],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 97,
    name: '1日のテレビ視聴時間',
    question: '1日のテレビ（テレビゲームを含む）視聴時間',
    options: {
      '1時間未満': 1,
      '1時間以上2時間未満': 2,
      '2時間以上3時間未満': 3,
      '3時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '視聴時間', hina: 'しちょうじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 98,
    name: '画面の視聴時間',
    question:
      '1日のパソコン（タブレット型を含む）・携帯電話・携帯ゲーム機の視聴時間',
    options: {
      '1時間未満': 1,
      '1時間以上2時間未満': 2,
      '2時間以上3時間未満': 3,
      '3時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '型', hina: 'かた' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '携帯電話', hina: 'けいたいでんわ' },
      { kanji: '携帯', hina: 'けいたい' },
      { kanji: '機', hina: 'き' },
      { kanji: '視聴時間', hina: 'しちょうじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 99,
    name: '都市階級区分',
    question:
      '居住地の都市階級区分\n※高等学校・中等教育学校以外の方は「4.回答なし」を選択してください。',
    options: {
      '大・中都市（人口15万人以上の市）': 1,
      '小都市（人口15万人未満の市）': 2,
      町村: 3,
      回答なし: 4,
    },
    kanjiQuestion: [
      { kanji: '居住地', hina: 'きょじゅうち' },
      { kanji: '都市階級区分', hina: 'としかいきゅうくぶん' },
      { kanji: '高等学校', hina: 'こうとうがっこう' },
      { kanji: '中等教育学校', hina: 'ちゅうとうきょういくがっこう' },
      { kanji: '以外', hina: 'いがい' },
      { kanji: '方', hina: 'かた' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '大', hina: 'だい' },
        { kanji: '中都市', hina: 'ちゅうとし' },
        { kanji: '人口', hina: 'じんこう' },
        { kanji: '万人', hina: 'まんにん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '市', hina: 'し' },
      ],
      '2': [
        { kanji: '小都市', hina: 'しょうとし' },
        { kanji: '人口', hina: 'じんこう' },
        { kanji: '万人', hina: 'まんにん' },
        { kanji: '未満', hina: 'みまん' },
        { kanji: '市', hina: 'し' },
      ],
      '3': [{ kanji: '町村', hina: 'ちょうそん' }],
      '4': [{ kanji: '回答', hina: 'かいとう' }],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 100,
    name: '朝昼晩3食食べるか',
    question: '朝昼晩3食きちんと食べていますか',
    options: { きちんと食べる: 1, 時々食べない: 2, 食べないことが多い: 3 },
    kanjiQuestion: [
      { kanji: '朝昼晩', hina: 'あさひるばん' },
      { kanji: '食', hina: 'しょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [{ kanji: '食', hina: 'た' }],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '食', hina: 'た' },
      ],
      '3': [
        { kanji: '食', hina: 'た' },
        { kanji: '多', hina: 'おお' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 101,
    name: '朝食の有無',
    question: '朝食は食べますか',
    options: { 毎日食べる: 1, 食べない日もある: 2, まったく食べない: 3 },
    kanjiQuestion: [
      { kanji: '朝食', hina: 'ちょうしょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
      ],
      '3': [{ kanji: '食', hina: 'た' }],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 102,
    name: '1日のテレビ視聴時間',
    question:
      '学習以外の、1日どのくらいテレビ、スマートフォン、パソコンなどを見ますか？（テレビゲームも含む）',
    options: {
      '1時間未満': 1,
      '1時間以上2時間未満': 2,
      '2時間以上3時間未満': 3,
      '3時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '学習以外', hina: 'がくしゅういがい' },
      { kanji: '日', hina: 'にち' },
      { kanji: '見', hina: 'み' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 103,
    name: '運動の頻度',
    question:
      '学校の体育の授業以外に運動やスポーツをどのくらいしていますか（登下校の徒歩や自転車も運動に含む）',
    options: { 週3日以上: 1, '週1~2日程度': 2, '月1~3日程度': 3, しない: 4 },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '登下校', hina: 'とうげこう' },
      { kanji: '徒歩', hina: 'とほ' },
      { kanji: '自転車', hina: 'じてんしゃ' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '3': [
        { kanji: '月', hina: 'つき' },
        { kanji: '日', hina: 'か' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 104,
    name: '1日の運動時間',
    question:
      '学校の保健体育の授業以外で運動やスポーツをどのくらいしていますか（登下校の徒歩や自転車も運動に含む）',
    options: {
      '2時間以上': 1,
      '1時間以上2時間未満': 2,
      '30分以上1時間未満': 3,
      '30分未満': 4,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '保健体育', hina: 'ほけんたいいく' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '登下校', hina: 'とうげこう' },
      { kanji: '徒歩', hina: 'とほ' },
      { kanji: '自転車', hina: 'じてんしゃ' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 105,
    name: '1日のテレビ視聴時間',
    question:
      '1日にどれくらいテレビを見ますか（ビデオ・ゲーム・パソコン・スマートフォン等も含む。土・日は除く）',
    options: {
      '1時間未満': 1,
      '1時間以上2時間未満': 2,
      '2時間以上3時間未満': 3,
      '3時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '見', hina: 'み' },
      { kanji: '等', hina: 'とう' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '土', hina: 'ど' },
      { kanji: '日', hina: 'にち' },
      { kanji: '除', hina: 'のぞ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 106,
    name: 'スナック菓子・炭酸飲料',
    question: 'スナック菓子や炭酸飲料を食べたり飲んだりしますか',
    options: {
      食べたり飲んだりしない: 1,
      時々食べたり飲んだりする: 2,
      ほとんど毎日食べたり飲んだりする: 3,
    },
    kanjiQuestion: [
      { kanji: '菓子', hina: 'がし' },
      { kanji: '炭酸飲料', hina: 'たんさんいんりょう' },
      { kanji: '食', hina: 'た' },
      { kanji: '飲', hina: 'の' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '食', hina: 'た' },
        { kanji: '飲', hina: 'の' },
      ],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '食', hina: 'た' },
        { kanji: '飲', hina: 'の' },
      ],
      '3': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
        { kanji: '飲', hina: 'の' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 107,
    name: '夕飯は誰と食べるか',
    question: '夕食は、家族と食べますか',
    options: {
      いつも家族で食べる: 1,
      時々子どもだけで食べる: 2,
      ほとんど子どもだけで食べる: 3,
    },
    kanjiQuestion: [
      { kanji: '夕食', hina: 'ゆうしょく' },
      { kanji: '家族', hina: 'かぞく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '家族', hina: 'かぞく' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '子', hina: 'こ' },
        { kanji: '食', hina: 'た' },
      ],
      '3': [
        { kanji: '子', hina: 'こ' },
        { kanji: '食', hina: 'た' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 108,
    name: '夕飯は誰が作るか',
    question: '夕飯は家庭で作ったものを食べていますか',
    options: {
      お父さんやお母さんなど家族が作ったものを食べる: 1,
      '自分たち（兄弟姉妹）で作ったものを食べる': 2,
      作ってある弁当やパンなどを買ってきて食べる: 3,
    },
    kanjiQuestion: [
      { kanji: '夕飯', hina: 'ゆうはん' },
      { kanji: '家庭', hina: 'かてい' },
      { kanji: '作', hina: 'つく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '父', hina: 'とう' },
        { kanji: '母', hina: 'かあ' },
        { kanji: '家族', hina: 'かぞく' },
        { kanji: '作', hina: 'つく' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '自分', hina: 'じぶん' },
        { kanji: '兄弟姉妹', hina: 'きょうだいしまい' },
        { kanji: '作', hina: 'つく' },
        { kanji: '食', hina: 'た' },
      ],
      '3': [
        { kanji: '作', hina: 'つく' },
        { kanji: '弁当', hina: 'べんとう' },
        { kanji: '買', hina: 'か' },
        { kanji: '食', hina: 'た' },
      ],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 109,
    name: '家庭学習',
    question:
      '学校の授業以外で、1日どのくらいの時間家庭学習をしますか（土・日は除く）',
    options: { '2時間以上': 1, '1~2時間': 2, '30分～1時間': 3, '30分未満': 4 },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '家庭学習', hina: 'かていがくしゅう' },
      { kanji: '土', hina: 'ど' },
      { kanji: '日', hina: 'にち' },
      { kanji: '除', hina: 'のぞ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [{ kanji: '時間', hina: 'じかん' }],
      '3': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '時間', hina: 'じかん' },
      ],
      '4': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 110,
    name: '1日の睡眠時間',
    question:
      '1日に何時間睡眠をとりますか',
    options: { '7時間未満': 1, '7時間以上～8時間未満': 2, '8時間以上': 3},
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '何時間', hina: 'なんじかん' },
      { kanji: '睡眠', hina: 'すいみん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
    },
  },
  {
    id: 111,
    name: '外遊びや運動の有無',
    question:
      '外遊びや運動・スポーツを1時間以上、ほとんど毎日（週3日以上）しますか',
    options: { 'はい': 1, 'いいえ': 2},
    kanjiQuestion: [
      { kanji: '外遊', hina: 'そとあそ' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '以上', hina: 'いじょう' },
      { kanji: '毎日', hina: 'まいにち' },
      { kanji: '週', hina: 'しゅう' },
      { kanji: '日', hina: 'か' },
      { kanji: '以上', hina: 'いじょう' },
    ],
    kanjiOption: {
      '1': [],
      '2': []
    },
  },
  {
    id: 112,
    name: '運動の頻度',
    question:
      '運動やスポーツをどのくらいしていますか',
    options: { 'ほとんど毎日（週3日以上）': 1, 'ときどき（週1～2日くらい）': 2, 'ときたま（月1～３日くらい）': 3, 'しない': 4},
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '3': [
        { kanji: '月', hina: 'つき' },
        { kanji: '日', hina: 'か' },
      ],
      '4': []
    },
  },
  {
    id: 113,
    name: '1日の運動時間',
    question:
      '運動やスポーツをするときは１日どのくらいの時間しますか',
    options: { '30分より少ない': 1, '30分以上1時間より少ない': 2, '1時間以上2時間より少ない': 3, '2時間以上': 4},
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '少', hina: 'すく' },
      ],
      '2': [
        { kanji: '分', hina: 'ぷん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
    },
  },
  {
    id: 114,
    name: '1日の睡眠時間',
    question:
      '１日の睡眠時間',
    options: { '6時間より少ない': 1, '6時間以上8時間より少ない': 2, '8時間以上': 3},
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '睡眠時間', hina: 'すいみんじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
    },
  },
  {
    id: 115,
    name: '1日のテレビなどの視聴時間',
    question:
      '学習以外で、１日どのくらいの時間、テレビやDVD、ゲーム機、スマートフォン、パソコンなどの画面を見ていますか',
    options: { '1時間より少ない': 1, '1時間以上2時間より少ない': 2, '2時間以上3時間より少ない': 3, '3時間以上': 4},
    kanjiQuestion: [
      { kanji: '学習以外', hina: 'がくしゅういがい' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '機', hina: 'き' },
      { kanji: '画面', hina: 'がめん' },
      { kanji: '見', hina: 'み' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
    },
  },
  {
    id: 116,
    name: '運動やスポーツは好きか',
    question:
      '運動（体を動かす遊びをふくむ）やスポーツをすることは好きですか',
    options: { '好き': 1, 'やや好き': 2, 'ややきらい': 3, 'きらい': 4},
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '好', hina: 'す' },
      ],
      '2': [
        { kanji: '好', hina: 'す' },
      ],
      '3': [],
      '4': []
    },
  },
  {
    id: 117,
    name: '体力の自信',
    question:
      '体力に自信がありますか',
    options: { 'ある': 1, 'まあまあある': 2, 'あまりない': 3, 'ない': 4},
    kanjiQuestion: [
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '自信', hina: 'じしん' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': []
    },
  },
  {
    id: 118,
    name: '運動やスポーツは大切か',
    question:
      '運動（体を動かす遊びをふくむ）やスポーツをすることは大切だと思いますか',
    options: { '思う': 1, 'やや思う': 2, 'あんまり思わない': 3, '思わない': 4},
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '大切', hina: 'たいせつ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '思', hina: 'おも' },
      ],
      '2': [
        { kanji: '思', hina: 'おも' },
      ],
      '3': [
        { kanji: '思', hina: 'おも' },
      ],
      '4': [
        { kanji: '思', hina: 'おも' },
      ],
    },
  },
  {
    id: 119,
    name: '食事の時間',
    question:
      '決まった時間に食事をしていますか',
    options: { 'している': 1, 'だいたいしている': 2, 'あまりしていない': 3, 'していない': 4},
    kanjiQuestion: [
      { kanji: '決', hina: 'き' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '食事', hina: 'しょくじ' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': []
    },
  },
  {
    id: 120,
    name: '家族と食事するか',
    question:
      '家族といっしょに食事をしていますか',
    options: { 'している': 1, 'だいたいしている': 2, 'あまりしていない': 3, 'していない': 4},
    kanjiQuestion: [
      { kanji: '家族', hina: 'かぞく' },
      { kanji: '食事', hina: 'しょくじ' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': []
    },
  },
  {
    id: 121,
    name: 'バランスの良い食事か',
    question:
      '好ききらいせず、栄養バランスに気をつけて食事をしていますか',
    options: { 'している': 1, 'まあまあしている': 2, 'あまりしていない': 3, 'していない': 4},
    kanjiQuestion: [
      { kanji: '好', hina: 'す' },
      { kanji: '栄養', hina: 'えいよう' },
      { kanji: '気', hina: 'き' },
      { kanji: '食事', hina: 'しょくじ' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': []
    },
  },
  {
    id: 122,
    name: '楽しい食事か',
    question:
      '楽しく食事をしていますか',
    options: { 'している': 1, 'だいたいしている': 2, 'あまりしていない': 3, 'していない': 4},
    kanjiQuestion: [
      { kanji: '楽', hina: 'たの' },
      { kanji: '食事', hina: 'しょくじ' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': []
    },
  },
  {
    id: 123,
    name: '夜ふかししないように気をつける',
    question:
      '夜ふかししないように気をつけていますか',
    options: { '気をつけている': 1, 'やや気をつけている': 2, 'あまり気をつけていない': 3, '気をつけていない': 4},
    kanjiQuestion: [
      { kanji: '夜', hina: 'よ' },
      { kanji: '気', hina: 'き' },
    ],
    kanjiOption: {
     '1': [
      { kanji: '気', hina: 'き' },
     ],
     '2': [
      { kanji: '気', hina: 'き' },
     ],
     '3': [
      { kanji: '気', hina: 'き' },
     ],
     '4': [
      { kanji: '気', hina: 'き' },
     ],
    },
  },
  {
    id: 124,
    name: 'ぐっすり眠れるか',
    question:
      'ぐっすりと眠れていますか',
    options: { '眠れている': 1, 'だいたい眠れている': 2, 'あまり眠れていない': 3, '眠れていない': 4},
    kanjiQuestion: [
      { kanji: '眠', hina: 'ねむ' },
    ],
    kanjiOption: {
     '1': [
      { kanji: '眠', hina: 'ねむ' },
     ],
     '2': [
      { kanji: '眠', hina: 'ねむ' },
     ],
     '3': [
      { kanji: '眠', hina: 'ねむ' },
     ],
     '4': [
      { kanji: '眠', hina: 'ねむ' },
     ],
    },
  },
  {
    id: 125,
    name: '朝は自分で起きているか',
    question:
      '朝は自分で起きていますか',
    options: { '起きている': 1, 'だいたい起きている': 2, 'あまり起きていない': 3, '起きていない': 4},
    kanjiQuestion: [
      { kanji: '朝', hina: 'あさ' },
      { kanji: '自分', hina: 'じぶん' },
      { kanji: '起', hina: 'お' },
    ],
    kanjiOption: {
     '1': [
      { kanji: '起', hina: 'お' },
     ],
     '2': [
      { kanji: '起', hina: 'お' },
     ],
     '3': [
      { kanji: '起', hina: 'お' },
     ],
     '4': [
      { kanji: '起', hina: 'お' },
     ],
    },
  },
  {
    id: 126,
    name: '疲れやだるさを感じるか',
    question:
      '何となく体のつかれやだるさを感じることがありますか',
    options: { 'ある': 1, 'ときどきある': 2, 'あまりない': 3, 'ない': 4},
    kanjiQuestion: [
      { kanji: '何', hina: 'なん' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '感', hina: 'かん' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': []
    },
  },
  {
    id: 127,
    name: '食後の歯磨き',
    question:
      '食事の後は歯みがきをしていますか',
    options: { 'している': 1, 'だいたいしている': 2, 'あまりしていない': 3, 'していない': 4},
    kanjiQuestion: [
      { kanji: '食事', hina: 'しょくじ' },
      { kanji: '後', hina: 'あと' },
      { kanji: '歯', hina: 'は' },
    ],
    kanjiOption: {
     '1': [],
     '2': [],
     '3': [],
     '4': []
    },
  },
  {
    id: 128,
    name: '1日の勉強時間',
    question:
      '家での、1日の勉強時間（じゅくなどでの勉強時間をふくみます）',
    options: { '30分より少ない': 1, '30分以上1時間より少ない': 2, '1時間以上2時間より少ない': 3, '2時間以上': 4},
    kanjiQuestion: [
      { kanji: '家', hina: 'いえ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '勉強時間', hina: 'べんきょうじかん' },
      { kanji: '勉強時間', hina: 'べんきょうじかん' },
    ],
    kanjiOption: {
     '1': [
      { kanji: '分', hina: 'ぷん' },
      { kanji: '少', hina: 'すく' },
     ],
     '2': [
      { kanji: '分', hina: 'ぷん' },
      { kanji: '以上', hina: 'いじょう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '少', hina: 'すく' },
     ],
     '3': [
      { kanji: '時間', hina: 'じかん' },
      { kanji: '以上', hina: 'いじょう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '少', hina: 'すく' },
     ],
     '4': [
      { kanji: '時間', hina: 'じかん' },
      { kanji: '以上', hina: 'いじょう' },
     ],
    },
  },
  {
    id: 129,
    name: '1日のケータイ・スマホの使用時間',
    question:
      '1日のケータイ・スマホの使用時間',
    options: { '30分より少ない': 1, '30分以上1時間より少ない': 2, '1時間以上2時間より少ない': 3, '2時間以上': 4, '持っていない': 5},
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '使用時間', hina: 'しようじかん' },
    ],
    kanjiOption: {
     '1': [
      { kanji: '分', hina: 'ぷん' },
      { kanji: '少', hina: 'すく' },
     ],
     '2': [
      { kanji: '分', hina: 'ぷん' },
      { kanji: '以上', hina: 'いじょう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '少', hina: 'すく' },
     ],
     '3': [
      { kanji: '時間', hina: 'じかん' },
      { kanji: '以上', hina: 'いじょう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '少', hina: 'すく' },
     ],
     '4': [
      { kanji: '時間', hina: 'じかん' },
      { kanji: '以上', hina: 'いじょう' },
     ],
     '5': [
      { kanji: '持', hina: 'も' },
     ],
    },
  },
  {
    id: 130,
    name: '大人になってもやってみたいもの',
    question:
      '体育のじゅぎょうで学んだ運動やスポーツの中で、大人になってもやってみたいと思ったものはありますか',
    options: { 'ある': 1, 'まあまあある': 2, 'あまりない': 3, 'まったくない': 4},
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '学', hina: 'まな' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '中', hina: 'なか' },
      { kanji: '大人', hina: 'おとな' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
     '1': [],
     '2': [],
     '3': [],
     '4': [],
    },
  },
  {
    id: 131,
    name: 'コツやポイントをつかめているか',
    question:
      '体育のじゅぎょうで、運動のコツやポイントをつかめていますか',
    options: { 'つかめている': 1, 'まあまあつかめている': 2, 'あまりつかめていない': 3, 'つかめていない': 4},
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '運動', hina: 'うんどう' },
    ],
    kanjiOption: {
     '1': [],
     '2': [],
     '3': [],
     '4': [],
    },
  },
  {
    id: 132,
    name: '今、がんばっていること',
    question:
      '今、がんばっていることがありますか',
    options: { 
      'ある': 1,
      '少しある': 2,
      'あまりない': 3,
      'ない': 4
    },
    kanjiQuestion: [
      { kanji: '今', hina: 'いま' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
    },
  },
  {
    id: 133,
    name: '現在の体調',
    question:
      '現在の体調はどうですか',
    options: { '良い': 1, 'ふつう': 2, '悪い': 3},
    kanjiQuestion: [
      { kanji: '現在', hina: 'げんざい' },
      { kanji: '体調', hina: 'たいちょう' },
    ],
    kanjiOption: {
     '1': [
      { kanji: '良', hina: 'よ' },
     ],
     '2': [],
     '3': [
      { kanji: '悪', hina: 'わる' },
     ]
    },
  },
  {
    id: 134,
    name: '清涼飲料水（コーラ・ジュース）',
    question:
      '清涼飲料水（コーラ・ジュース）をよく飲みますか',
    options: { 'よく飲む': 1, '少し飲む': 2, 'あまり飲まない': 3},
    kanjiQuestion: [
      { kanji: '清涼飲料', hina: 'せいりょういんりょう' },
      { kanji: '飲', hina: 'の' },
    ],
    kanjiOption: {
     '1': [
      { kanji: '飲', hina: 'の' },
     ],
     '2': [
      { kanji: '飲', hina: 'の' },
     ],
     '3': [
      { kanji: '飲', hina: 'の' },
     ]
    },
  },
  {
    id: 135,
    name: '朝起きるのがつらい',
    question:
      '朝起きるのがつらい時がありますか',
    options: { 'よくある': 1, '時々ある': 2, 'ほとんどない': 3},
    kanjiQuestion: [
      { kanji: '朝', hina: 'あさ' },
      { kanji: '起', hina: 'お' },
      { kanji: '時', hina: 'とき' },
    ],
    kanjiOption: {
     '1': [],
     '2': [
      { kanji: '時々', hina: 'ときどき' },
     ],
     '3': [],
    },
  },
  {
    id: 136,
    name: 'イライラすることがある',
    question:
      'イライラすることがありますか',
    options: { 'よくある': 1, '時々ある': 2, 'ほとんどない': 3},
    kanjiQuestion: [
    ],
    kanjiOption: {
      '1': [],
      '2': [
       { kanji: '時々', hina: 'ときどき' },
      ],
      '3': [],
    },
  },
  {
    id: 137,
    name: '1日の勉強時間',
    question:
      '家での、1日の勉強時間（じゅくなどでの勉強時間をふくみます）',
    options: { '1時間より少ない': 1, '1時間以上2時間より少ない': 2, '2時間以上3時間より少ない': 3, '3時間以上': 4},
    kanjiQuestion: [
      { kanji: '家', hina: 'いえ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '勉強時間', hina: 'べんきょうじかん' },
      { kanji: '勉強時間', hina: 'べんきょうじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ]
    },
  },
  {
    id: 138,
    name: '1日のパソコン・携帯電話の使用時間',
    question:
      '1日のパソコンや携帯電話の利用時間（メールも含む）',
    options: { '1時間より少ない': 1, '1時間以上2時間より少ない': 2, '2時間以上3時間より少ない': 3, '3時間以上': 4, '持っていない': 5},
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '携帯電話', hina: 'けいたいでんわ' },
      { kanji: '利用時間', hina: 'りようじかん' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '5': [
        { kanji: '持', hina: 'も' },
      ],
    },
  },
  {
    id: 139,
    name: '今、熱中し打ち込めること',
    question:
      '今、熱中し打ち込めることがありますか',
    options: { 'とても熱中していることがある': 1, 'やや熱中していることがある': 2, 'ない': 3},
    kanjiQuestion: [
      { kanji: '今', hina: 'いま' },
      { kanji: '熱中', hina: 'ねっちゅう' },
      { kanji: '打', hina: 'う' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '熱中', hina: 'ねっちゅう' },
      ],
      '2': [
        { kanji: '熱中', hina: 'ねっちゅう' },
      ],
      '3': [],
    },
  },
  {
    id: 140,
    name: '自主的に運動する時間を持ちたい',
    question:
      '自主的に運動（体を動かす遊びを含む）やスポーツをする時間をもちたいと思いますか',
    options: { '思う': 1, 'やや思う': 2, 'あまり思わない': 3, '思わない': 4},
    kanjiQuestion: [
      { kanji: '自主的', hina: 'じしゅてき' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '思', hina: 'おも' },
      ],
      '2': [
        { kanji: '思', hina: 'おも' },
      ],
      '3': [
        { kanji: '思', hina: 'おも' },
      ],
      '4': [
        { kanji: '思', hina: 'おも' },
      ],
    },
  },
  {
    id: 141,
    name: '平日のテレビ視聴時間',
    question:
      '平日（月～金曜日）について、学習以外で1日にどのくらいの時間、テレビやDVD、ゲーム機、スマートフォン、パソコンなどの画面を見ていますか',
    options: { '1時間未満': 1, '1時間以上2時間未満': 2, '2時間以上3時間未満': 3, '3時間以上': 4},
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '月', hina: 'げつ' },
      { kanji: '金曜日', hina: 'きんようび' },
      { kanji: '学習', hina: 'がくしゅう' },
      { kanji: '以外', hina: 'いがい' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '機', hina: 'き' },
      { kanji: '画面', hina: 'がめん' },
      { kanji: '見', hina: 'み' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '未満', hina: 'みまん' },  
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '以上', hina: 'いじょう' },
      ],
    },
  },
  {
    id: 142,
    name: '挑戦したいこと',
    question:
      '"運動やスポーツで、挑戦したいこと（できるようになりたいこと）はありますか',
    options: { 'ある': 1, 'ない': 2},
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '挑戦', hina: 'ちょうせん' },
    ],
    kanjiOption: {
      '1': [],
      '2': []
    },
  },
  {
    id: 143,
    name: '挑戦したいこと',
    question:
      '【「ある」と回答した人のみ】\n「ある」と答えた人は、どんなことですか',
    options: {
      '記録を向上させたい': 1,
      'より大きな大会に出場したい': 2,
      '大会・試合で好成績を収めたい': 3,
      '自分の得意なスポーツを見つけたい': 4,
      '未経験のスポーツを体験したい': 5,
      '地域のクラブなどに参加したい': 6,
      '体力をつけたい': 7,
      'その他': 8
    },
    kanjiQuestion: [
      { kanji: '答', hina: 'こた' },
      { kanji: '人', hina: 'ひと' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '記録', hina: 'きろく' },
        { kanji: '向上', hina: 'こうじょう' },
      ],
      '2': [
        { kanji: '大', hina: 'おお' },
        { kanji: '大会', hina: 'たいかい' },
        { kanji: '出場', hina: 'しゅつじょう' },
        { kanji: '出場', hina: 'しゅつじょう' },
      ],
      '3': [
        { kanji: '大会', hina: 'たいかい' },
        { kanji: '試合', hina: 'しあい' },
        { kanji: '好成績', hina: 'こうせいせき' },
        { kanji: '収', hina: 'おさ' },
      ],
      '4': [
        { kanji: '自分', hina: 'じぶん' },
        { kanji: '得意', hina: 'とくい' },
        { kanji: '見', hina: 'み' },
      ],
      '5': [
        { kanji: '未経験', hina: 'みけいけん' },
        { kanji: '体験', hina: 'たいけん' },
      ],
      '6': [
        { kanji: '地域', hina: 'ちいき' },
        { kanji: '参加', hina: 'さんか' },
      ],
      '7': [
        { kanji: '体力', hina: 'たいりょく' },
      ],
      '8': [
        { kanji: '他', hina: 'た' },
      ],
    },
    isOptional: true
  },
  {
    id: 144,
    name: '挑戦したいこと',
    question:
      '【「ある」と回答した人のみ】\n「ある」と答えた人は、そのことに挑戦できていますか（学校生活も含む）',
    options: {
      'できている': 1,
      'ややできている': 2,
      'あまりできていない': 3,
      'できていない': 4,
    },
    kanjiQuestion: [
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '答', hina: 'こた' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '挑戦', hina: 'ちょうせん' },
      { kanji: '学校生活', hina: 'がっこうせいかつ' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
    },
    isOptional: true
  },
  {
    id: 145,
    name: '視力右（裸眼）',
    question:
      '視力右（裸眼）\n※計測していない場合は「回答しない」を選択してください。',
    options: {
      'A（1.0以上）': 1,
      'B（0.9~0.7）': 2,
      'C（0.6~0.3）': 3,
      'D（0.3未満）': 4,
      '回答しない': 5,
    },
    kanjiQuestion: [
      { kanji: '視力', hina: 'しりょく' },
      { kanji: '右', hina: 'みぎ' },
      { kanji: '裸眼', hina: 'らがん' },
      { kanji: '計測', hina: 'けいそく' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [],
      '3': [],
      '4': [
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [
        { kanji: '回答', hina: 'かいとう' },
      ],
    }
  },
  {
    id: 146,
    name: '視力右（矯正）',
    question:
      '視力右（矯正）\n※計測していない場合は「回答しない」を選択してください。',
    options: {
      'A（1.0以上）': 1,
      'B（0.9~0.7）': 2,
      'C（0.6~0.3）': 3,
      'D（0.3未満）': 4,
      '回答しない': 5,
    },
    kanjiQuestion: [
      { kanji: '視力', hina: 'しりょく' },
      { kanji: '右', hina: 'みぎ' },
      { kanji: '矯正', hina: 'きょうせい' },
      { kanji: '計測', hina: 'けいそく' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [],
      '3': [],
      '4': [
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [
        { kanji: '回答', hina: 'かいとう' },
      ],
    }
  },
  {
    id: 147,
    name: '視力左（裸眼）',
    question:
      '視力左（裸眼）\n※計測していない場合は「回答しない」を選択してください。',
    options: {
      'A（1.0以上）': 1,
      'B（0.9~0.7）': 2,
      'C（0.6~0.3）': 3,
      'D（0.3未満）': 4,
      '回答しない': 5,
    },
    kanjiQuestion: [
      { kanji: '視力', hina: 'しりょく' },
      { kanji: '左', hina: 'ひだり' },
      { kanji: '裸眼', hina: 'らがん' },
      { kanji: '計測', hina: 'けいそく' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [],
      '3': [],
      '4': [
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [
        { kanji: '回答', hina: 'かいとう' },
      ],
    }
  },
  {
    id: 148,
    name: '視力左（矯正）',
    question:
      '視力左（矯正）\n※計測していない場合は「回答しない」を選択してください。',
    options: {
      'A（1.0以上）': 1,
      'B（0.9~0.7）': 2,
      'C（0.6~0.3）': 3,
      'D（0.3未満）': 4,
      '回答しない': 5,
    },
    kanjiQuestion: [
      { kanji: '視力', hina: 'しりょく' },
      { kanji: '左', hina: 'ひだり' },
      { kanji: '矯正', hina: 'きょうせい' },
      { kanji: '計測', hina: 'けいそく' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [],
      '3': [],
      '4': [
        { kanji: '未満', hina: 'みまん' },
      ],
      '5': [
        { kanji: '回答', hina: 'かいとう' },
      ],
    }
  },
  {
    id: 149,
    name: 'できるようになりたいこと',
    question:
      '運動やスポーツで、できるようになりたいこと（やってみたいこと）はありますか',
    options: {
      'ある': 1,
      'ない': 2,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
    }
  },
  {
    id: 150,
    name: 'できるようになりたいこと',
    question:
      '【「ある」と回答した人のみ】\n「ある」と答えた人は、どんなことですか',
    options: {
      '速く走りたい': 1,
      '泳ぎが上手くなりたい': 2,
      '球技が上手くなりたい': 3,
      'ダンスや体操が上手になりたい': 4,
      '大会・試合で勝ちたい': 5,
      'これまでしたことのないスポーツを体験してみたい': 6,
      'その他': 7,
    },
    kanjiQuestion: [
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '答', hina: 'こた' },
      { kanji: '人', hina: 'ひと' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '速', hina: 'はや' },
        { kanji: '走', hina: 'はし' },
      ],
      '2': [
        { kanji: '泳', hina: 'およ' },
        { kanji: '上手', hina: 'うま' },
      ],
      '3': [
        { kanji: '球技', hina: 'きゅうぎ' },
        { kanji: '上手', hina: 'うま' },
      ],
      '4': [
        { kanji: '体操', hina: 'たいそう' },
        { kanji: '上手', hina: 'うま' },
      ],
      '5': [
        { kanji: '大会', hina: 'たいかい' },
        { kanji: '試合', hina: 'しあい' },
        { kanji: '勝', hina: 'か' },
      ],
      '6': [
        { kanji: '体験', hina: 'たいけん' },
      ],
      '7': [
        { kanji: '他', hina: 'た' },
      ],
    },
    isOptional: true
  },
  {
    id: 151,
    name: '運動は好きか',
    question:
      '運動やスポーツをすることは好きですか',
    options: {
      '好き': 1,
      'どちらかといえば好き': 2,
      'どちらかといえば嫌い': 3,
      '嫌い': 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '好', hina: 'す' },
      ],
      '2': [
        { kanji: '好', hina: 'す' },
      ],
      '3': [
        { kanji: '嫌', hina: 'きら' },
      ],
      '4': [
        { kanji: '嫌', hina: 'きら' },
      ],
    },
  },
  {
    id: 152,
    name: '運動は好きか',
    question:
      '運動やスポーツをすることは好きですか',
    options: {
      '好き': 1,
      'やや好き': 2,
      'あまり好きではない': 3,
      'きらい': 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '好', hina: 'す' },
      ],
      '2': [
        { kanji: '好', hina: 'す' },
      ],
      '3': [
        { kanji: '好', hina: 'す' },
      ],
      '4': [],
    },
  },
  {
    id: 153,
    name: '就寝時刻',
    question:
      '夜更かしをせず、時刻を決めて寝ていますか',
    options: {
      '寝ている': 1,
      'だいたい寝ている': 2,
      '寝ていない': 3,
    },
    kanjiQuestion: [
      { kanji: '夜更', hina: 'よふ' },
      { kanji: '時刻', hina: 'じこく' },
      { kanji: '決', hina: 'き' },
      { kanji: '寝', hina: 'ね' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '寝', hina: 'ね' },
      ],
      '2': [
        { kanji: '寝', hina: 'ね' },
      ],
      '3': [
        { kanji: '寝', hina: 'ね' },
      ],
    },
  },
  {
    id: 154,
    name: '起床時刻',
    question:
      '早起きを心がけ、時刻を決めて起きていますか',
    options: {
      '起きている': 1,
      'だいたい起きている': 2,
      '起きていない': 3,
    },
    kanjiQuestion: [
      { kanji: '早起', hina: 'はやお' },
      { kanji: '心', hina: 'こころ' },
      { kanji: '時刻', hina: 'じこく' },
      { kanji: '決', hina: 'き' },
      { kanji: '起', hina: 'お' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '起', hina: 'お' },
      ],
      '2': [
        { kanji: '起', hina: 'お' },
      ],
      '3': [
        { kanji: '起', hina: 'お' },
      ],
    },
  },
  {
    id: 155,
    name: '好き嫌い',
    question:
      '好き嫌いをせず残さず食べていますか',
    options: {
      '食べている': 1,
      'だいたい食べている': 2,
      'よく残す': 3,
    },
    kanjiQuestion: [
      { kanji: '好', hina: 'す' },
      { kanji: '嫌', hina: 'きら' },
      { kanji: '残', hina: 'のこ' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '食', hina: 'た' },
      ],
      '3': [
        { kanji: '残', hina: 'のこ' },
      ],
    },
  },
  {
    id: 156,
    name: '家庭学習',
    question:
      '1日にどのくらい家庭学習をしていますか（塾の時間を含む）',
    options: {
      '30分未満': 1,
      '30分以上1時間未満': 2,
      '1時間以上2時間未満': 3,
      '2時間以上': 4
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '家庭学習', hina: 'かていがくしゅう' },
      { kanji: '塾', hina: 'じゅく' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ぷんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ぷんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ]
    },
  },
  {
    id: 157,
    name: '学校生活',
    question:
      '学校生活は楽しいですか',
    options: {
      '楽しい': 1,
      'やや楽しい': 2,
      'あまり楽しくない': 3,
      '楽しくない': 4
    },
    kanjiQuestion: [
      { kanji: '学校生活', hina: 'がっこうせいかつ' },
      { kanji: '楽', hina: 'たの' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '楽', hina: 'たの' },
      ],
      '2': [
        { kanji: '楽', hina: 'たの' },
      ],
      '3': [
        { kanji: '楽', hina: 'たの' },
      ],
      '4': [
        { kanji: '楽', hina: 'たの' },
      ]
    },
  },
  {
    id: 158,
    name: '学校の日の起床時間',
    question:
      '学校がある日、朝、何時頃起きますか',
    options: {
      '~5:59': 1,
      '6:00~6:29': 2,
      '6:30~6:59': 3,
      '7:00~7:29': 4,
      '7:30~7:59': 5,
      '8:00~': 6,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '朝', hina: 'あさ' },
      { kanji: '何時頃', hina: 'なんじごろ' },
      { kanji: '起', hina: 'お' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': []
    },
  },
  {
    id: 159,
    name: '休みの日の起床時間',
    question:
      '学校が休みの日、朝、何時頃起きますか',
    options: {
      '~6:59': 1,
      '7:00~7:29': 2,
      '7:30~7:59': 3,
      '8:00~8:29': 4,
      '8:30~8:59': 5,
      '9:00~9:29': 6,
      '9:30~09:59': 7,
      '10:00~': 8,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '休', hina: 'やす' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '朝', hina: 'あさ' },
      { kanji: '何時頃', hina: 'なんじごろ' },
      { kanji: '起', hina: 'お' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 160,
    name: '学校の日の睡眠時間',
    question:
      '学校がある日に何時間くらい寝ますか',
    options: {
      '6時間未満': 1,
      '6時間から8時間': 2,
      '8時間以上': 3,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '何時間', hina: 'なんじかん' },
      { kanji: '寝', hina: 'ね' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '時間', hina: 'じかん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 161,
    name: '食事をしない理由',
    question:
      '朝、食事をしない理由は何ですか',
    options: {
      '食べる時間がないから': 1,
      '家族も食べないから': 2,
      '食欲がないから': 3,
      '太りたくないから': 4,
      '食事が用意されていないから': 5,
      'その他': 6,
      '回答しない': 7,
    },
    kanjiQuestion: [
      { kanji: '朝', hina: 'あさ' },
      { kanji: '食事', hina: 'しょくじ' },
      { kanji: '理由', hina: 'りゆう' },
      { kanji: '何', hina: 'なに' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '食', hina: 'た' },
        { kanji: '時間', hina: 'じかん' },
      ],
      '2': [
        
        { kanji: '家族', hina: 'かぞく' },
        { kanji: '食', hina: 'た' },
      ],
      '3': [
        { kanji: '食欲', hina: 'しょくよく' },
      ],
      '4': [
        { kanji: '太', hina: 'ふと' },
      ],
      '5': [
        { kanji: '食事', hina: 'しょくじ' },
        { kanji: '用意', hina: 'ようい' },
      ],
      '6': [
        { kanji: '他', hina: 'た' },
      ],
      '7': [
        { kanji: '回答', hina: 'かいとう' },
      ],
    },
  },
  {
    id: 162,
    name: '大便',
    question:
      '大便は毎日出ますか',
    options: {
      '毎日、登校前に出る': 1,
      '毎日出るが、同じ頃ではない': 2,
      '2~4日に1回出る': 3,
      '5~7日に1回出る': 4,
    },
    kanjiQuestion: [
      { kanji: '大便', hina: 'だいべん' },
      { kanji: '毎日', hina: 'まいにち' },
      { kanji: '出', hina: 'で' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '登校前', hina: 'とうこうまえ' },
        { kanji: '出', hina: 'で' },
      ],
      '2': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '出', hina: 'で' },
        { kanji: '同', hina: 'おな' },
        { kanji: '頃', hina: 'ころ' },
      ],
      '3': [
        { kanji: '日', hina: 'にち' },
        { kanji: '回', hina: 'かい' },
        { kanji: '出', hina: 'で' },
      ],
      '4': [
        { kanji: '日', hina: 'にち' },
        { kanji: '出', hina: 'で' },
      ],
    },
  },
  {
    id: 163,
    name: '自由時間の運動',
    question:
      '学校での自由時間（中間休み・昼休み）に運動遊びやスポーツをしますか',
    options: {
      'よくする': 1,
      '時々する': 2,
      'あまりしない': 3,
      'ぜんぜんしない': 4,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '自由時間', hina: 'じゆうじかん' },
      { kanji: '中間休', hina: 'ちゅうかんやす' },
      { kanji: '昼休', hina: 'ひるやす' },
      { kanji: '運動遊', hina: 'うんどうあそ' },
    ],
    kanjiOption: {
      '1': [],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
      ],
      '3': [],
      '4': [],
    },
  },
  {
    id: 164,
    name: '運動は好きか',
    question:
      '運動や外遊び（体を動かしての遊び）は好きですか',
    options: {
      '好き': 1,
      'どちらかというと好き': 2,
      'どちらかというときらい': 3,
      'きらい': 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '外遊', hina: 'そとあそ' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '好', hina: 'す' },
      ],
      '2': [],
      '3': [],
      '4': [],
    },
  },
  {
    id: 165,
    name: 'スクリーンタイム',
    question:
      '平日に、学校以外で、1日に何時間くらいテレビやDVD、ゲーム機、スマートフォン、パソコンなどの画面を見ていますか',
    options: {
      '5時間以上': 1,
      '4時間以上5時間未満': 2,
      '3時間以上4時間未満': 3,
      '2時間以上3時間未満': 4,
      '1時間以上2時間未満': 5,
      '1時間未満': 6,
      'まったく見ない': 7,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '学校以外', hina: 'がっこういがい' },
      { kanji: '日', hina: 'にち' },
      { kanji: '何時間', hina: 'なんじかん' },
      { kanji: '機', hina: 'き' },
      { kanji: '画面', hina: 'がめん' },
      { kanji: '見', hina: 'み' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
      '2': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '6': [
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '7': [
        { kanji: '見', hina: 'み' },
      ],
    },
  },
  {
    id: 166,
    name: '夕食は誰と',
    question:
      '普段、夕食は誰と食べますか',
    options: {
      '誰かと一緒に食べる': 1,
      '一人で食べる': 2,
    },
    kanjiQuestion: [
      { kanji: '普段', hina: 'ふだん' },
      { kanji: '夕食', hina: 'ゆうしょく' },
      { kanji: '誰', hina: 'だれ' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '誰', hina: 'だれ' },
        { kanji: '一緒', hina: 'いっしょ' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '一人', hina: 'ひとり' },
        { kanji: '食', hina: 'た' },
      ],
    },
  },
  {
    id: 167,
    name: '寝る前のお菓子',
    question:
      '寝る前に、お菓子などを食べますか',
    options: {
      '毎日食べる': 1,
      '食べる日の方が多い': 2,
      '食べない日の方が多い': 3,
      'ほとんど食べない': 4,
    },
    kanjiQuestion: [
      { kanji: '寝', hina: 'ね' },
      { kanji: '前', hina: 'まえ' },
      { kanji: '菓子', hina: 'かし' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '方', hina: 'ほう' },
        { kanji: '多', hina: 'おお' },
      ],
      '3': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '方', hina: 'ほう' },
        { kanji: '多', hina: 'おお' },
      ],
      '4': [
        { kanji: '食', hina: 'た' },
      ],
    },
  },
  {
    id: 168,
    name: '学校の前日の就寝時間',
    question:
      '次の日、学校がある日、夜、何時頃寝ますか',
    options: {
      '~19:59': 1,
      '20:00~20:59': 2,
      '21:00~21:59': 3,
      '22:00~22:59': 4,
      '23:00~23:59': 5,
      '0:00~0:59': 6,
      '1:00~1:59': 7,
      '2:00~': 8,
    },
    kanjiQuestion: [
      { kanji: '次', hina: 'つぎ' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '夜', hina: 'よる' },
      { kanji: '何時頃', hina: 'なんじごろ' },
      { kanji: '寝', hina: 'ね' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 169,
    name: '休みの前日の就寝時間',
    question:
      '次の日、学校が休みの日、夜、何時頃寝ますか',
    options: {
      '~19:59': 1,
      '20:00~20:59': 2,
      '21:00~21:59': 3,
      '22:00~22:59': 4,
      '23:00~23:59': 5,
      '0:00~0:59': 6,
      '1:00~1:59': 7,
      '2:00~': 8,
    },
    kanjiQuestion: [
      { kanji: '次', hina: 'つぎ' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '休', hina: 'やす' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '夜', hina: 'よる' },
      { kanji: '何時頃', hina: 'なんじごろ' },
      { kanji: '寝', hina: 'ね' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
      '5': [],
      '6': [],
      '7': [],
      '8': [],
    },
  },
  {
    id: 170,
    name: '1ヶ月の読書量',
    question:
      '学校以外で、1ヶ月に本（漫画本を除く）を何冊ぐらい読みますか',
    options: {
      '0冊': 1,
      '1~3冊': 2,
      '4~6冊': 3,
      '7~9冊': 4,
      '10~12冊': 5,
      '13~15冊': 6,
      '16冊以上': 7,
    },
    kanjiQuestion: [
      { kanji: '学校以外', hina: 'がっこういがい' },
      { kanji: 'ヶ月', hina: 'かげつ' },
      { kanji: '本', hina: 'ほん' },
      { kanji: '漫画本', hina: 'まんがぼん' },
      { kanji: '除', hina: 'のぞ' },
      { kanji: '何冊', hina: 'なんさつ' },
      { kanji: '読', hina: 'よ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '冊', hina: 'さつ' },
      ],
      '2': [
        { kanji: '冊', hina: 'さつ' },
      ],
      '3': [
        { kanji: '冊', hina: 'さつ' },
      ],
      '4': [
        { kanji: '冊', hina: 'さつ' },
      ],
      '5': [
        { kanji: '冊', hina: 'さつ' },
      ],
      '6': [
        { kanji: '冊', hina: 'さつ' },
      ],
      '7': [
        { kanji: '冊', hina: 'さつ' },
        { kanji: '以上', hina: 'いじょう' },
      ],
    },
  },
  {
    id: 171,
    name: '本を読まない理由',
    question:
      '本を読まないのは、なぜですか',
    options: {
      '読みたい本がないから': 1,
      '嫌いだから': 2,
      '時間がないから': 3,
      '面倒くさいから': 4,
      'その他': 5,
      '回答しない': 6,
    },
    kanjiQuestion: [
      { kanji: '本', hina: 'ほん' },
      { kanji: '読', hina: 'よ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '読', hina: 'よ' },
        { kanji: '本', hina: 'ほん' },
      ],
      '2': [
        { kanji: '嫌', hina: 'きら' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
      ],
      '4': [
        { kanji: '面倒', hina: 'めんどう' },
      ],
      '5': [
        { kanji: '他', hina: 'た' },
      ],
      '6': [
        { kanji: '回答', hina: 'かいとう' },
      ],
    },
  },
  {
    id: 172,
    name: '読書をすすめられるか',
    question:
      '家の人から読書をすすめられますか',
    options: {
      'よくすすめられる': 1,
      'ときどきすすめられる': 2,
      'あまりすすめられない': 3,
      'まったくすすめられない': 4,
    },
    kanjiQuestion: [
      { kanji: '家', hina: 'いえ' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '読書', hina: 'どくしょ' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
    },
  },
  {
    id: 173,
    name: '読書は好きか',
    question:
      '読書（漫画以外）は好きですか',
    options: {
      '好き': 1,
      'どちらかというと好き': 2,
      'どちらかというときらい': 3,
      'きらい': 4,
    },
    kanjiQuestion: [
      { kanji: '読書', hina: 'どくしょ' },
      { kanji: '漫画以外', hina: 'まんがいがい' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '好', hina: 'す' },
      ],
      '2': [
        { kanji: '好', hina: 'す' },
      ],
      '3': [],
      '4': [],
    },
  },
  {
    id: 174,
    name: '授業以外の運動時間',
    question:
      '体育の授業以外に、どれくらい運動や外遊び（体を動かしての遊び）をしていますか',
    options: {
      '週3日以上': 1,
      '週1~2日': 2,
      '月1~3日': 3,
      'しない': 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '外遊', hina: 'そとあそ' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日以上', hina: 'にちいじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
      ],
      '3': [
        { kanji: '月', hina: 'つき' },
        { kanji: '日', hina: 'にち' },
      ],
      '4': [],
    },
  },
  {
    id: 175,
    name: '1週間のスポーツくらぶ',
    question:
      '1週間に何日、スポーツ少年団や民間スポーツクラブ（スイミングクラブやテニスクラブなど）に通っていますか',
    options: {
      '0日': 1,
      '1日': 2,
      '2日': 3,
      '3日': 4,
      '4日': 5,
      '5日': 6,
      '6日': 7,
      '7日': 8,
      
    },
    kanjiQuestion: [
      { kanji: '週間', hina: 'しゅうかん' },
      { kanji: '何日', hina: 'なんにち' },
      { kanji: '少年団', hina: 'しょうねんだん' },
      { kanji: '民間', hina: 'みんかん' },
      { kanji: '通', hina: 'かよ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '日', hina: 'にち' },
      ],
      '2': [
        { kanji: '日', hina: 'にち' },
      ],
      '3': [
        { kanji: '日', hina: 'にち' },
      ],
      '4': [
        { kanji: '日', hina: 'にち' },
      ],
      '5': [
        { kanji: '日', hina: 'にち' },
      ],
      '6': [
        { kanji: '日', hina: 'にち' },
      ],
      '7': [
        { kanji: '日', hina: 'にち' },
      ],
      '8': [
        { kanji: '日', hina: 'にち' },
      ],
    },
  },
  {
    id: 176,
    name: '部活動',
    question:
      '部活動に所属していますか',
    options: {
      '運動部に所属': 1,
      '文化部に所属': 2,
      '所属していない': 3,
    },
    kanjiQuestion: [
      { kanji: '部活動', hina: 'ぶかつどう' },
      { kanji: '所属', hina: 'しょぞく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '運動部', hina: 'うんどうぶ' },
        { kanji: '所属', hina: 'しょぞく' },
      ],
      '2': [
        { kanji: '文化部', hina: 'ぶんかぶ' },
        { kanji: '所属', hina: 'しょぞく' },
      ],
      '3': [
        { kanji: '所属', hina: 'しょぞく' },
      ],
    },
  },
  {
    id: 177,
    name: '休みの日の運動',
    question:
      '休みの日（土曜日や日曜日）に運動やスポーツ（小学生は体を使った外遊びも含む）をしていますか',
    options: {
      '毎週行う': 1,
      '月3回行う': 2,
      '月2回行う': 3,
      '行わない': 4,
      
    },
    kanjiQuestion: [
      { kanji: '休', hina: 'やす' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '土曜日', hina: 'どようび' },
      { kanji: '日曜日', hina: 'にちようび' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '小学生', hina: 'しょうがくせい' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '使', hina: 'つか' },
      { kanji: '外遊', hina: 'そとあそ' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎週', hina: 'まいしゅう' },
        { kanji: '行', hina: 'おこな' },
      ],
      '2': [
        { kanji: '月', hina: 'つき' },
        { kanji: '回', hina: 'かい' },
        { kanji: '行', hina: 'おこな' },
      ],
      '3': [
        { kanji: '月', hina: 'つき' },
        { kanji: '回', hina: 'かい' },
        { kanji: '行', hina: 'おこな' },
      ],
      '4': [
        { kanji: '行', hina: 'おこな' },
      ],
    },
  },
  {
    id: 178,
    name: '1日の運動時間',
    question:
      '運動や外遊び（体を使っての遊び）をする時には、1日どれくらいしますか。（体育の授業は除く）',
    options: {
      '30分未満': 1,
      '30分以上1時間未満': 2,
      '1時間以上2時間未満': 3,
      '2時間以上3時間未満': 4,
      '3時間以上': 5,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '外遊', hina: 'そとあそ' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '使', hina: 'つか' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '時', hina: 'とき' },
      { kanji: '日', hina: 'にち' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '除', hina: 'のぞ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ぷんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ぷんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  // 179
  {
    id: 179,
    name: '1日の視聴時間',
    question:
      '学習以外で、1日にどのくらいの時間、テレビやDVD、ゲーム機、スマートフォン、パソコンなどの画面を見ていますか',
    options: {
      '1時間未満': 1,
      '1時間以上2時間未満': 2,
      '2時間以上3時間未満': 3,
      '3時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '学習', hina: 'がくしゅう' },
      { kanji: '以外', hina: 'いがい' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '機', hina: 'き' },
      { kanji: '画面', hina: 'がめん' },
      { kanji: '見', hina: 'み' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '2': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },

  // 180
  {
    id: 180,
    name: 'ぐっすり眠れるか',
    question:
      'ぐっすり眠る（よく眠る）ことができますか',
    options: {
      '週に6日以上': 1,
      '週に4～5日': 2,
      '週に2〜3日': 3,
      '週に1日以下': 4,
    },
    kanjiQuestion: [
      { kanji: '眠', hina: 'ねむ' },
      { kanji: '眠', hina: 'ねむ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
      ],
      '3': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
      ],
      '4': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '以下', hina: 'いか' },
      ],
    },
  },
  {
    id: 181,
    name: '体育は楽しいか',
    question:
      '体育のじゅぎょうは楽しいですか',
    options: {
      '楽しい': 1,
      'やや楽しい': 2,
      'あまり楽しくない': 3,
      '楽しくない': 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '楽', hina: 'たの' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '楽', hina: 'たの' },
      ],
      '2': [
        { kanji: '楽', hina: 'たの' },
      ],
      '3': [
        { kanji: '楽', hina: 'たの' },
      ],
      '4': [
        { kanji: '楽', hina: 'たの' },
      ],
    },
  },
  {
    id: 182,
    name: '授業は集中しているか',
    question:
      'じゅぎょうでは集中して学習していますか',
    options: {
      'している': 1,
      'だいたいしている': 2,
      'あまりしていない': 3,
      'していない': 4,
    },
    kanjiQuestion: [
      { kanji: '集中', hina: 'しゅうちゅう' },
      { kanji: '学習', hina: 'がくしゅう' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
    },
  },
  {
    id: 183,
    name: '朝食の有無',
    question:
      '朝食の有無',
    options: {
      '毎日食べる': 1,
      'ときどき欠かす': 2,
      'まったく食べない': 3,
    },
    kanjiQuestion: [
      { kanji: '朝食', hina: 'ちょうしょく' },
      { kanji: '有無', hina: 'うむ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '欠', hina: 'か' },
      ],
      '3': [
        { kanji: '食', hina: 'た' },
      ],
    },
  },
  {
    id: 184,
    name: '1日の視聴時間',
    question:
      '学習以外で、1日のテレビやDVD、ゲーム機、スマートフォン、パソコンなどの視聴時間',
    options: {
      '1時間未満': 1,
      '1時間以上2時間未満': 2,
      '2時間以上3時間未満': 3,
      '3時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '学習', hina: 'がくしゅう' },
      { kanji: '以外', hina: 'いがい' },
      { kanji: '日', hina: 'にち' },
      { kanji: '機', hina: 'き' },
      { kanji: '視聴時間', hina: 'しちょうじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '2': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 185,
    name: '1日の勉強時間',
    question:
      '家での、1日の勉強時間（塾などでの勉強時間を含む）',
    options: {
      '30分未満': 1,
      '30分以上1時間未満': 2,
      '1時間以上2時間未満': 3,
      '2時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '家', hina: 'いえ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '勉強時間', hina: 'べんきょうじかん' },
      { kanji: '塾', hina: 'じゅく' },
      { kanji: '勉強時間', hina: 'べんきょうじかん' },
      { kanji: '含', hina: 'ふく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ぷんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ぷんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 186,
    name: '1日のケータイ・スマホの使用時間',
    question:
      '1日のケータイ・スマホの使用時間',
    options: {
      '30分未満': 1,
      '30分以上1時間未満': 2,
      '1時間以上2時間未満': 3,
      '2時間以上': 4,
      '持っていない': 5,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '使用時間', hina: 'しようじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ぷんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ぷんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
      '5': [
        { kanji: '持', hina: 'も' },
      ],
    },
  },
  {
    id: 187,
    name: '大人になってもやってみたいもの',
    question:
      '体育の授業で学んだ運動やスポーツの中で、大人になってもやってみたいと思ったものはありますか',
    options: {
      'ある': 1,
      'まあまあある': 2,
      'あまりない': 3,
      'まったくない': 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '学', hina: 'まな' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '中', hina: 'なか' },
      { kanji: '大人', hina: 'おとな' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
    },
  },
  {
    id: 188,
    name: 'コツやポイントをつかめているか',
    question:
      '体育の授業で、運動のコツやポイントをつかめていますか',
    options: {
      'つかめている': 1,
      'まあまあつかめている': 2,
      'あまりつかめていない': 3,
      'つかめていない': 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '運動', hina: 'うんどう' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
    },
  },
  {
    id: 189,
    name: '今、がんばっていること',
    question:
      '今、頑張っていることがありますか',
    options: {
      'ある': 1,
      '少しある': 2,
      'あまりない': 3,
      'ない': 4,
    },
    kanjiQuestion: [
      { kanji: '今', hina: 'いま' },
      { kanji: '頑張', hina: 'がんば' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
    },
  },
  {
    id: 190,
    name: '授業は集中しているか',
    question:
      '授業では集中して学習していますか',
    options: {
      'している': 1,
      'だいたいしている': 2,
      'あまりしていない': 3,
      'していない': 4,
    },
    kanjiQuestion: [
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '集中', hina: 'しゅうちゅう' },
      { kanji: '学習', hina: 'がくしゅう' },
    ],
    kanjiOption: {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
    },
  },

  {
    id: 191,
    name: '運動やスポーツは大切か',
    question:
      'あなたにとって運動（体を動かす遊びを含む）やスポーツは大切なものですか',
    options: {
      大切: 1,
      やや大切: 2,
      あまり大切でない: 3,
      大切でない: 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '大切', hina: 'たいせつ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '大切', hina: 'たいせつ' },
      ],
      '2': [
        { kanji: '大切', hina: 'たいせつ' },
      ],
      '3': [
        { kanji: '大切', hina: 'たいせつ' },
      ],
      '4': [
        { kanji: '大切', hina: 'たいせつ' },
      ],
    },
  },
  {
    id: 192,
    name: '聴力',
    question:
      '聴力\n※計測していない場合は「回答しない」を選択してください。',
    options: {
      正常: 1,
      右耳難聴: 2,
      左耳難聴: 3,
      両耳難聴: 4,
      回答しない: 5,
    },
    kanjiQuestion: [
      { kanji: '聴力', hina: 'ちょうりょく' },
      { kanji: '計測', hina: 'けいそく' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '回答', hina: 'かいとう' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '正常', hina: 'せいじょう' },
      ],
      '2': [
        { kanji: '右耳', hina: 'みぎみみ' },
        { kanji: '難聴', hina: 'なんちょう' },
      ],
      '3': [
        { kanji: '左耳', hina: 'ひだりみみ' },
        { kanji: '難聴', hina: 'なんちょう' },
      ],
      '4': [
        { kanji: '両耳', hina: 'りょうみみ' },
        { kanji: '難聴', hina: 'なんちょう' },
      ],
      '5': [
        { kanji: '回答', hina: 'かいとう' },
      ],
    },
  },
  {
    id: 193,
    name: '朝食の有無',
    question:
      '朝食は食べますか。',
    options: {
      毎日食べる: 1,
      食べる日の方が多い: 2,
      食べない日の方が多い: 3,
      ほとんど食べない: 4,
    },
    kanjiQuestion: [
      { kanji: '朝食', hina: 'ちょうしょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '方', hina: 'ほう' },
        { kanji: '多', hina: 'おお' },
      ],
      '3': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '方', hina: 'ほう' },
        { kanji: '多', hina: 'おお' },
      ],
      '4': [
        { kanji: '食', hina: 'た' },
      ],
    },
  },
  {
    id: 194,
    name: '1日の睡眠時間',
    question:
      '1日の睡眠時間はどのくらいですか。',
    options: {
      '6時間より少ない': 1,
      '6時間〜7時間くらい': 2,
      '7時間〜8時間くらい': 3,
      '8時間〜9時間くらい': 4,
      '9時間より多い': 5
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'にち' },
      { kanji: '睡眠時間', hina: 'すいみんじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '少', hina: 'すく' },
      ],
      '2': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '時間', hina: 'じかん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '時間', hina: 'じかん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '時間', hina: 'じかん' },
      ],
      '5': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '多', hina: 'おお' },
      ],
    },
  },
  {
    id: 195,
    name: '起床方法',
    question:
      '朝、どのようにして起きますか。',
    options: {
      'ひとりで起きる': 1,
      '目覚まし時計で起きる': 2,
      '家の人に起こされる': 3,
      'その他': 4,
    },
    kanjiQuestion: [
      { kanji: '朝', hina: 'あさ' },
      { kanji: '起', hina: 'お' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '起', hina: 'お' },
      ],
      '2': [
        { kanji: '目覚', hina: 'めざ' },
        { kanji: '時計', hina: 'どけい' },
        { kanji: '起', hina: 'お' },
      ],
      '3': [
        { kanji: '家', hina: 'いえ' },
        { kanji: '人', hina: 'ひと' },
        { kanji: '起', hina: 'お' },
      ],
      '4': [
        { kanji: '他', hina: 'た' },
      ],
    },
  },
  {
    id: 196,
    name: '大便はでるか',
    question:
      '大便（うんち）はでますか。',
    options: {
      '毎日朝にでる': 1,
      '朝ではないが、毎日でる': 2,
      '2〜3日に1回でる': 3,
      '1週間に1回でる': 4,
    },
    kanjiQuestion: [
      { kanji: '大便', hina: 'だいべん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '朝', hina: 'あさ' },
      ],
      '2': [
        { kanji: '朝', hina: 'あさ' },
        { kanji: '毎日', hina: 'まいにち' },
      ],
      '3': [
        { kanji: '日', hina: 'にち' },
        { kanji: '回', hina: 'かい' },
      ],
      '4': [
        { kanji: '週間', hina: 'しゅうかん' },
        { kanji: '回', hina: 'かい' },
      ],
    },
  },
  {
    id: 197,
    name: '夕食後のおやつ',
    question:
      '夕食後、よく夜食やおやつを食べますか。',
    options: {
      'よく食べる': 1,
      '時々食べる': 2,
      '食べない': 3,
    },
    kanjiQuestion: [
      { kanji: '夕食後', hina: 'ゆうしょくご' },
      { kanji: '夜食', hina: 'やしょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '食', hina: 'た' },
      ],
      '3': [
        { kanji: '食', hina: 'た' },
      ],
    },
  },
  {
    id: 198,
    name: '就寝時間',
    question:
      '何時ごろ寝ますか。',
    options: {
      '9時前': 1,
      '9時〜10時': 2,
      '10時〜11時': 3,
      '11時より後': 4,
    },
    kanjiQuestion: [
      { kanji: '何時', hina: 'なんじ' },
      { kanji: '寝', hina: 'ね' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時前', hina: 'じまえ' },
      ],
      '2': [
        { kanji: '時', hina: 'じ' },
        { kanji: '時', hina: 'じ' },
      ],
      '3': [
        { kanji: '時', hina: 'じ' },
        { kanji: '時', hina: 'じ' },
      ],
      '4': [
        { kanji: '時', hina: 'じ' },
        { kanji: '後', hina: 'あと' },
      ],
    },
  },
  {
    id: 199,
    name: 'すぐ眠れるか',
    question:
      'ふとんに入って（床について）すぐ眠れますか。',
    options: {
      'すぐ眠れる': 1,
      '時々すぐには眠れない': 2,
      'なかなか眠れない': 3,
    },
    kanjiQuestion: [
      { kanji: '入', hina: 'はい' },
      { kanji: '床', hina: 'とこ' },
      { kanji: '眠', hina: 'ねむ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '眠', hina: 'ねむ' },
      ],
      '2': [
        { kanji: '時々', hina: 'ときどき' },
        { kanji: '眠', hina: 'ねむ' },
      ],
      '3': [
        { kanji: '眠', hina: 'ねむ' },
      ],
    },
  },
  {
    id: 200,
    name: '歯みがき',
    question:
      '毎日歯みがきをしますか。',
    options: {
      '朝昼夜の1日3回': 1,
      '朝と夜（昼）の1日2回': 2,
      '朝（夜）の1日1回': 3,
      '歯みがきをしない': 4,
    },
    kanjiQuestion: [
      { kanji: '毎日', hina: 'まいにち' },
      { kanji: '歯', hina: 'は' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '朝昼夜', hina: 'あさひるよる' },
        { kanji: '日', hina: 'にち' },
        { kanji: '回', hina: 'かい' },
      ],
      '2': [
        { kanji: '朝', hina: 'あさ' },
        { kanji: '夜', hina: 'よる' },
        { kanji: '昼', hina: 'ひる' },
        { kanji: '日', hina: 'にち' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
        { kanji: '朝', hina: 'あさ' },
        { kanji: '夜', hina: 'よる' },
        { kanji: '日', hina: 'にち' },
        { kanji: '回', hina: 'かい' },
      ],
      '4': [
        { kanji: '歯', hina: 'は' },
      ],
    },
  },
  {
    id: 201,
    name: '帰宅後の学習',
    question:
      '学校から帰って、家でどのくらい勉強をしますか。',
    options: {
      'ほとんどしない': 1,
      '30分ぐらい': 2,
      '1時間ぐらい': 3,
      '1時間30分ぐらい': 4,
      '2時間以上': 5,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '帰', hina: 'かえ' },
      { kanji: '家', hina: 'いえ' },
      { kanji: '勉強', hina: 'べんきょう' },
    ],
    kanjiOption: {
      '1': [],
      '2': [
        { kanji: '分', hina: 'ぷん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
        { kanji: '分', hina: 'ぷん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 202,
    name: '月曜日の運動時間',
    question:
      '月曜日に運動やスポーツをどのくらいの時間しますか\n※「 分」で記入してください。\n※運動やスポーツをしない場合は「0」と記入してください。',
    options: {},
    kanjiQuestion: [
      { kanji: '月曜日', hina: 'げつようび' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '分', hina: 'ふん' },
      { kanji: '記入', hina: 'きにゅう' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '記入', hina: 'きにゅう' },
    ],
    kanjiOption: {},
    answerType: AnswerType.number,
    answerUnit: '分',
    kanjiAnswerUnit: [
      { kanji: '分', hina: 'ふん' },
    ],
  },
  {
    id: 203,
    name: '火曜日の運動時間',
    question:
      '火曜日に運動やスポーツをどのくらいの時間しますか\n※「 分」で記入してください。\n※運動やスポーツをしない場合は「0」と記入してください。',
    options: {},
    kanjiQuestion: [
      { kanji: '火曜日', hina: 'かようび' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '分', hina: 'ふん' },
      { kanji: '記入', hina: 'きにゅう' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '記入', hina: 'きにゅう' },
    ],
    kanjiOption: {},
    answerType: AnswerType.number,
    answerUnit: '分',
    kanjiAnswerUnit: [
      { kanji: '分', hina: 'ふん' },
    ],
  },
  {
    id: 204,
    name: '水曜日の運動時間',
    question:
      '水曜日に運動やスポーツをどのくらいの時間しますか\n※「 分」で記入してください。\n※運動やスポーツをしない場合は「0」と記入してください。',
    options: {},
    kanjiQuestion: [
      { kanji: '水曜日', hina: 'すいようび' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '分', hina: 'ふん' },
      { kanji: '記入', hina: 'きにゅう' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '記入', hina: 'きにゅう' },
    ],
    kanjiOption: {},
    answerType: AnswerType.number,
    answerUnit: '分',
    kanjiAnswerUnit: [
      { kanji: '分', hina: 'ふん' },
    ],
  },
  {
    id: 205,
    name: '木曜日の運動時間',
    question:
      '木曜日に運動やスポーツをどのくらいの時間しますか\n※「 分」で記入してください。\n※運動やスポーツをしない場合は「0」と記入してください。',
    options: {},
    kanjiQuestion: [
      { kanji: '木曜日', hina: 'もくようび' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '分', hina: 'ふん' },
      { kanji: '記入', hina: 'きにゅう' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '記入', hina: 'きにゅう' },
    ],
    kanjiOption: {},
    answerType: AnswerType.number,
    answerUnit: '分',
    kanjiAnswerUnit: [
      { kanji: '分', hina: 'ふん' },
    ],
  },
  {
    id: 206,
    name: '金曜日の運動時間',
    question:
      '金曜日に運動やスポーツをどのくらいの時間しますか\n※「 分」で記入してください。\n※運動やスポーツをしない場合は「0」と記入してください。',
    options: {},
    kanjiQuestion: [
      { kanji: '金曜日', hina: 'きんようび' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '分', hina: 'ふん' },
      { kanji: '記入', hina: 'きにゅう' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '記入', hina: 'きにゅう' },
    ],
    kanjiOption: {},
    answerType: AnswerType.number,
    answerUnit: '分',
    kanjiAnswerUnit: [
      { kanji: '分', hina: 'ふん' },
    ],
  },
  {
    id: 207,
    name: '土曜日の運動時間',
    question:
      '土曜日に運動やスポーツをどのくらいの時間しますか\n※「 分」で記入してください。\n※運動やスポーツをしない場合は「0」と記入してください。',
    options: {},
    kanjiQuestion: [
      { kanji: '土曜日', hina: 'どようび' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '分', hina: 'ふん' },
      { kanji: '記入', hina: 'きにゅう' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '記入', hina: 'きにゅう' },
    ],
    kanjiOption: {},
    answerType: AnswerType.number,
    answerUnit: '分',
    kanjiAnswerUnit: [
      { kanji: '分', hina: 'ふん' },
    ],
  },
  {
    id: 208,
    name: '日曜日の運動時間',
    question:
      '日曜日に運動やスポーツをどのくらいの時間しますか\n※「 分」で記入してください。\n※運動やスポーツをしない場合は「0」と記入してください。',
    options: {},
    kanjiQuestion: [
      { kanji: '日曜日', hina: 'にちようび' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '分', hina: 'ふん' },
      { kanji: '記入', hina: 'きにゅう' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '記入', hina: 'きにゅう' },
    ],
    kanjiOption: {},
    answerType: AnswerType.number,
    answerUnit: '分',
    kanjiAnswerUnit: [
      { kanji: '分', hina: 'ふん' },
    ],
  },
  {
    id: 209,
    name: '自主的に運動する時間を持ちたい',
    question:
      '中・高等学校を卒業した後、自主的に運動（体を動かす遊びを含む）やスポーツをする時間を持ちたいを思いますか。',
    options: {
      '思う': 1,
      'やや思う': 2,
      'あまり思わない': 3,
      '思わない': 4,
    },
    kanjiQuestion: [
      { kanji: '中', hina: 'ちゅう' },
      { kanji: '高等学校', hina: 'こうとうがっこう' },
      { kanji: '卒業', hina: 'そつぎょう' },
      { kanji: '後', hina: 'あと' },
      { kanji: '自主的', hina: 'じしゅてき' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '持', hina: 'も' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '思', hina: 'おも' },
      ],
      '2': [
        { kanji: '思', hina: 'おも' },
      ],
      '3': [
        { kanji: '思', hina: 'おも' },
      ],
      '4': [
        { kanji: '思', hina: 'おも' },
      ],
    },
  },
  {
    id: 210,
    name: '起床方法',
    question:
      'いつも朝は，どのようにして目をさますことが多いですか。',
    options: {
      'ひとりで目がさめる': 1,
      '目ざまし時計で目がさめる': 2,
      '家の人に起こされて目がさめる': 3,
      '起こされてもなかなか目がさめない': 4,
    },
    kanjiQuestion: [
      { kanji: '朝', hina: 'あさ' },
      { kanji: '目', hina: 'め' },
      { kanji: '多', hina: 'おお' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '目', hina: 'め' },
      ],
      '2': [
        { kanji: '目', hina: 'め' },
        { kanji: '時計', hina: 'どけい' },
        { kanji: '目', hina: 'め' },
      ],
      '3': [
        { kanji: '家', hina: 'いえ' },
        { kanji: '人', hina: 'ひと' },
        { kanji: '起', hina: 'お' },
        { kanji: '目', hina: 'め' },
      ],
      '4': [
        { kanji: '起', hina: 'おも' },
        { kanji: '目', hina: 'お' },
      ],
    },
  },
  {
    id: 211,
    name: '就寝時間',
    question:
      '睡眠時間はどれくらいですか。',
    options: {
      '５時間未満': 1,
      '５時間以上６時間未満': 2,
      '６時間以上７時間未満': 3,
      '７時間以上８時間未満': 4,
      '８時間以上': 5,
    },
    kanjiQuestion: [
      { kanji: '睡眠時間', hina: 'すいみんじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '2': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 212,
    name: '歯みがき',
    question:
      '毎食後，歯をみがきますか。',
    options: {
      '朝・昼・夜ともみがく（１日３回）': 1,
      '朝・夜（昼）だけみがく（１日２回）': 2,
      '朝・（夜）だけみがく（１日１回）': 3,
      'みがかない': 4,
    },
    kanjiQuestion: [
      { kanji: '毎食後', hina: 'まいしょくご' },
      { kanji: '歯', hina: 'は' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '朝', hina: 'あさ' },
        { kanji: '昼', hina: 'ひる' },
        { kanji: '夜', hina: 'よる' },
        { kanji: '日', hina: 'にち' },
        { kanji: '回', hina: 'かい' },
      ],
      '2': [
        { kanji: '朝', hina: 'あさ' },
        { kanji: '夜', hina: 'よる' },
        { kanji: '昼', hina: 'ひる' },
        { kanji: '日', hina: 'にち' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
        { kanji: '朝', hina: 'あさ' },
        { kanji: '夜', hina: 'よる' },
        { kanji: '日', hina: 'にち' },
        { kanji: '回', hina: 'かい' },
      ],
      '4': [
      ],
    },
  },
  {
    id: 213,
    name: '朝食の有無',
    question:
      'いつも朝食を食べますか。',
    options: {
      '毎日食べる': 1,
      '食べる日のほうが多い': 2,
      '食べない日のほうが多い': 3,
      '食べない': 4,
    },
    kanjiQuestion: [
      { kanji: '朝食', hina: 'ちょうしょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '多', hina: 'おお' },
      ],
      '3': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '多', hina: 'おお' },
      ],
      '4': [
        { kanji: '食', hina: 'た' },
      ],
    },
  },
  {
    id: 214,
    name: '大便はでるか',
    question:
      '大便は毎日出ますか。',
    options: {
      '毎日ほとんど同じ時間に出る': 1,
      '毎日出るが同じ時間ではない': 2,
      'ときどき出ない日がある': 3,
      '何日かつづけて出ないことがある': 4,
    },
    kanjiQuestion: [
      { kanji: '大便', hina: 'だいべん' },
      { kanji: '毎日', hina: 'まいにち' },
      { kanji: '出', hina: 'で' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '同', hina: 'おな' },
        { kanji: '時間', hina: 'じかん' },
        { kanji: '出', hina: 'で' },
      ],
      '2': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '出', hina: 'で' },
        { kanji: '同', hina: 'おな' },
        { kanji: '時間', hina: 'じかん' },
      ],
      '3': [
        { kanji: '出', hina: 'で' },
        { kanji: '日', hina: 'ひ' },
      ],
      '4': [
        { kanji: '何日', hina: 'なんにち' },
        { kanji: '出', hina: 'で' },
      ],
    },
  },
  {
    id: 215,
    name: '帰宅後の学習',
    question:
      'いつも学校から帰って，家でどのくらい勉強しますか。',
    options: {
      'ほとんどしない': 1,
      '30分くらい': 2,
      '１時間くらい': 3,
      '２時間くらい': 4,
      '3時間以上': 5,
    },
    kanjiQuestion: [
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '帰', hina: 'かえ' },
      { kanji: '家', hina: 'いえ' },
      { kanji: '勉強', hina: 'べんきょう' },
    ],
    kanjiOption: {
      '1': [
      ],
      '2': [
        { kanji: '分', hina: 'ぷん' },
      ],
      '3': [
        { kanji: '時間', hina: 'じかん' },
      ],
      '4': [
        { kanji: '時間', hina: 'じかん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 216,
    name: '放課後や帰宅後の運動時間',
    question:
      '運動部活動・スポーツクラブ以外で放課後や帰宅後に毎日何時間ぐらい運動していますか。',
    options: {
      '運動しない': 1,
      '30分未満': 2,
      '30分以上１時間未満': 3,
      '１時間以上２時間未満': 4,
      '２時間以上': 5,
    },
    kanjiQuestion: [
      { kanji: '運動部活動', hina: 'うんどうぶかつどう' },
      { kanji: '以外', hina: 'いがい' },
      { kanji: '放課後', hina: 'ほうかご' },
      { kanji: '帰宅後', hina: 'きたくご' },
      { kanji: '毎日', hina: 'まいにち' },
      { kanji: '何時間', hina: 'なんじかん' },
      { kanji: '運動', hina: 'うんどう' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '運動', hina: 'うんどう' },
      ],
      '2': [
        { kanji: '分未満', hina: 'ぷんみまん' },
      ],
      '3': [
        { kanji: '分以上', hina: 'ぷんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '分以上', hina: 'ぷんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 217,
    name: '所属状況',
    question:
      '校内で運動部やスポーツクラブに所属していますか。',
    options: {
      '所属している': 1,
      '所属していない': 2,
    },
    kanjiQuestion: [
      { kanji: '校内', hina: 'こうない' },
      { kanji: '運動部', hina: 'うんどうぶ' },
      { kanji: '所属', hina: 'しょぞく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '所属', hina: 'しょぞく' },
      ],
      '2': [
        { kanji: '所属', hina: 'しょぞく' },
      ],
    },
  },
  {
    id: 218,
    name: '運動の頻度',
    question:
      '運動やスポーツをどのくらいしていますか。',
    options: {
      'ほとんど毎日（週に３日以上）': 1,
      'ときどき（週に１～２日程度）': 2,
      'たまに（月に１～３日程度）': 3,
      'しない': 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
        { kanji: '以上', hina: 'いじょう' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'にち' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '3': [
        { kanji: '月', hina: 'つき' },
        { kanji: '日', hina: 'か' },
        { kanji: '程度', hina: 'ていど' },
      ],
      '4': [
      ],
    },
  },
  {
    id: 219,
    name: '1日の運動時間',
    question:
      '校内での部活動や校外での運動の習いごとで，毎日どのくらい運動していますか。',
    options: {
      '入っていない': 1,
      '１時間未満': 2,
      '１時間以上２時間未満': 3,
      '２時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '校内', hina: 'こうない' },
      { kanji: '部活動', hina: 'ぶかつどう' },
      { kanji: '校外', hina: 'こうがい' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '習', hina: 'なら' },
      { kanji: '毎日', hina: 'まいにち' },
      { kanji: '運動', hina: 'うんどう' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '入', hina: 'はい' },
      ],
      '2': [
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 220,
    name: '画面の視聴時間',
    question:
      'いつも家でテレビやスマートフォン（ゲームをふくむ）をどのくらいみていますか。',
    options: {
      '１時間未満': 1,
      '１時間以上２時間未満': 2,
      '２時間以上３時間未満': 3,
      '３時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '家', hina: 'いえ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '2': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 221,
    name: '夜食の有無',
    question:
      'あなたは夕食後に夜食（おやつをふくむ）を食べていますか。',
    options: {
      '毎日食べる': 1,
      '食べる日のほうが多い': 2,
      '食べない日のほうが多い': 3,
      '食べない': 4,
    },
    kanjiQuestion: [
      { kanji: '夕食後', hina: 'ゆうしょくご' },
      { kanji: '夜食', hina: 'やしょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '多', hina: 'おお' },
      ],
      '3': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '多', hina: 'おお' },
      ],
      '4': [
        { kanji: '食', hina: 'た' },
      ],
    },
  },
  {
    id: 222,
    name: '就寝時間',
    question:
      'いつも何時ごろにねますか。',
    options: {
      '10時まえ': 1,
      '10時から11時': 2,
      '11時から12時': 3,
      '12時以降': 4,
    },
    kanjiQuestion: [
      { kanji: '何時', hina: 'なんじ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時', hina: 'じ' },
      ],
      '2': [
        { kanji: '時', hina: 'じ' },
        { kanji: '時', hina: 'じ' },
      ],
      '3': [
        { kanji: '時', hina: 'じ' },
        { kanji: '時', hina: 'じ' },
      ],
      '4': [
        { kanji: '時以降', hina: 'じいこう' },
      ],
    },
  },
  {
    id: 223,
    name: 'すぐに眠れるか',
    question:
      'いつも，ねるときすぐに眠れますか。',
    options: {
      'すぐ眠れる': 1,
      '眠れる日のほうが多い': 2,
      '眠れない日のほうが多い': 3,
      '毎晩，なかなか眠れない': 4,
    },
    kanjiQuestion: [
      { kanji: '眠', hina: 'ねむ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '眠', hina: 'ねむ' },
      ],
      '2': [
        { kanji: '眠', hina: 'ねむ' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '多', hina: 'おお' },
      ],
      '3': [
        { kanji: '眠', hina: 'ねむ' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '多', hina: 'おお' },
      ],
      '4': [
        { kanji: '毎晩', hina: 'まいばん' },
        { kanji: '眠', hina: 'ねむ' },
      ],
    },
  },
  {
    id: 224,
    name: 'たちくらみ、めまい',
    question:
      '「たちくらみ」や「めまい」をおこしやすいですか。',
    options: {
      'よくある（１週間に１回以上）': 1,
      '１か月に１回くらい': 2,
      'たまにある': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
    ],
    kanjiOption: {
      '1': [
        { kanji: '週間', hina: 'しゅうかん' },
        { kanji: '回以上', hina: 'かいいじょう' },
      ],
      '2': [
        { kanji: '月', hina: 'げつ' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 225,
    name: '長時間立っている時の症状',
    question:
      '長い時間立っていると，目のまえがまっくらになったり，気持ちがわるくなったりすることはありますか。',
    options: {
      'よくある（１週間に１回以上）': 1,
      '１か月に１回くらい': 2,
      'たまにある': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
      { kanji: '長', hina: 'なが' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '立', hina: 'た' },
      { kanji: '目', hina: 'め' },
      { kanji: '気持', hina: 'きも' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週間', hina: 'しゅうかん' },
        { kanji: '回以上', hina: 'かいいじょう' },
      ],
      '2': [
        { kanji: '月', hina: 'げつ' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 226,
    name: '入浴中の症状',
    question:
      'おふろにはいったときや，いやなことを見たり聞いたりしたときに気持ちがわるくなったことがありますか。',
    options: {
      'よくある（１週間に１回以上）': 1,
      '１か月に１回くらい': 2,
      'たまにある': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
      { kanji: '見', hina: 'み' },
      { kanji: '聞', hina: 'き' },
      { kanji: '気持', hina: 'きも' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週間', hina: 'しゅうかん' },
        { kanji: '回以上', hina: 'かいいじょう' },
      ],
      '2': [
        { kanji: '月', hina: 'げつ' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 227,
    name: '動悸',
    question:
      '少し動くと，心臓がドキドキしますか。',
    options: {
      'よくある（１週間に１回以上）': 1,
      '１か月に１回くらい': 2,
      'たまにある': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
      { kanji: '少', hina: 'すこ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '心臓', hina: 'しんぞう' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週間', hina: 'しゅうかん' },
        { kanji: '回以上', hina: 'かいいじょう' },
      ],
      '2': [
        { kanji: '月', hina: 'げつ' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 228,
    name: '起床時、午前中の体調',
    question:
      '起床時に頭痛や腹痛がしたり，午前中は体の調子が悪いことがありますか。',
    options: {
      'よくある（１週間に１回以上）': 1,
      '１か月に１回くらい': 2,
      'たまにある': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
      { kanji: '起床時', hina: 'きしょうじ' },
      { kanji: '頭痛', hina: 'ずつう' },
      { kanji: '腹痛', hina: 'ふくつう' },
      { kanji: '午前中', hina: 'ごぜんちゅう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '調子', hina: 'ちょうし' },
      { kanji: '悪', hina: 'わる' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週間', hina: 'しゅうかん' },
        { kanji: '回以上', hina: 'かいいじょう' },
      ],
      '2': [
        { kanji: '月', hina: 'げつ' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 229,
    name: '顔色が悪いと言われたことがあるか',
    question:
      '人から，顔色がわるい（青白い）といわれることがありますか。',
    options: {
      'よくある': 1,
      'ときどきある': 2,
      'ほとんどない': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
      { kanji: '人', hina: 'ひと' },
      { kanji: '顔色', hina: 'かおいろ' },
      { kanji: '青白', hina: 'あおじろ' },
    ],
    kanjiOption: {
      '1': [
      ],
      '2': [
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 230,
    name: '食欲はないことがあるか',
    question:
      '食欲がないことがありますか。',
    options: {
      'よくある（１週間に１回以上）': 1,
      '１か月に１回くらい': 2,
      'たまにある': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
      { kanji: '食欲', hina: 'しょくよく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週間', hina: 'しゅうかん' },
        { kanji: '回以上', hina: 'かいいじょう' },
      ],
      '2': [
        { kanji: '月', hina: 'げつ' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 231,
    name: 'お腹が痛くなることがあるか',
    question:
      'すごくお腹がいたくなることがありますか。',
    options: {
      'よくある（１週間に１回以上）': 1,
      '１か月に１回くらい': 2,
      'たまにある': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
      { kanji: '腹', hina: 'なか' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週間', hina: 'しゅうかん' },
        { kanji: '回以上', hina: 'かいいじょう' },
      ],
      '2': [
        { kanji: '月', hina: 'げつ' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 232,
    name: 'だるさ、つかれやすさ',
    question:
      'からだの「だるさ」や「つかれやすさ」を感じることがありますか。',
    options: {
      'よくある（１週間に１回以上）': 1,
      '１か月に１回くらい': 2,
      'たまにある': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
      { kanji: '感', hina: 'かん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週間', hina: 'しゅうかん' },
        { kanji: '回以上', hina: 'かいいじょう' },
      ],
      '2': [
        { kanji: '月', hina: 'げつ' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 233,
    name: '乗り物よい',
    question:
      '乗り物にようことがありますか。',
    options: {
      'よくある': 1,
      'ときどきある': 2,
      'ほとんどない': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
      { kanji: '乗', hina: 'の' },
      { kanji: '物', hina: 'もの' },
    ],
    kanjiOption: {
      '1': [
      ],
      '2': [
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 234,
    name: '頭が痛くなることがあるか',
    question:
      '頭がいたくなることがありますか。',
    options: {
      'よくある（１週間に１回以上）': 1,
      '１か月に１回くらい': 2,
      'たまにある': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
      { kanji: '頭', hina: 'あたま' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週間', hina: 'しゅうかん' },
        { kanji: '回以上', hina: 'かいいじょう' },
      ],
      '2': [
        { kanji: '月', hina: 'げつ' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 235,
    name: '目の疲れ',
    question:
      '日ごろ，目がつかれたと感じることがありますか。',
    options: {
      'よくある（１週間に１回以上）': 1,
      '１か月に１回くらい': 2,
      'たまにある': 3,
      '全くない': 4,
    },
    kanjiQuestion: [
      { kanji: '日', hina: 'ひ' },
      { kanji: '目', hina: 'め' },
      { kanji: '感', hina: 'かん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '週間', hina: 'しゅうかん' },
        { kanji: '回以上', hina: 'かいいじょう' },
      ],
      '2': [
        { kanji: '月', hina: 'げつ' },
        { kanji: '回', hina: 'かい' },
      ],
      '3': [
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
      ],
    },
  },
  {
    id: 236,
    name: 'がんばり屋',
    question:
      'がんばり屋である',
    options: {
      'とても当てはまる': 1,
      'やや当てはまる': 2,
      'あまり当てはまらない': 3,
      '全く当てはまらない': 4,
    },
    kanjiQuestion: [
      { kanji: '屋', hina: 'や' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '当', hina: 'あ' },
      ],
      '2': [
        { kanji: '当', hina: 'あ' },
      ],
      '3': [
        { kanji: '当', hina: 'あ' },
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
        { kanji: '当', hina: 'あ' },
      ],
    },
  },
  {
    id: 237,
    name: '一生懸命',
    question:
      'どんなことにも一生懸命に取り組む',
    options: {
      'とても当てはまる': 1,
      'やや当てはまる': 2,
      'あまり当てはまらない': 3,
      '全く当てはまらない': 4,
    },
    kanjiQuestion: [
      { kanji: '一生懸命', hina: 'いっしょうけんめい' },
      { kanji: '取', hina: 'と' },
      { kanji: '組', hina: 'く' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '当', hina: 'あ' },
      ],
      '2': [
        { kanji: '当', hina: 'あ' },
      ],
      '3': [
        { kanji: '当', hina: 'あ' },
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
        { kanji: '当', hina: 'あ' },
      ],
    },
  },
  {
    id: 238,
    name: '忍耐力',
    question:
      'むずかしいことや辛いことにも負けない',
    options: {
      'とても当てはまる': 1,
      'やや当てはまる': 2,
      'あまり当てはまらない': 3,
      '全く当てはまらない': 4,
    },
    kanjiQuestion: [
      { kanji: '辛', hina: 'つら' },
      { kanji: '負', hina: 'ま' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '当', hina: 'あ' },
      ],
      '2': [
        { kanji: '当', hina: 'あ' },
      ],
      '3': [
        { kanji: '当', hina: 'あ' },
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
        { kanji: '当', hina: 'あ' },
      ],
    },
  },
  {
    id: 239,
    name: 'やり切る力',
    question:
      '始めたことはどんなことでも最後までやる',
    options: {
      'とても当てはまる': 1,
      'やや当てはまる': 2,
      'あまり当てはまらない': 3,
      '全く当てはまらない': 4,
    },
    kanjiQuestion: [
      { kanji: '始', hina: 'はじ' },
      { kanji: '最後', hina: 'さいご' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '当', hina: 'あ' },
      ],
      '2': [
        { kanji: '当', hina: 'あ' },
      ],
      '3': [
        { kanji: '当', hina: 'あ' },
      ],
      '4': [
        { kanji: '全', hina: 'まった' },
        { kanji: '当', hina: 'あ' },
      ],
    },
  },
  {
    id: 240,
    name: '運動の頻度',
    question:
      '地域スポーツクラブでの活動は週に何日ですか？',
    options: {
      '毎日': 1,
      '週6日': 2,
      '週5日': 3,
      '週4日': 4,
      '週3日': 5,
      '週1日': 6,
    },
    kanjiQuestion: [
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '活動', hina: 'かつどう' },
      { kanji: '週', hina: 'しゅう' },
      { kanji: '何日', hina: 'なんにち' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '3': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '4': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '5': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '6': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
    },
  },
  {
    id: 241,
    name: '1日の運動時間',
    question:
      '平日1日にどのくらいの時間、運動・遊びをしていますか？（体育の授業以外）＊地域スポーツクラブがある日（クラブに入っていない人は「なし」を選択）',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上': 4,
      'なし': 5,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '入', hina: 'はい' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
      '5': [
      ],
    },
  },
  {
    id: 242,
    name: '1日の運動時間',
    question:
      '平日1日にどのくらいの時間、運動・遊びをしていますか？（体育の授業以外）＊地域スポーツクラブがない日',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '日', hina: 'ひ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 243,
    name: '快眠できているか',
    question:
      'ぐっすり眠ることができていますか？',
    options: {
      'できている': 1,
      'どちらかというとできている': 2,
      'どちらかというとできていない': 3,
      'できていない': 4,
    },
    kanjiQuestion: [
      { kanji: '眠', hina: 'ねむ' },
    ],
    kanjiOption: {
      '1': [
      ],
      '2': [
      ],
      '3': [
      ],
      '4': [
      ],
    },
  },
  {
    id: 244,
    name: '運動は得意か',
    question:
      '運動やスポーツをすることは得意ですか？',
    options: {
      '得意': 1,
      'やや得意': 2,
      'やや苦手': 3,
      '苦手': 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '得意', hina: 'とくい' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '得意', hina: 'とくい' },
      ],
      '2': [
        { kanji: '得意', hina: 'とくい' },
      ],
      '3': [
        { kanji: '苦手', hina: 'にがて' },
      ],
      '4': [
        { kanji: '苦手', hina: 'にがて' },
      ],
    },
  },
  {
    id: 245,
    name: '体育の授業は楽しいか',
    question:
      '体育の授業は楽しいですか？',
    options: {
      '楽しい': 1,
      'やや楽しい': 2,
      'あまり楽しくない': 3,
      '楽しくない': 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '楽', hina: 'たの' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '楽', hina: 'たの' },
      ],
      '2': [
        { kanji: '楽', hina: 'たの' },
      ],
      '3': [
        { kanji: '楽', hina: 'たの' },
      ],
      '4': [
        { kanji: '楽', hina: 'たの' },
      ],
    },
  },
  {
    id: 246,
    name: '体育の授業は運動能力向上に向けて目標の設定楽しいか',
    question:
      '体力テストや体力・運動能力の向上について自分なりの目標を立てていますか？',
    options: {
      '立てている': 1,
      '立てていない': 2,
    },
    kanjiQuestion: [
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '運動能力', hina: 'うんどうのうりょく' },
      { kanji: '向上', hina: 'こうじょう' },
      { kanji: '自分', hina: 'じぶん' },
      { kanji: '目標', hina: 'もくひょう' },
      { kanji: '立', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '立', hina: 'た' },
      ],
      '2': [
        { kanji: '立', hina: 'た' },
      ],
    },
  },
  {
    id: 247,
    name: '最後までやり遂げたいと思うか',
    question:
      'なんでも最後までやりとげたいと思いますか？',
    options: {
      'とてもそう思う': 1,
      'まあそう思う': 2,
      'あまりそう思わない': 3,
      'まったく思わない': 4,
    },
    kanjiQuestion: [
      { kanji: '最後', hina: 'さいご' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '思', hina: 'おも' },
      ],
      '2': [
        { kanji: '思', hina: 'おも' },
      ],
      '3': [
        { kanji: '思', hina: 'おも' },
      ],
      '4': [
        { kanji: '思', hina: 'おも' },
      ],
    },
  },
  {
    id: 248,
    name: '平日1日の勉強時間',
    question:
      '平日１日の勉強時間はどれくらいですか？（塾に行っている人は塾がある日）',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上~3時間未満': 4,
      '3時間以上~4時間未満': 5,
      '4時間以上': 6,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '勉強時間', hina: 'べんきょうじかん' },
      { kanji: '塾', hina: 'じゅく' },
      { kanji: '行', hina: 'い' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '塾', hina: 'じゅく' },
      { kanji: '日', hina: 'ひ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '6': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 249,
    name: '所属している部活動',
    question:
      '運動部や地域スポーツクラブへの所属状況',
    options: {
      '学校の運動部': 1,
      '学校の文化部': 2,
      '地域スポーツクラブ': 3,
      '所属していない': 4,
    },
    kanjiQuestion: [
      { kanji: '運動部', hina: 'うんどうぶ' },
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '所属状況', hina: 'しょぞくじょうきょう' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '学校', hina: 'がっこう' },
        { kanji: '運動部', hina: 'うんどうぶ' },
      ],
      '2': [
        { kanji: '学校', hina: 'がっこう' },
        { kanji: '文化部', hina: 'ぶんかぶ' },
      ],
      '3': [
        { kanji: '地域', hina: 'ちいき' },
      ],
      '4': [
        { kanji: '所属', hina: 'しょぞく' },
      ],
    },
  },
  {
    id: 250,
    name: '運動の頻度',
    question:
      '地域スポーツクラブでの活動は週に何日ですか？',
    options: {
      '毎日': 1,
      '週6日': 2,
      '週5日': 3,
      '週4日': 4,
      '週3日': 5,
      'なし': 6
    },
    kanjiQuestion: [
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '活動', hina: 'かつどう' },
      { kanji: '週', hina: 'しゅう' },
      { kanji: '何日', hina: 'なんにち' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '3': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '4': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '5': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '6': [
      ]
    },
  },
  {
    id: 251,
    name: '1日の運動時間',
    question:
      '平日1日にどのくらいの時間、運動・遊びをしていますか？（体育の授業以外）＊地域スポーツクラブがある日（クラブに入っていない人は「なし」を選択）',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上': 4,
      'なし': 5
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '入', hina: 'はい' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
      '5': [
      ],
    },
  },
  {
    id: 252,
    name: '1日の運動時間',
    question:
      '平日1日にどのくらいの時間、運動・遊びをしていますか？（体育の授業以外）＊地域スポーツクラブがない日',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '日', hina: 'ひ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 253,
    name: '快眠できているか',
    question:
      'ぐっすり眠ることができていますか？',
    options: {
      'できている': 1,
      'どちらかというとできている': 2,
      'どちらかというとできていない': 3,
      'できていない': 4,
    },
    kanjiQuestion: [
      { kanji: '眠', hina: 'ねむ' },
    ],
    kanjiOption: {
      '1': [
      ],
      '2': [
      ],
      '3': [
      ],
      '4': [
      ],
    },
  },
  {
    id: 254,
    name: '運動は得意か',
    question:
      '運動やスポーツをすることは得意ですか？',
    options: {
      '得意': 1,
      'やや得意': 2,
      'やや苦手': 3,
      '苦手': 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '得意', hina: 'とくい' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '得意', hina: 'とくい' },
      ],
      '2': [
        { kanji: '得意', hina: 'とくい' },
      ],
      '3': [
        { kanji: '苦手', hina: 'にがて' },
      ],
      '4': [
        { kanji: '苦手', hina: 'にがて' },
      ],
    },
  },
  {
    id: 255,
    name: '体育の授業は楽しいか',
    question:
      '体育の授業は楽しいですか？',
    options: {
      '楽しい': 1,
      'やや楽しい': 2,
      'あまり楽しくない': 3,
      '楽しくない': 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '楽', hina: 'たの' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '楽', hina: 'たの' },
      ],
      '2': [
        { kanji: '楽', hina: 'たの' },
      ],
      '3': [
        { kanji: '楽', hina: 'たの' },
      ],
      '4': [
        { kanji: '楽', hina: 'たの' },
      ],
    },
  },
  {
    id: 256,
    name: '運動能力向上に向けて目標の設定',
    question:
      '体力テストや体力・運動能力の向上について自分なりの目標を立てていますか？',
    options: {
      '立てている': 1,
      '立てていない': 2,
    },
    kanjiQuestion: [
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '運動能力', hina: 'うんどうのうりょく' },
      { kanji: '向上', hina: 'こうじょう' },
      { kanji: '自分', hina: 'じぶん' },
      { kanji: '目標', hina: 'もくひょう' },
      { kanji: '立', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '立', hina: 'た' },
      ],
      '2': [
        { kanji: '立', hina: 'た' },
      ],
    },
  },
  {
    id: 257,
    name: '最後までやり遂げたいと思うか',
    question:
      'なんでも最後までやりとげたいと思いますか？',
    options: {
      'とてもそう思う': 1,
      'まあそう思う': 2,
      'あまりそう思わない': 3,
      'まったく思わない': 4,
    },
    kanjiQuestion: [
      { kanji: '最後', hina: 'さいご' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '思', hina: 'おも' },
      ],
      '2': [
        { kanji: '思', hina: 'おも' },
      ],
      '3': [
        { kanji: '思', hina: 'おも' },
      ],
      '4': [
        { kanji: '思', hina: 'おも' },
      ],
    },
  },
  {
    id: 258,
    name: '平日1日の勉強時間',
    question:
      '平日１日の勉強時間はどれくらいですか？（塾に入っている人は塾がある日の時間）',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上~3時間未満': 4,
      '3時間以上~4時間未満': 5,
      '4時間以上': 6,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '勉強時間', hina: 'べんきょうじかん' },
      { kanji: '塾', hina: 'じゅく' },
      { kanji: '行', hina: 'い' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '塾', hina: 'じゅく' },
      { kanji: '日', hina: 'ひ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '6': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 259,
    name: '所属状況',
    question:
      '運動部や地域スポーツクラブへの所属状況（複数回答可）',
    options: {
      '学校の運動部': 1,
      '学校の文化部': 2,
      '地域スポーツクラブ': 3,
      '所属していない': 4,
    },
    kanjiQuestion: [
      { kanji: '運動部', hina: 'うんどうぶ' },
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '所属状況', hina: 'しょぞくじょうきょう' },
    ],
    answerType: AnswerType.multipleSelect,
    kanjiOption: {
      '1': [
        { kanji: '学校', hina: 'がっこう' },
        { kanji: '運動部', hina: 'うんどうぶ' },
      ],
      '2': [
        { kanji: '学校', hina: 'がっこう' },
        { kanji: '文化部', hina: 'ぶんかぶ' },
      ],
      '3': [
        { kanji: '地域', hina: 'ちいき' },
      ],
      '4': [
        { kanji: '所属', hina: 'しょぞく' },
      ],
    },
  },
  {
    id: 260,
    name: '運動の頻度',
    question:
      '地域スポーツクラブでの活動は週に何日ですか？',
    options: {
      '毎日': 1,
      '週6日': 2,
      '週5日': 3,
      '週4日': 4,
      '週3日': 5,
      '週1日': 6,
      'なし': 7,
    },
    kanjiQuestion: [
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '活動', hina: 'かつどう' },
      { kanji: '週', hina: 'しゅう' },
      { kanji: '何日', hina: 'なんにち' },
    ],
    // 学校	がっこう	運動部	うんどうぶ
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '3': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '4': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '5': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '6': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '7': []
    },
  },
  {
    id: 261,
    name: '1日の運動時間',
    question:
      '平日1日にどのくらいの時間、運動・遊びをしていますか？（体育の授業以外）＊地域スポーツクラブがある日（クラブに入っていない人は「なし」を選択）',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上': 4,
      'なし': 5,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '入', hina: 'はい' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
      '5': []
    },
  },
  {
    id: 262,
    name: '1日の運動時間',
    question:
      '平日1日にどのくらいの時間、運動・遊びをしていますか？（体育の授業以外）＊地域スポーツクラブがない日',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '日', hina: 'ひ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 263,
    name: '朝食の有無',
    question:
      '朝食を食べていますか？',
    options: {
      '毎日食べる': 1,
      '食べない日もある': 2,
      '食べない日が多い': 3,
      '食べない': 4,
    },
    kanjiQuestion: [
      { kanji: '朝食', hina: 'ちょうしょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
      ],
      '3': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '多', hina: 'おお' },
      ],
      '4': [
        { kanji: '食', hina: 'た' },
      ],
    },
  },
  {
    id: 264,
    name: '睡眠時間',
    question:
      '平日1日の睡眠時間はどれくらいですか？',
    options: {
      '6時間未満': 1,
      '6時間以上~7時間未満': 2,
      '7時間以上~8時間未満': 3,
      '8時間以上~9時間未満': 4,
      '9時間以上~10時間未満': 5,
      '10時間以上': 6,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '睡眠時間', hina: 'すいみんじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '2': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '6': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 265,
    name: '快眠できているか',
    question:
      'ぐっすり眠ることができていますか？',
    options: {
      'できている': 1,
      'どちらかというとできている': 2,
      'どちらかというとできていない': 3,
      'できていない': 4,
    },
    kanjiQuestion: [
      { kanji: '眠', hina: 'ねむ' },
    ],
    kanjiOption: {
      '1': [
      ],
      '2': [
      ],
      '3': [
      ],
      '4': [
      ],
    },
  },
  {
    id: 266,
    name: '画面の視聴時間',
    question:
      '平日のテレビ・スマートフォン等（テレビゲーム・SNS等を含む）の使用時間はどれくらいですか？',
    options: {
      '見ない': 1,
      '1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上~3時間未満': 4,
      '3時間以上~4時間未満': 5,
      '4時間以上~5時間未満': 6,
      '5時間以上': 7
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '等', hina: 'など' },
      { kanji: '等', hina: 'など' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '使用時間', hina: 'しようじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '見', hina: 'み' },
      ],
      '2': [
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '6': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '7': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 267,
    name: '運動は好きか',
    question:
      '運動やスポーツをすることは好きですか？',
      // 好き	どちらかというと好き	どちらかというと嫌い	嫌い
    options: {
      '好き': 1,
      'どちらかというと好き': 2,
      'どちらかというと嫌い': 3,
      '嫌い': 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '好', hina: 'す' },
      ],
      '2': [
        { kanji: '好', hina: 'す' },
      ],
      '3': [
        { kanji: '嫌', hina: 'きら' },
      ],
      '4': [
        { kanji: '嫌', hina: 'きら' },
      ],
    },
  },
  {
    id: 268,
    name: '運動は好きか',
    question:
      '運動やスポーツをすることは得意ですか？',
    options: {
      '得意': 1,
      'やや得意': 2,
      'やや苦手': 3,
      '苦手': 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '得意', hina: 'とくい' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '得意', hina: 'とくい' },
      ],
      '2': [
        { kanji: '得意', hina: 'とくい' },
      ],
      '3': [
        { kanji: '苦手', hina: 'にがて' },
      ],
      '4': [
        { kanji: '苦手', hina: 'にがて' },
      ],
    },
  },
  {
    id: 269,
    name: '体育の授業は楽しいか',
    question:
      '体育の授業は楽しいですか？',
    options: {
      '楽しい': 1,
      'やや楽しい': 2,
      'あまり楽しくない': 3,
      '楽しくない': 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '楽', hina: 'たの' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '楽', hina: 'たの' },
      ],
      '2': [
        { kanji: '楽', hina: 'たの' },
      ],
      '3': [
        { kanji: '楽', hina: 'たの' },
      ],
      '4': [
        { kanji: '楽', hina: 'たの' },
      ],
    },
  },
  {
    id: 270,
    name: '運動能力向上に向けて目標の設定',
    question:
      '体力テストや体力・運動能力の向上について自分なりの目標を立てていますか？',
    options: {
      '立てている': 1,
      '立てていない': 2,
    },
    kanjiQuestion: [
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '運動能力', hina: 'うんどうのうりょく' },
      { kanji: '向上', hina: 'こうじょう' },
      { kanji: '自分', hina: 'じぶん' },
      { kanji: '目標', hina: 'もくひょう' },
      { kanji: '立', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '立', hina: 'た' },
      ],
      '2': [
        { kanji: '立', hina: 'た' },
      ],
    },
  },
  {
    id: 271,
    name: '最後までやり遂げたいと思うか',
    question:
      'なんでも最後までやりとげたいと思いますか？',
    options: {
      'とてもそう思う': 1,
      'まあそう思う': 2,
      'あまりそう思わない': 3,
      'まったく思わない': 4,
    },
    kanjiQuestion: [
      { kanji: '最後', hina: 'さいご' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '思', hina: 'おも' },
      ],
      '2': [
        { kanji: '思', hina: 'おも' },
      ],
      '3': [
        { kanji: '思', hina: 'おも' },
      ],
      '4': [
        { kanji: '思', hina: 'おも' },
      ],
    },
  },
  {
    id: 272,
    name: '平日1日の勉強時間',
    question:
      '平日１日の勉強時間はどれくらいですか？（塾に行っている人は塾がある日）',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上~3時間未満': 4,
      '3時間以上~4時間未満': 5,
      '4時間以上': 6,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '勉強時間', hina: 'べんきょうじかん' },
      { kanji: '塾', hina: 'じゅく' },
      { kanji: '行', hina: 'い' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '塾', hina: 'じゅく' },
      { kanji: '日', hina: 'ひ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '6': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 273,
    name: '所属状況',
    question:
      '地域スポーツクラブに入っていますか？',
    options: {
      '入っている': 1,
      '入っていない': 2,
    },
    kanjiQuestion: [
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '入', hina: 'はい' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '入', hina: 'はい' },
      ],
      '2': [
        { kanji: '入', hina: 'はい' },
      ],
    },
  },
  {
    id: 274,
    name: '運動の頻度',
    question:
      '地域スポーツクラブでの活動は週に何日ですか？',
    options: {
      '毎日': 1,
      '週6日': 2,
      '週5日': 3,
      '週4日': 4,
      '週3日': 5,
      '週1日': 6,
      'なし': 7,
    },
    kanjiQuestion: [
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '活動', hina: 'かつどう' },
      { kanji: '週', hina: 'しゅう' },
      { kanji: '何日', hina: 'なんにち' },
    ],
    // 学校	がっこう	運動部	うんどうぶ
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
      ],
      '2': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '3': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '4': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '5': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '6': [
        { kanji: '週', hina: 'しゅう' },
        { kanji: '日', hina: 'か' },
      ],
      '7': []
    },
  },
  {
    id: 275,
    name: '1日の運動時間',
    question:
      '平日1日にどのくらいの時間、運動・遊びをしていますか？（体育の授業以外）＊地域スポーツクラブがある日（クラブに入っていない人は「なし」を選択）',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上': 4,
      'なし': 5,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '日', hina: 'ひ' },
      { kanji: '入', hina: 'はい' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '選択', hina: 'せんたく' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
      '5': []
    },
  },
  {
    id: 276,
    name: '1日の運動時間',
    question:
      '平日1日にどのくらいの時間、運動・遊びをしていますか？（体育の授業以外）＊地域スポーツクラブがない日',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上': 4,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業以外', hina: 'じゅぎょういがい' },
      { kanji: '地域', hina: 'ちいき' },
      { kanji: '日', hina: 'ひ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 277,
    name: '朝食の有無',
    question:
      '朝食を食べていますか？',
    options: {
      '毎日食べる': 1,
      '食べない日もある': 2,
      '食べない日が多い': 3,
      '食べない': 4,
    },
    kanjiQuestion: [
      { kanji: '朝食', hina: 'ちょうしょく' },
      { kanji: '食', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '毎日', hina: 'まいにち' },
        { kanji: '食', hina: 'た' },
      ],
      '2': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
      ],
      '3': [
        { kanji: '食', hina: 'た' },
        { kanji: '日', hina: 'ひ' },
        { kanji: '多', hina: 'おお' },
      ],
      '4': [
        { kanji: '食', hina: 'た' },
      ],
    },
  },
  {
    id: 278,
    name: '睡眠時間',
    question:
      '平日1日の睡眠時間はどれくらいですか？',
    options: {
      '6時間未満': 1,
      '6時間以上~7時間未満': 2,
      '7時間以上~8時間未満': 3,
      '8時間以上~9時間未満': 4,
      '9時間以上~10時間未満': 5,
      '10時間以上': 6,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '睡眠時間', hina: 'すいみんじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '2': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '6': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 279,
    name: '快眠できているか',
    question:
      'ぐっすり眠ることができていますか？',
    options: {
      'できている': 1,
      'どちらかというとできている': 2,
      'どちらかというとできていない': 3,
      'できていない': 4,
    },
    kanjiQuestion: [
      { kanji: '眠', hina: 'ねむ' },
    ],
    kanjiOption: {
      '1': [
      ],
      '2': [
      ],
      '3': [
      ],
      '4': [
      ],
    },
  },
  {
    id: 280,
    name: '画面の視聴時間',
    question:
      '平日のテレビ・スマートフォン等（テレビゲーム・SNS等を含む）の使用時間はどれくらいですか？',
    options: {
      '見ない': 1,
      '1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上~3時間未満': 4,
      '3時間以上~4時間未満': 5,
      '4時間以上~5時間未満': 6,
      '5時間以上': 7
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '等', hina: 'など' },
      { kanji: '等', hina: 'など' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '使用時間', hina: 'しようじかん' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '見', hina: 'み' },
      ],
      '2': [
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '6': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '7': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 281,
    name: '運動は好きか',
    question:
      '運動やスポーツをすることは好きですか？',
      // 好き	どちらかというと好き	どちらかというと嫌い	嫌い
    options: {
      '好き': 1,
      'どちらかというと好き': 2,
      'どちらかというと嫌い': 3,
      '嫌い': 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '好', hina: 'す' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '好', hina: 'す' },
      ],
      '2': [
        { kanji: '好', hina: 'す' },
      ],
      '3': [
        { kanji: '嫌', hina: 'きら' },
      ],
      '4': [
        { kanji: '嫌', hina: 'きら' },
      ],
    },
  },
  {
    id: 282,
    name: '運動は好きか',
    question:
      '運動やスポーツをすることは得意ですか？',
    options: {
      '得意': 1,
      'やや得意': 2,
      'やや苦手': 3,
      '苦手': 4,
    },
    kanjiQuestion: [
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '得意', hina: 'とくい' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '得意', hina: 'とくい' },
      ],
      '2': [
        { kanji: '得意', hina: 'とくい' },
      ],
      '3': [
        { kanji: '苦手', hina: 'にがて' },
      ],
      '4': [
        { kanji: '苦手', hina: 'にがて' },
      ],
    },
  },
  {
    id: 283,
    name: '体育の授業は楽しいか',
    question:
      '体育の授業は楽しいですか？',
    options: {
      '楽しい': 1,
      'やや楽しい': 2,
      'あまり楽しくない': 3,
      '楽しくない': 4,
    },
    kanjiQuestion: [
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '楽', hina: 'たの' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '楽', hina: 'たの' },
      ],
      '2': [
        { kanji: '楽', hina: 'たの' },
      ],
      '3': [
        { kanji: '楽', hina: 'たの' },
      ],
      '4': [
        { kanji: '楽', hina: 'たの' },
      ],
    },
  },
  {
    id: 284,
    name: '運動能力向上に向けて目標の設定',
    question:
      '体力テストや体力・運動能力の向上について自分なりの目標を立てていますか？',
    options: {
      '立てている': 1,
      '立てていない': 2,
    },
    kanjiQuestion: [
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '運動能力', hina: 'うんどうのうりょく' },
      { kanji: '向上', hina: 'こうじょう' },
      { kanji: '自分', hina: 'じぶん' },
      { kanji: '目標', hina: 'もくひょう' },
      { kanji: '立', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '立', hina: 'た' },
      ],
      '2': [
        { kanji: '立', hina: 'た' },
      ],
    },
  },
  {
    id: 285,
    name: '最後までやり遂げたいと思うか',
    question:
      'なんでも最後までやりとげたいと思いますか？',
    options: {
      'とてもそう思う': 1,
      'まあそう思う': 2,
      'あまりそう思わない': 3,
      'まったく思わない': 4,
    },
    kanjiQuestion: [
      { kanji: '最後', hina: 'さいご' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '思', hina: 'おも' },
      ],
      '2': [
        { kanji: '思', hina: 'おも' },
      ],
      '3': [
        { kanji: '思', hina: 'おも' },
      ],
      '4': [
        { kanji: '思', hina: 'おも' },
      ],
    },
  },
  {
    id: 286,
    name: '平日1日の勉強時間',
    question:
      '平日１日の勉強時間はどれくらいですか？（塾に行っている人は塾がある日）',
    options: {
      '30分未満': 1,
      '30分以上~1時間未満': 2,
      '1時間以上~2時間未満': 3,
      '2時間以上~3時間未満': 4,
      '3時間以上~4時間未満': 5,
      '4時間以上': 6,
    },
    kanjiQuestion: [
      { kanji: '平日', hina: 'へいじつ' },
      { kanji: '日', hina: 'にち' },
      { kanji: '勉強時間', hina: 'べんきょうじかん' },
      { kanji: '塾', hina: 'じゅく' },
      { kanji: '行', hina: 'い' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '塾', hina: 'じゅく' },
      { kanji: '日', hina: 'ひ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '分未満', hina: 'ふんみまん' },
      ],
      '2': [
        { kanji: '分以上', hina: 'ふんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '3': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '4': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '5': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
        { kanji: '時間未満', hina: 'じかんみまん' },
      ],
      '6': [
        { kanji: '時間以上', hina: 'じかんいじょう' },
      ],
    },
  },
  {
    id: 287,
    name: '平日1日の勉強時間',
    question:
      '中学校に進んだら、授業以外でも自主的に運動（体を動かす遊びをふくむ）やスポーツをする時間を持ちたいと思いますか',
    options: {
      '思う': 1,
      'やや思う': 2,
      'あまり思わない': 3,
      '思わない': 4,
    },
    kanjiQuestion: [
      { kanji: '中学校', hina: 'ちゅうがっこう' },
      { kanji: '進', hina: 'すす' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '以外', hina: 'いがい' },
      { kanji: '自主的', hina: 'じしゅてき' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '持', hina: 'も' },
      { kanji: '思', hina: 'おも' },
      
    ],
    kanjiOption: {
      '1': [
        { kanji: '思', hina: 'おも' },
      ],
      '2': [
        { kanji: '思', hina: 'おも' },
      ],
      '3': [
        { kanji: '思', hina: 'おも' },
      ],
      '4': [
        { kanji: '思', hina: 'おも' },
      ],
    },
  },
  {
    id: 288,
    name: '休み時間に運動場や体育館で遊んでいますか',
    question:
      '休み時間に運動場や体育館で遊んでいますか。',
    options: {
      'いつも遊んでいる': 1,
      'だいだい遊んでいる': 2,
      'あまり遊んでいない': 3,
      '遊んでいない': 4,
    },
    kanjiQuestion: [
      { kanji: '休', hina: 'やす' },
      { kanji: '時間', hina: 'じかん' },
      { kanji: '運動場', hina: 'うんどうじょう' },
      { kanji: '体育館', hina: 'たいいくかん' },
      { kanji: '遊', hina: 'あそ' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '遊', hina: 'あそ' },
      ],
      '2': [
        { kanji: '遊', hina: 'あそ' },
      ],
      '3': [
        { kanji: '遊', hina: 'あそ' },
      ],
      '4': [
        { kanji: '遊', hina: 'あそ' },
      ],
    },
  },
  {
    id: 289,
    name: '休み時間に運動場や体育館で遊んでいますか',
    question:
      'ふだんの1週間について聞きます。学校の体育の授業以外で、運動（体を動かす遊びを含む）やスポーツを合計で1日およそどのくらいの時間していますか。それぞれの曜日のらんに記入してください。\n※運動しない場合は、「0」を入力してください。（記入式）',
    options: {
      '月〜分': 1,
      '火〜分': 2,
      '水〜分': 3,
      '木〜分': 4,
      '金〜分': 5,
      '土〜分': 6,
      '日〜分': 7,
    },
    kanjiQuestion: [
      { kanji: '1週間', hina: 'いっしゅうかん' },
      { kanji: '聞', hina: 'き' },
      { kanji: '学校', hina: 'がっこう' },
      { kanji: '体育', hina: 'たいいく' },
      { kanji: '授業', hina: 'じゅぎょう' },
      { kanji: '以外', hina: 'いがい' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '体', hina: 'からだ' },
      { kanji: '動', hina: 'うご' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '含', hina: 'ふく' },
      { kanji: '合計', hina: 'ごうけい' },
      { kanji: '日', hina: 'いちにち' },
      { kanji: '時間', hina: 'じかん' },
      // { kanji: '理由', hina: 'りゆう' },
      // { kanji: '教', hina: 'おし' },
      { kanji: '曜日', hina: 'ようび' },
      { kanji: '記入', hina: 'きにゅう' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '場合', hina: 'ばあい' },
      { kanji: '入力', hina: 'にゅうりょく' },
      { kanji: '記入式', hina: 'きにゅうしき' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '月', hina: 'げつ' },
      ],
      '2': [
        { kanji: '火', hina: 'か' },
      ],
      '3': [
        { kanji: '水', hina: 'すい' },
      ],
      '4': [
        { kanji: '木', hina: 'もく' },
      ],
      '5': [
        { kanji: '金', hina: 'きん' },
      ],
      '6': [
        { kanji: '土', hina: 'ど' },
      ],
      '7': [
        { kanji: '日', hina: 'にち' },
      ],
    },
    answerType: AnswerType.multipleText
  },
  {
    id: 290,
    name: '休み時間に運動場や体育館で遊んでいますか',
    question:
      'お手本動画は見ましたか',
    options: {
      '見た': 1,
      '見ていない': 2,
    },
    kanjiQuestion: [
      { kanji: '手本', hina: 'てほん' },
      { kanji: '動画', hina: 'どうが' },
      { kanji: '見', hina: 'み' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '見', hina: 'み' },
      ],
      '2': [
        { kanji: '見', hina: 'み' },
      ],
    },
  },
  {
    id: 291,
    name: '休み時間に運動場や体育館で遊んでいますか',
    question:
      '【お手本動画を見たと答えた人】\nいつ見ましたか？\nあてはまるものをすべて選んでください。',
    options: {
      '体力テスト前日以前': 1,
      '体力テスト直前': 2,
      '体力テスト当日': 3,
      '回答しない': 4,
    },
    kanjiQuestion: [
      { kanji: '手本', hina: 'てほん' },
      { kanji: '動画', hina: 'どうが' },
      { kanji: '見', hina: 'み' },
      { kanji: '答', hina: 'こた' },
      { kanji: '見', hina: 'み' },
      { kanji: '選', hina: 'えら' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '体力', hina: 'たいりょく' },
        { kanji: '前日以前', hina: 'ぜんじついぜん' },
      ],
      '2': [
        { kanji: '体力', hina: 'たいりょく' },
        { kanji: '直前', hina: 'ちょくぜん' },
      ],
      '3': [
        { kanji: '体力', hina: 'たいりょく' },
        { kanji: '当日', hina: 'とうじつ' },
      ],
      '4': [
        { kanji: '回答', hina: 'かいとう' },
      ],
    },
    answerType: AnswerType.multipleSelect
  },
  {
    id: 292,
    name: '休み時間に運動場や体育館で遊んでいますか',
    question:
      '体力テストの記録入力後、レーダーチャートや体力要素と評価（あなたへのアドバイス）を見ましたか',
    options: {
      '見た': 1,
      '見ていない': 2,
    },
    kanjiQuestion: [
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '記録', hina: 'きろく' },
      { kanji: '入力後', hina: 'にゅうりょくご' },
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '要素', hina: 'ようそ' },
      { kanji: '評価', hina: 'ひょうか' },
      { kanji: '見', hina: 'み' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '見', hina: 'み' },
      ],
      '2': [
        { kanji: '見', hina: 'み' },
      ],
    },
  },
  {
    id: 293,
    name: '休み時間に運動場や体育館で遊んでいますか',
    question:
      '自分に合った動画や運動あそび動画は見たことがありますか',
    options: {
      '見たことがある': 1,
      '見たことがない': 2,
    },
    kanjiQuestion: [
      { kanji: '自分', hina: 'じぶん' },
      { kanji: '合', hina: 'あ' },
      { kanji: '動画', hina: 'どうが' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '動画', hina: 'どうが' },
      { kanji: '見', hina: 'み' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '見', hina: 'み' },
      ],
      '2': [
        { kanji: '見', hina: 'み' },
      ],
    },
  },
  {
    id: 294,
    name: '休み時間に運動場や体育館で遊んでいますか',
    question:
      '【自分に合った動画や運動あそび動画を見たと答えた人】\n自分に合った動画や運動あそび動画にある遊びを実際に行ってみましたか？',
    options: {
      'はい': 1,
      'いいえ': 2,
      '回答しない': 3,
    },
    kanjiQuestion: [
      { kanji: '自分', hina: 'じぶん' },
      { kanji: '合', hina: 'あ' },
      { kanji: '動画', hina: 'どうが' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '動画', hina: 'どうが' },
      { kanji: '見', hina: 'み' },
      { kanji: '答', hina: 'こた' },
      { kanji: '人', hina: 'ひと' },
      { kanji: '自分', hina: 'じぶん' },
      { kanji: '合', hina: 'あ' },
      { kanji: '動画', hina: 'どうが' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '動画', hina: 'どうが' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '実際', hina: 'じっさい' },
      { kanji: '行', hina: 'おこな' },
    ],
    kanjiOption: {
      '1': [
      ],
      '2': [
      ],
      '3': [
        { kanji: '回答', hina: 'かいとう' },
      ],
    },
  },
  {
    id: 295,
    name: '休み時間に運動場や体育館で遊んでいますか',
    question:
      '体力テストデジタル集計システムは、新体力テストの目標や、体力についての目標を立てる上で役に立ちましたか',
    options: {
      '役に立った': 1,
      'やや役に立った': 2,
      'あまり役に立たなかった': 3,
      '役に立たなかった': 4,
    },
    kanjiQuestion: [
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '集計', hina: 'しゅうけい' },
      { kanji: '新体力', hina: 'しんたいりょく' },
      { kanji: '目標', hina: 'もくひょう' },
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '目標', hina: 'もくひょう' },
      { kanji: '立', hina: 'た' },
      { kanji: '役', hina: 'やく' },
      { kanji: '立', hina: 'た' },
    ],
    kanjiOption: {
      '1': [
        { kanji: '役', hina: 'やく' },
        { kanji: '立', hina: 'た' },
      ],
      '2': [
        { kanji: '役', hina: 'やく' },
        { kanji: '立', hina: 'た' },
      ],
      '3': [
        { kanji: '役', hina: 'やく' },
        { kanji: '立', hina: 'た' },
      ],
      '4': [
        { kanji: '役', hina: 'やく' },
        { kanji: '立', hina: 'た' },
      ],
    },
  },
  {
    id: 296,
    name: '休み時間に運動場や体育館で遊んでいますか',
    question:
      '体力テストデジタル集計システムを使うことで、もっと運動やスポーツ、遊びをしようと思いましたか。',
    options: {
      'はい': 1,
      'いいえ': 2,
    },
    kanjiQuestion: [
      { kanji: '体力', hina: 'たいりょく' },
      { kanji: '集計', hina: 'しゅうけい' },
      { kanji: '使', hina: 'つか' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '遊', hina: 'あそ' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [
      ],
      '2': [
      ],
    },
  },
  {
    id: 297,
    name: '休み時間に運動場や体育館で遊んでいますか',
    question:
      '2学期以降も「あなたへのアドバイス」や「自分に合った動画や運動あそび動画」を見たいと思いますか？',
    options: {
      'はい': 1,
      'いいえ': 2,
    },
    kanjiQuestion: [
      { kanji: '2学期', hina: 'にがっき' },
      { kanji: '以降', hina: 'いこう' },
      { kanji: '自分', hina: 'じぶん' },
      { kanji: '合', hina: 'あ' },
      { kanji: '動画', hina: 'どうが' },
      { kanji: '運動', hina: 'うんどう' },
      { kanji: '動画', hina: 'どうが' },
      { kanji: '見', hina: 'み' },
      { kanji: '思', hina: 'おも' },
    ],
    kanjiOption: {
      '1': [
      ],
      '2': [
      ],
    },
  },
]