import { createBlankArr } from '.'
import { allQuestions } from './questions/all-questions'

export const getCurrentPrefectureQuestions = (
  prefectureCode: number,
  schoolId: string,
  isElementarySchool: boolean,
  schoolCategoryCode: string | undefined,
): number[] => {
  // const prefectureCityQuestions = isElementarySchool
  //   ? prefectureCityQuestion2024ForElementStudent
  //   : prefectureCityQuestion2024ForJuniorOrHighSchoolStudent

  // let findingQuestions: PrefectureCityQuestion2024 | undefined

  // for (const key of customQuestionForSchoolIdsKeys) {
  //   if (customQuestionForSchoolIds[key].includes(schoolId)) {
  //     findingQuestions = prefectureCityQuestions.find((item) => item[1] === key)
  //     break
  //   }
  // }

  // if (findingQuestions === undefined) {
  //   findingQuestions = prefectureCityQuestions.find(
  //     (item) => item[0] === prefectureCode,
  //   )
  // }

  // const questionList: number[] =
  //   findingQuestions !== undefined ? findingQuestions[2] : []

  // if (questionList.length === 0) {
  //   console.error('No questions found in the prefecture data!')
  // }

  return [116, 191, 287, 288, 289, 41, 290, 291, 292, 293, 294, 295, 296, 297]
}

export const getCurrentPrefectureQuestionsWithPrefName = (
  prefectureCode: number,
  schoolId: string,
  isElementarySchool: boolean,
): { questions: number[]; name: string } => {
  // const prefectureCityQuestions = isElementarySchool
  //   ? prefectureCityQuestion2024ForElementStudent
  //   : prefectureCityQuestion2024ForJuniorOrHighSchoolStudent

  // let findingQuestions: PrefectureCityQuestion2024 | undefined

  // for (const key of customQuestionForSchoolIdsKeys) {
  //   if (customQuestionForSchoolIds[key].includes(schoolId)) {
  //     findingQuestions = prefectureCityQuestions.find((item) => item[1] === key)
  //     break
  //   }
  // }

  // if (findingQuestions === undefined) {
  //   findingQuestions = prefectureCityQuestions.find(
  //     (item) => item[0] === prefectureCode,
  //   )
  // }

  const questionList: number[] = [116, 191, 287, 288, 289, 41, 290, 291, 292, 293, 294, 295, 296, 297]
  return { questions: questionList, name: '' }
}

export const getIsOptionalQuestions = (questionList: number[]): boolean[] => {
  const isOptionalQuestions: boolean[] = []

  for (const questionId of questionList) {
    const findingQuestion = allQuestions.find(
      (question) => question.id === questionId,
    )

    isOptionalQuestions.push(findingQuestion?.isOptional ?? false)
  }

  return isOptionalQuestions
}

export const countRequiredQuestionsLength = (
  questionList: number[],
): number => {
  let count = 0

  for (const questionId of questionList) {
    const isFoundQuestion = allQuestions.some(
      (question) => question.id === questionId && !question.isOptional,
    )

    if (isFoundQuestion) count++
  }

  return count
}

/**
 * @returns array of number from 1 to length of questions list
 */
export const getSortedQuestionnairesIdArr = (
  prefectureCode: number,
  schoolCode: string,
  isElementary: boolean,
  schoolCategoryCode: string | undefined,
) => {
  const questionList = getCurrentPrefectureQuestions(
    prefectureCode,
    schoolCode,
    isElementary,
    schoolCategoryCode,
  )

  return createBlankArr(questionList.length)
}
