import { Row } from 'antd'
import { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Dashboard } from '../layout/Dashboard'

import { AuthContext } from '~/alpha/core'
import isJapanese from '~/utils/isJapanese'
import { TabIndex } from '../navGraph/DashboardNavGraphItem'

/**
 * Path: /movie
 */
const MoviePage = () => {
  const history = useHistory()

  const { t, i18n } = useTranslation()

  const auth = useContext(AuthContext)
  const isLevelB = auth.schoolLevel === 'B' || true
  const isUsingJpRuby = isLevelB && isJapanese(i18n)

  const title = (
    <div className="theme-nav-title">
      {isUsingJpRuby ? (
        <ruby>
          <span>動画</span>
          <rt className="font-normal text-18-hina">どうが</rt>
        </ruby>
      ) : (
        t('動画')
      )}
    </div>
  )

  const cardItems = [
    {
      url: 'advise',
      title: isUsingJpRuby ? (
        <span>
          お
          <ruby>
            <span>手本動画</span>
            <rt className="font-normal text-12-hina">てほんどうが</rt>
          </ruby>
        </span>
      ) : (
        t('お手本動画')
      ),
      image: '/images/MoviePage.png',
      desc: isUsingJpRuby ? (
        <span style={{ fontWeight: 'bold' }}>
          <ruby>
            <span style={{ paddingLeft: 15 }}>体力</span>
            <rt className="font-normal text-10-hina">たいりょく</rt>
          </ruby>
          テストの
          <ruby>
            <span>前</span>
            <rt className="font-normal text-10-hina">まえ</rt>
          </ruby>
          に
          <ruby>
            <span>見</span>
            <rt className="font-normal text-10-hina">み</rt>
          </ruby>
          {'て\nスコアをあげよう！'}
        </span>
      ) : (
        <Trans i18nKey="体力テストの前に見てスコアをあげよう！" />
      ),
    },
    {
      url: 'videos',
      title: isUsingJpRuby ? (
        <span>
          <ruby>
            <span>自分</span>
            <rt className="font-normal text-12-hina">じぶん</rt>
          </ruby>
          にあった
          <ruby>
            <span>動画</span>
            <rt className="font-normal text-12-hina">どうが</rt>
          </ruby>
        </span>
      ) : (
        t('自分にあった動画')
      ),
      image: '/images/VideosPage.png',
      desc: isUsingJpRuby ? (
        <span style={{ fontWeight: 'bold' }}>
          <ruby>
            <span style={{ paddingLeft: 15 }}>体力</span>
            <rt className="font-normal text-10-hina">たいりょく</rt>
          </ruby>
          テストの
          <ruby>
            <span>結果</span>
            <rt className="font-normal text-10-hina">けっか</rt>
          </ruby>
          {'からあなた\n'}
          にあった
          <ruby>
            <span>動画</span>
            <rt className="font-normal text-10-hina">どうが</rt>
          </ruby>
          を
          <ruby>
            <span>見</span>
            <rt className="font-normal text-10-hina">み</rt>
          </ruby>
          つけたよ。
        </span>
      ) : (
        <Trans i18nKey="体力テストの結果からあなたにあった動画を見つけたよ。" />
      ),
    },
  ]

  const Card = (cardItem, idx) => (
    <div
      key={idx}
      className="w-56 h-96 pt-6 rounded-10px cursor-pointer p-2"
      style={{ background: '#D3ECF3' }}
      onClick={() => history.push(`/${cardItem.url}`)}
    >
      <Row justify="center" align="bottom" className="text-2xl font-black h-11">
        <span className="p-2">{cardItem.title}</span>
      </Row>

      <Row
        justify="center"
        className="w-full items-end"
        style={{ height: 246 }}
      >
        <img
          src={cardItem.image}
          alt={cardItem.title}
          style={{ objectFit: 'contain' }}
        />
      </Row>

      <Row justify="center" className="w-full">
        <span className="text-center" style={{ whiteSpace: 'break-spaces' }}>
          {cardItem.desc}
        </span>
      </Row>
    </div>
  )

  const CardMobile = (cardItem, idx) => (
    <div
      key={idx}
      className="mb-6 pt-6 pb-3 rounded-10px cursor-pointer tag-item"
      style={{ background: '#D3ECF3', width: '100%', height: 290 }}
      onClick={() => history.push(`/${cardItem.url}`)}
    >
      <Row justify="center" align="bottom" className="text-2xl font-black">
        <span>{cardItem.title}</span>
      </Row>

      <Row
        justify="center"
        className="w-full items-end"
        style={{ height: 160 }}
      >
        <img
          src={cardItem.image}
          alt={cardItem.title}
          style={{ objectFit: 'contain', height: 160 }}
        />
      </Row>

      <Row justify="center" className="w-full">
        <span
          className="text-center tag-desc p-3"
          style={{ whiteSpace: 'break-spaces' }}
        >
          {cardItem.desc}
        </span>
      </Row>
    </div>
  )

  return (
    <Dashboard selectedMenu={TabIndex.Movie} navbar={title}>
      <div className="show-pc">
        <Row justify="center" align="middle" className="space-x-4 mt-12 p-3">
          {cardItems.map((cardItem, idx) => Card(cardItem, idx))}
        </Row>
      </div>
      <div className="show-sp">
        <Row
          justify="center"
          align="middle"
          className="pt-6 m-auto"
          style={{ width: 'calc(100vw - 30px)' }}
        >
          {cardItems.map((cardItem, idx) => CardMobile(cardItem, idx))}
        </Row>
      </div>
    </Dashboard>
  )
}

export default MoviePage
