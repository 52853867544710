import './Dashboard.css'

import { useApolloClient } from '@apollo/client'
import { Button, Modal, Row, message } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AuthContext } from '@alpha/core'
import { CloseOutlined } from '@ant-design/icons'
import { useAlphaStore } from '~/context'
import { useIsOnlineStore } from '~/context/online'
import { useStudentMeApiQuery } from '~/lib/hooks/hooks'
import { clearStorageAndAuthToken } from '~/student-utils/storages'
import Api from '~/utils/api'
import isJapanese from '~/utils/isJapanese'
import Card from '../cards/Card'
import { Rt } from '../common/Rt'
import { WarnIcon } from '../icons/WarnIcon'
import { ITestResForm } from '../inputs/type'
import RightDropdown from './RightDropdown'

export const Dashboard = ({
  navbar,
  children,
  selectedMenu = 1,
  authenticated = true,
}) => {
  const { t, i18n } = useTranslation()

  const client = useApolloClient()
  const history = useHistory()
  const auth = useContext(AuthContext)

  const isLevelB = auth.schoolLevel === 'B' || true
  const isUsingJp = isJapanese(i18n)
  const isUsingJpRuby = isLevelB && isUsingJp

  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useStudentMeApiQuery()

  const {
    warnBeforeLeave,
    setWarnBeforeLeave,
    setSchool,
    setMe,
    setIsLoading,
  } = useAlphaStore()

  const [showWarnModal, setShowWarnModal] = useState(false)
  const [goingPath, setGoingPath] = useState('')

  const { isOnline } = useIsOnlineStore()

  useEffect(() => {
    setIsLoading(meLoading)
    if (!meLoading) {
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meLoading])

  useEffect(() => {
    if (!meData?.school) {
      return
    }

    setSchool({
      _id: meData.school._id,
      attributes: meData.school,
    })

    setMe(meData)

    // TODO: refactor this
    const dataTest = localStorage.getItem('form_test_alpha')

    if (dataTest) {
      const dataForm = JSON.parse(dataTest) as ITestResForm

      if (dataForm) {
        onSubmitForm(dataForm)
      } else {
        localStorage.removeItem('form_test_alpha')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meData])

  const onSubmitForm = async (values: ITestResForm) => {
    try {
      const res = await Api.post('/alpha/v1/student/result', values)
      // console.log('🚀 ~ onSubmitForm ~ values:', values)

      if (res.status === 200) {
        message.success(t('登録完了'))
        localStorage.removeItem('form_test_alpha')
        history.push('/result')
        return
      }
      if (res.status === 500) {
        message.error(`${t('エラーが発生しました。')} [${res.data.error}]`)
        return
      }
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${err}]`)
    }
  }

  if (
    authenticated &&
    !meLoading &&
    !meData?.school &&
    meError?.response?.status === 401
  ) {
    // when authenticated, loading is done, school response is null and API authorized error
    // then force student logout!
    // WARNING: Only at 04/01 each year, user will be forced to logout!
    // So carefully when use this component to force student logout!
    return (
      <div className="w-screen h-screen flex items-center justify-center bg-gray-400 transition-opacity duration-500">
        <Card>
          <div className="flex flex-col items-center justify-center space-y-4">
            <Button
              type="primary"
              onClick={async () => {
                clearStorageAndAuthToken()
                await client.clearStore()

                setTimeout(() => {
                  window.open(`/?${Date.now()}`, '_self')
                }, 500)
              }}
            >
              {t('ログアウト')}
            </Button>
          </div>
        </Card>
      </div>
    )
  }

  const gotoPath = (path: string) => {
    if (isOnline === false) {
      return
    }

    if (warnBeforeLeave) {
      setGoingPath(path)
      setShowWarnModal(true)
      return
    }

    history.push(path)
  }

  const checkActive = (index: number) => {
    return index === selectedMenu
  }

  return (
    <>
      {/* right top menu */}
      <div className="flex justify-center items-center h-20 fixed right-0 text-white text-base font-bold print:hidden right-menu">
        <RightDropdown isUsingJpRuby={isUsingJpRuby} />
      </div>

      <div className="flex bg-gray-50 h-screen">
        <div className="flex-auto flex flex-col bg-white md:shadow-lg">
          {/* top bar pc */}
          <div className="show-pc">
            <div className="flex justify-center text-white bg-primary items-center fixed w-full h-20 z-50 print:hidden">
              <div className="flex items-center text-base font-black">
                {navbar}
              </div>
            </div>
          </div>

          <div className="show-sp">
            <div className="flex justify-center text-white bg-primary items-center fixed w-full h-16 z-50 print:hidden">
              <div className="flex items-center text-base font-black">
                {navbar}
              </div>
            </div>
          </div>

          <div className="form-content h-screen">
            {/* left menu for PC */}
            <div className="show-pc">
              <div className="menu-dashboard fixed flex menu-dashboard-pc">
                <div
                  className={`menu-item item1 ${
                    checkActive(1) ? 'menuActive' : ''
                  }`}
                  onClick={() => {
                    gotoPath('/movie')
                  }}
                >
                  <img src="images/movie_icon.png" alt="" />
                  {isUsingJpRuby ? (
                    <ruby>
                      <span>動画</span>
                      <rt className="text-12-hina font-normal">どうが</rt>
                    </ruby>
                  ) : (
                    <span>{t('動画')}</span>
                  )}
                </div>

                <div
                  className={`menu-item item2 ${
                    checkActive(2) ? 'menuActive' : ''
                  }`}
                  onClick={() => {
                    gotoPath('/tests')
                  }}
                >
                  <img src="images/test_icon.png" alt="" />
                  <span>{t('Tests')}</span>
                </div>

                <div
                  className={`menu-item item3 ${
                    checkActive(3) ? 'menuActive' : ''
                  }`}
                  onClick={() => {
                    gotoPath('/questionnaire')
                  }}
                >
                  <img src="images/question_icon.png" alt="" />
                  <span className={isUsingJp ? '' : 'text-10px'}>
                    {t('アンケート')}
                  </span>
                </div>

                <div
                  className={`menu-item item4 ${
                    checkActive(4) ? 'menuActive' : ''
                  }`}
                  onClick={() => {
                    gotoPath('/view')
                  }}
                >
                  <img src="images/view_icon.png" alt="" />
                  <span>{t('みる')}</span>
                </div>

                <div
                  className={`menu-item item5 ${
                    checkActive(5) ? 'menuActive' : ''
                  }`}
                  onClick={() => {
                    gotoPath('/my_page')
                  }}
                >
                  <img src="images/user_icon.png" alt="" />
                  <span>{t('マイページ')}</span>
                </div>
              </div>
            </div>

            {/* children content */}
            <div
              className={`mt-20 h-full form-children children-content ${
                meLoading ? 'hidden' : ''
              }`}
            >
              {children}
            </div>

            {/* menu for SP */}
            <div className="menu-dashboard menu-dashboard-sp">
              <div className="flex">
                <div
                  className={`menu-item item1 ${
                    checkActive(1) ? 'menuActive' : ''
                  }`}
                  onClick={() => {
                    gotoPath('/movie')
                  }}
                >
                  <img src="images/movie_icon.png" alt="" />
                  {isUsingJpRuby ? (
                    <ruby>
                      <span>動画</span>
                      <rt className="text-12-hina font-normal">どうが</rt>
                    </ruby>
                  ) : (
                    <span>{t('動画')}</span>
                  )}
                </div>
                <div
                  className={`menu-item item2 ${
                    checkActive(2) ? 'menuActive' : ''
                  }`}
                  onClick={() => {
                    gotoPath('/tests')
                  }}
                >
                  <img src="images/test_icon.png" alt="" />
                  <span>{t('Tests')}</span>
                </div>

                <div
                  className={`menu-item item3 ${
                    checkActive(3) ? 'menuActive' : ''
                  }`}
                  onClick={() => {
                    gotoPath('/questionnaire')
                  }}
                >
                  <img src="images/question_icon.png" alt="" />
                  <span className={isUsingJp ? '' : 'text-xxs'}>
                    {t('アンケート')}
                  </span>
                </div>

                <div
                  className={`menu-item item4 ${
                    checkActive(4) ? 'menuActive' : ''
                  }`}
                  onClick={() => {
                    gotoPath('/view')
                  }}
                >
                  <img src="images/view_icon.png" alt="" />
                  <span>{t('みる')}</span>
                </div>
                <div
                  className={`menu-item item5 ${
                    checkActive(5) ? 'menuActive' : ''
                  }`}
                  onClick={() => {
                    gotoPath('/my_page')
                  }}
                >
                  <img src="images/user_icon.png" alt="" />
                  <span>{t('マイページ')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TODO: remove the commented code below, because it is not used */}
      {/* And it make a bug when play video */}
      {/* <div className="hidden print:block">{meLoading ? '' : children}</div> */}

      <Modal
        visible={showWarnModal}
        onCancel={() => setShowWarnModal(false)}
        width={900}
        closeIcon={
          <CloseOutlined className="text-base" style={{ color: '#036EB8' }} />
        }
        footer={null}
        centered={true}
        bodyStyle={{ border: '3px solid #036EB8', borderRadius: 4 }}
      >
        <div
          className="p-10 flex flex-col justify-center items-center text-base font-black"
          style={{ color: '#B80303' }}
        >
          <div className="flex">
            {WarnIcon}
            {isUsingJpRuby ? (
              <span>
                <ruby>
                  本当<Rt className="text-tiny">ほんとう</Rt>
                </ruby>
                にこのページから
                <ruby>
                  移動<Rt className="text-tiny">いどう</Rt>
                </ruby>
                しますか？
              </span>
            ) : (
              <span>{t('本当にこのページから移動しますか？')}</span>
            )}
          </div>
          {isUsingJpRuby ? (
            <span>
              登録していないデータは
              <ruby>
                削除<Rt className="text-tiny">さくじょ</Rt>
              </ruby>
              されます。
            </span>
          ) : (
            <span>{t('登録していないデータは削除されます。')}</span>
          )}
          <Row className="mt-7">
            <Button
              className="w-60 mx-2 bg-gray-150"
              size="large"
              onClick={() => setShowWarnModal(false)}
            >
              {isUsingJpRuby ? (
                <>
                  <ruby>
                    戻<Rt className="text-tiny font-black">もど</Rt>
                  </ruby>
                  る
                </>
              ) : (
                <ruby>{t('戻る')}</ruby>
              )}
            </Button>
            <Button
              className="w-60 mx-2 bg-gray-150"
              size="large"
              onClick={() => {
                setWarnBeforeLeave(false)
                gotoPath(goingPath)
              }}
            >
              {isUsingJpRuby ? (
                <>
                  はい、
                  <ruby>
                    移動
                    <Rt className="text-tiny font-black">いどう</Rt>
                  </ruby>
                  します。
                </>
              ) : (
                <ruby>{t('はい、移動します。')}</ruby>
              )}
            </Button>
          </Row>
        </div>
      </Modal>
    </>
  )
}
