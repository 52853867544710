import { TUserGender } from './constants'

export function convertJapNumbers(input: string): string {
  const chars = {
    '０': '0',
    '１': '1',
    '２': '2',
    '３': '3',
    '４': '4',
    '５': '5',
    '６': '6',
    '７': '7',
    '８': '8',
    '９': '9',
  }
  return input.replace(/[０１２３４５６７８９]/g, (m) => chars[m])
}

export const getValidPositiveNumberFromExcelFile = (
  value: number | string | undefined | null,
): false | number => {
  if (!value) {
    return false
  }

  const numVal =
    typeof value === 'number'
      ? value
      : Number.parseInt(convertJapNumbers(value))

  if (Number.isNaN(numVal) || numVal <= 0) {
    return false
  }

  return numVal
}

export const getValidGenderFromJpText = (
  inputVal: number | string | undefined | null,
): false | TUserGender => {
  if (inputVal === '男' || inputVal?.toString().toLowerCase() === 'male') {
    return 'MALE'
  }

  if (inputVal === '女' || inputVal?.toString().toLowerCase() === 'female') {
    return 'FEMALE'
  }

  return false
}

/**
 * Parse display value to validate number.
 *
 * @param displayValue
 * @returns
 */
export const parseDisplayValueToValidateNumber = (
  displayValue: string | undefined,
) => {
  return convertJapNumbers(displayValue as string)
}
