import { PublicClientApplication } from '@azure/msal-browser'

/**
 * webApp version check time
 */
export const WEB_APP_VERSION_CHECK_TIME_KEY = 'last_version_check_time'

/**
 * 6 * 60 * 60 * 1000 = 6 hours in milliseconds
 * For example, 00:00 AM to 06:00 AM of the next day, check once.
 */
export const VERSION_CHECKING_INTERVAL = 21600000

export const LOCAL_STORAGE_WEB_APP_VER_KEY = 'ver'

/**
 * The client ID for the Google OAuth 2.0 authentication.
 *
 * @see https://developers.google.com/identity/sign-in/web/reference
 *
 * @see https://console.cloud.google.com/apis/credentials
 *
 */
export const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

if (!REACT_APP_GOOGLE_CLIENT_ID) {
  console.error(
    'WARNING: The environment variable REACT_APP_GOOGLE_CLIENT_ID is invalid! REACT_APP_GOOGLE_CLIENT_ID:',
    REACT_APP_GOOGLE_CLIENT_ID,
  )
}

const REACT_APP_MS_CLIENT_ID = process.env.REACT_APP_MS_CLIENT_ID

if (!REACT_APP_MS_CLIENT_ID) {
  console.error(
    'WARNING: The environment variable REACT_APP_MS_CLIENT_ID is invalid! REACT_APP_MS_CLIENT_ID:',
    REACT_APP_MS_CLIENT_ID,
  )
}

const REACT_APP_MS_TENANT_ID = process.env.REACT_APP_MS_TENANT_ID

if (!REACT_APP_MS_TENANT_ID) {
  console.error(
    'WARNING: The environment variable REACT_APP_MS_TENANT_ID is invalid! REACT_APP_MS_TENANT_ID:',
    REACT_APP_MS_TENANT_ID,
  )
}

export const REACT_APP_MS_REDIRECT_URI = process.env.REACT_APP_MS_REDIRECT_URI

if (!REACT_APP_MS_REDIRECT_URI) {
  console.error(
    'WARNING: The environment variable REACT_APP_MS_REDIRECT_URI is invalid! REACT_APP_MS_REDIRECT_URI:',
    REACT_APP_MS_REDIRECT_URI,
  )
}

/**
 * The client ID for the Microsoft OAuth 2.0 authentication.
 *
 * @see https://docs.microsoft.com/en-us/azure/azure-resource-manager/management/authenticate-multi-tenant
 */
export const msAlConfig = {
  auth: {
    // Application ID from Azure AD
    clientId: REACT_APP_MS_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    /**
     * Redirect URL after successful login
     *
     * @example "https://alpha-student.alpha-pestalozzi.com"
     */
    redirectUri: REACT_APP_MS_REDIRECT_URI,
  },
}

export const msAlInstance = new PublicClientApplication(msAlConfig)
