import { Card } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'

export type ScoreData = {
  subject: string
  personal: number | undefined | null
  prevPersonal: number | undefined | null
}

export type ContainerSize = {
  height: string | number
  width: string | number
}

type Props = {
  title: string
  data: ScoreData[]
  forPrinting: boolean
  border?: boolean
  padding?: number
  outerRadius?: number
  size?: ContainerSize
}

const ScoreRadarChart = ({
  title,
  data,
  forPrinting,
  border,
  padding,
  outerRadius,
  size = {
    height: 420,
    width: '100%',
  },
}: Props) => {
  const { t } = useTranslation()

  const radarChartProps = {
    width: forPrinting ? 480 : 0,
    height: forPrinting ? 380 : 0,
    data: data.map((d) => ({
      ...d,
      personal: d.personal || 0,
    })),
  }

  const radarChart = (
    <RadarChart {...radarChartProps} outerRadius={outerRadius}>
      <PolarGrid />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis angle={75} tickCount={10} domain={[0, 10]} />
      {/* <Radar
        name="県平均"
        dataKey="prefecture"
        stroke="#0000FF"
        fill="#0000FF"
        fillOpacity={0}
        isAnimationActive={!forPrinting}
      /> */}
      <Radar
        name={t('昨年の個人結果')}
        dataKey="prevPersonal"
        stroke="#0000FF"
        fill="#0000FF"
        fillOpacity={0.2}
        isAnimationActive={!forPrinting}
      />
      <Radar
        name={t('個人結果')}
        dataKey="personal"
        stroke="#FF0000"
        fill="#FF0000"
        fillOpacity={0.2}
        isAnimationActive={!forPrinting}
      />

      <Legend />
      <Tooltip />
    </RadarChart>
  )
  const withinContainer = (
    <ResponsiveContainer height={size.height} width={size.width}>
      {radarChart}
    </ResponsiveContainer>
  )

  return (
    <Card
      title={title}
      style={{ textAlign: 'center' }}
      bodyStyle={{
        padding: padding ?? 0,
        fontSize: forPrinting ? '14px' : '13px',
      }}
      bordered={border}
    >
      {forPrinting ? (
        <div className="flex justify-center">{radarChart}</div>
      ) : (
        withinContainer
      )}
    </Card>
  )
}

export default ScoreRadarChart
