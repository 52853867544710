import './LoginUserPage.css'
import { FC, useContext, useMemo, useState } from 'react'
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import axios from 'axios';
import { AuthContext } from '~/alpha/core';
import { OnboardingLayout } from '~/alpha/components';
import CompletionLayout from '../layout/CompletionLayout';
import { t } from 'i18next';

interface UserAuthInfo {
  username: string;
  password: string;
}

const LoginUserPage: FC = () => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, dirtyFields },
  } = useForm<UserAuthInfo>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  // const { onLogin } = useLogin();
  // const token = useToken();
  // const { onGetUserId } = useToGetUserId();
  const auth = useContext(AuthContext)
  // const isLevelB = auth.schoolLevel === 'B' || true
  // const isUsingJp = isLevelB && isJapanese(i18n)
  const [passwordType, setPasswordType] = useState<string>('password');
  const [registerComplete, setRegisterComplete] = useState(false)

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formData) => {
        try {
          // async submit
          const parseData = {
            ...formData,
            username: formData.username.toString().trim(),
          };
          const {data} = await axios.post(`${process.env.REACT_APP_REST_API_URL}/alpha/v1/student/login`, parseData)
          if (!data.success && data.access_token) {
            auth.setAccessToken(data.access_token)
            setRegisterComplete(true)
            setTimeout(() => {
              window.open('/home', '_self')
            }, 2000)
          } else {
            toast.error('エラーが発生しました。ログイン情報が正しくありません。')
            return
          }
        } catch (e: any) {
          toast.error(
            'エラーが発生しました。ログイン情報が正しくありません。',
          );
        }
      }),
    [handleSubmit],
  );

  const showPassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };
  return (
    <OnboardingLayout>
      <div
        className="containerLogin"
        style={{
          background: 'url(/welcome-2.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center'
        }}
      >
      {!registerComplete ? (
      <div className="login">
        <span className="loginHeader">サインイン</span>
        <form onSubmit={onSubmit}>
          <div className="formItem">
            <p className="loginLabel">ID</p>
            <input {...register('username')} />
          </div>
          <div className="formItem">
            <p className="loginLabel">パスワード</p>
            <div className="flex relative items-center">
              <input type={passwordType} {...register('password')} />
              <img
                alt=""
                onClick={showPassword}
                src='/show-password.png'
                className="cursor-pointer absolute right-0 mr-2"
              />
            </div>
          </div>
          <div className='loginButton'>
            <Button
              type="primary"
              className="w-30"
              onClick={onSubmit}
              disabled={
                isSubmitting || !dirtyFields.password || !dirtyFields.username
              }
            >
              サインイン
            </Button>
          </div>
        </form>
      </div>) : (
        <div className="flex flex-col relative">
          {/* <div className="w-full text-center absolute mt-11">
            <ruby className="text-2xl font-black">
              {t('本人確認完了')}
              {isUsingJp ? (
                <rt className="text-10px">ほんにんかくにんかんりょう</rt>
              ) : null}
            </ruby>
          </div> */}
          <div className="flex-1">
            <CompletionLayout message={t('ようこそ！')} />
          </div>
          <div className="" />
        </div>
      )}
      </div>
    </OnboardingLayout>
  );
};

export default LoginUserPage
