import {
  AuthProvider,
  Client,
  getApolloClient,
  login,
  offlineLink,
} from '@alpha/core'
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import AdvisePage from './components/pages/AdvisePage'
import HomePage from './components/pages/HomePage'
import LogoutPage from './components/pages/LogoutPage'
import MoviePage from './components/pages/MoviePage'
import MyPage from './components/pages/MyPage'
import PastResultPage from './components/pages/PastResultPage'
import QuestionnairePage from './components/pages/QuestionnairePage'
import QuestionnaireResultPage from './components/pages/QuestionnaireResultPage'
// import RegisterUserPage from './components/pages/RegisterUserPage'
import SignInPage from './components/pages/SignInPage'
import TestInputPage from './components/pages/TestInputPage'
import TestPage from './components/pages/TestPage'
import TestResultPage from './components/pages/TestResultPage'
import TrainingPage from './components/pages/TrainingPage'
import VideosPage from './components/pages/VideosPage'
import ViewPage from './components/pages/ViewPage'
import { PrivateRoute } from './components/routes/PrivateRoute'
import { PublicRoute } from './components/routes/PublicRoute'
import { useIsOnlineStore } from './context/online'
import { APP_VERSION } from './utils/constants'
import LoginUserPage from './components/pages/LoginUserPage'

// to determine whether new versions is available
console.info(APP_VERSION)

function App() {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()

  const { setIsOnline } = useIsOnlineStore()
  const { t } = useTranslation()

  useEffect(() => {
    login()

    const handleOnOnline = () => {
      setIsOnline(true)

      offlineLink
        .sync()
        .then(() => {
          message.success(t('オンラインになりました。変更を送信しました。'))
        })
        .catch((err) => {
          message.error(t('キューの送信に失敗しました。'))
          console.error('offlineLink.sync error:', err)
        })
    }

    window.addEventListener('online', handleOnOnline)

    const handleOnOffline = () => {
      setIsOnline(false)
      message.warning(t('オフラインになりました。'))
    }

    window.addEventListener('offline', handleOnOffline)

    return () => {
      window.removeEventListener('online', handleOnOnline)
      window.removeEventListener('offline', handleOnOffline)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async () => {
      const ac = await getApolloClient(Client.StudentClient)
      setClient(ac)
    })()
  }, [])

  if (!client) return null

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <Switch>
            <PrivateRoute path="/home" component={HomePage} notRedirect />
            <PrivateRoute path="/advise" component={AdvisePage} notRedirect />
            <PrivateRoute path="/my_page" component={MyPage} />
            <PrivateRoute path="/questionnaire" component={QuestionnairePage} />
            <PrivateRoute
              path="/questionnaire_result"
              component={QuestionnaireResultPage}
            />
            <PrivateRoute path="/training" component={TrainingPage} />
            <PrivateRoute path="/test" component={TestInputPage} />
            <PrivateRoute path="/result" component={TestResultPage} />
            <PrivateRoute path="/logout" component={LogoutPage} />
            <PrivateRoute path="/movie" component={MoviePage} />
            <PrivateRoute path="/videos" component={VideosPage} />
            <PrivateRoute path="/view" component={ViewPage} />
            <PrivateRoute path="/tests" component={TestPage} />
            <PrivateRoute path="/past_result" component={PastResultPage} />

            {/* <PublicRoute path="/register" component={RegisterUserPage} /> */}
            <PublicRoute path="/login" component={LoginUserPage} />
            <PublicRoute path="/" component={SignInPage} />
          </Switch>
        </Router>
      </AuthProvider>
    </ApolloProvider>
  )
}

export default App
