import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** DateTime value */
  DateTime: any
  /** JSON value */
  JSON: any
}

/** # Area */
export type Area = {
  __typename?: 'Area'
  _id: Scalars['ID']
  name: Scalars['String']
  name_en: Scalars['String']
  name_hiragana: Scalars['String']
}

/** # AreasInput */
export type AreasInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  names?: InputMaybe<Array<Scalars['String']>>
}

export type AreasResponse = {
  __typename?: 'AreasResponse'
  count: Scalars['Int']
  data: Array<Area>
  error?: Maybe<Scalars['String']>
}

export type AuthResponse = {
  __typename?: 'AuthResponse'
  error?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

/** # BendingInput */
export type BendingInput = {
  bending1?: InputMaybe<Scalars['Float']>
  bending2?: InputMaybe<Scalars['Float']>
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
}

export type BendingResult = {
  __typename?: 'BendingResult'
  bending1?: Maybe<Scalars['Float']>
  bending2?: Maybe<Scalars['Float']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['Float']>
}

/** # CitiesInput */
export type CitiesInput = {
  area?: InputMaybe<Scalars['ID']>
  codes?: InputMaybe<Array<Scalars['Int']>>
  from?: InputMaybe<Scalars['Int']>
  ids?: InputMaybe<Array<Scalars['ID']>>
  limit?: InputMaybe<Scalars['Int']>
  names?: InputMaybe<Array<Scalars['String']>>
  prefecture?: InputMaybe<Scalars['ID']>
}

export type CitiesResponse = {
  __typename?: 'CitiesResponse'
  count: Scalars['Int']
  data: Array<City>
  error?: Maybe<Scalars['String']>
}

/** # City */
export type City = {
  __typename?: 'City'
  _id: Scalars['ID']
  area: Scalars['ID']
  code: Scalars['Int']
  name: Scalars['String']
  name_hiragana: Scalars['String']
  prefecture: Scalars['ID']
}

export enum Client {
  AdminClient = 'ADMIN_CLIENT',
  IbarakiCity = 'IBARAKI_CITY',
  SchoolClient = 'SCHOOL_CLIENT',
  StudentClient = 'STUDENT_CLIENT',
}

export enum ClubActivity {
  Art = 'ART',
  Badminton = 'BADMINTON',
  Baseball = 'BASEBALL',
  Basketball = 'BASKETBALL',
  Biology = 'BIOLOGY',
  BrassBand = 'BRASS_BAND',
  Chemistry = 'CHEMISTRY',
  Creative = 'CREATIVE',
  Cycling = 'CYCLING',
  Dance = 'DANCE',
  EarthScience = 'EARTH_SCIENCE',
  EnglishSpeakingSociety = 'ENGLISH_SPEAKING_SOCIETY',
  Fencing = 'FENCING',
  Fishing = 'FISHING',
  History = 'HISTORY',
  IceHockey = 'ICE_HOCKEY',
  Igo = 'IGO',
  Judo = 'JUDO',
  Kendo = 'KENDO',
  Kyudo = 'KYUDO',
  Literature = 'LITERATURE',
  Magic = 'MAGIC',
  Mathematics = 'MATHEMATICS',
  ModelBuilding = 'MODEL_BUILDING',
  MountainClimbing = 'MOUNTAIN_CLIMBING',
  Music = 'MUSIC',
  MusicBand = 'MUSIC_BAND',
  NotSet = 'NOT_SET',
  Origami = 'ORIGAMI',
  Other = 'OTHER',
  Physics = 'PHYSICS',
  Programming = 'PROGRAMMING',
  Quiz = 'QUIZ',
  Railroad = 'RAILROAD',
  Science = 'SCIENCE',
  Shogi = 'SHOGI',
  Ski = 'SKI',
  Soccer = 'SOCCER',
  SoftballBaseball = 'SOFTBALL_BASEBALL',
  SoftTennis = 'SOFT_TENNIS',
  Swimming = 'SWIMMING',
  TableTennis = 'TABLE_TENNIS',
  Tennis = 'TENNIS',
  TrackAndField = 'TRACK_AND_FIELD',
  Volleyball = 'VOLLEYBALL',
}

export type CodeGenerateSchool = {
  __typename?: 'CodeGenerateSchool'
  code: Scalars['String']
  testYear: Scalars['Int']
}

/** # CreateSchoolInput */
export type CreateSchoolInput = {
  attributes: SchoolAttributesInput
  japanSchoolId: Scalars['ID']
}

/** # EnduranceRunInput */
export type EnduranceRunInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  runningTime?: InputMaybe<Scalars['String']>
  runningTimeSeconds?: InputMaybe<Scalars['Float']>
}

export type EnduranceRunResult = {
  __typename?: 'EnduranceRunResult'
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  runningTime?: Maybe<Scalars['String']>
  runningTimeSeconds?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

export enum Era {
  Heisei = 'HEISEI',
  Reiwa = 'REIWA',
  Showa = 'SHOWA',
}

export type GripResult = {
  __typename?: 'GripResult'
  gripLeft1?: Maybe<Scalars['Float']>
  gripLeft2?: Maybe<Scalars['Float']>
  gripRight1?: Maybe<Scalars['Float']>
  gripRight2?: Maybe<Scalars['Float']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['Float']>
}

/** # GripTestInput */
export type GripTestInput = {
  gripLeft1?: InputMaybe<Scalars['Float']>
  gripLeft2?: InputMaybe<Scalars['Float']>
  gripRight1?: InputMaybe<Scalars['Float']>
  gripRight2?: InputMaybe<Scalars['Float']>
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
}

/** # HandballThrowInput */
export type HandballThrowInput = {
  handballThrow1?: InputMaybe<Scalars['Float']>
  handballThrow2?: InputMaybe<Scalars['Float']>
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
}

export type HandballThrowResult = {
  __typename?: 'HandballThrowResult'
  handballThrow1?: Maybe<Scalars['Float']>
  handballThrow2?: Maybe<Scalars['Float']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['Float']>
}

/** # Hint */
export type Hint = {
  __typename?: 'Hint'
  score: Scalars['Float']
  term: Scalars['String']
}

/** # JapanSchool */
export type JapanSchool = {
  __typename?: 'JapanSchool'
  _id: Scalars['ID']
  prefectureCode: Scalars['Int']
  registered?: Maybe<Scalars['Boolean']>
  school?: Maybe<School>
  schoolAddress?: Maybe<Scalars['String']>
  schoolCategoryCode: Scalars['String']
  schoolCode: Scalars['String']
  schoolName: Scalars['String']
  schoolStatusCode?: Maybe<Scalars['Int']>
  schoolTypeCode: Scalars['Int']
}

export type JapanSchoolCategoriesResponse = {
  __typename?: 'JapanSchoolCategoriesResponse'
  count: Scalars['Int']
  data?: Maybe<Array<JapanSchoolCategory>>
  error?: Maybe<Scalars['String']>
}

/** # JapanSchoolCategory */
export type JapanSchoolCategory = {
  __typename?: 'JapanSchoolCategory'
  code: Scalars['String']
  name: Scalars['String']
}

/** # JapanSchoolType */
export type JapanSchoolType = {
  __typename?: 'JapanSchoolType'
  code: Scalars['Int']
  name: Scalars['String']
}

export type JapanSchoolTypesResponse = {
  __typename?: 'JapanSchoolTypesResponse'
  count: Scalars['Int']
  data?: Maybe<Array<JapanSchoolType>>
  error?: Maybe<Scalars['String']>
}

/** # JapanSchoolsInput */
export type JapanSchoolsInput = {
  cityName?: InputMaybe<Scalars['String']>
  districtName?: InputMaybe<Scalars['String']>
  from?: InputMaybe<Scalars['Int']>
  ids?: InputMaybe<Array<Scalars['ID']>>
  limit?: InputMaybe<Scalars['Int']>
  prefectureCode?: InputMaybe<Scalars['Int']>
  registered?: InputMaybe<Scalars['Boolean']>
  schoolCategoryCode?: InputMaybe<Scalars['String']>
  schoolCodes?: InputMaybe<Array<Scalars['String']>>
  schoolNames?: InputMaybe<Array<Scalars['String']>>
  schoolTypeCode?: InputMaybe<Scalars['Int']>
}

export type JapanSchoolsResponse = {
  __typename?: 'JapanSchoolsResponse'
  count: Scalars['Int']
  data: Array<JapanSchool>
  error?: Maybe<Scalars['String']>
  totalCount: Scalars['Int']
}

export type ListAveragePoints = {
  __typename?: 'ListAveragePoints'
  key: Scalars['String']
  title: Scalars['String']
  value?: Maybe<Scalars['Float']>
}

export type MeResponse = {
  __typename?: 'MeResponse'
  data?: Maybe<User>
  error?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

/** MetaStudent */
export type MetaStudent = {
  __typename?: 'MetaStudent'
  attendanceNumber: Scalars['Int']
  familyName?: Maybe<Scalars['String']>
  givenName?: Maybe<Scalars['String']>
  name: Scalars['String']
  studentInvitationCode?: Maybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** # Analytics */
  analyticsEvent: Scalars['Boolean']
  analyticsEvents: Scalars['Boolean']
  /**
   * # General
   *  Using at Ibaraki city
   */
  auth?: Maybe<AuthResponse>
  /**
   * # Admin
   *  done
   */
  createSchool?: Maybe<OperationResponse>
  /** done */
  linkSchoolTestYear?: Maybe<OperationResponse>
  /**
   * # SchoolAdmin
   *  done
   */
  registerGrade?: Maybe<OperationResponse>
  /** Using at Ibaraki city */
  registerUser?: Maybe<MeResponse>
  schoolAddStudent?: Maybe<OperationResponse>
  schoolEditStudent?: Maybe<OperationResponse>
  /**
   * # Student
   *  done
   */
  studentLinkSchool?: Maybe<StudentLinkSchoolResponse>
  /**
   * # SchoolAdmin, Teacher
   *  done
   */
  teacherLinkSchool?: Maybe<TeacherLinkSchoolResponse>
  /** done */
  updateSchool?: Maybe<OperationResponse>
  /** # SchoolAdmin */
  updateTeacherRole?: Maybe<OperationResponse>
  /**
   * # Me
   *  Using at Ibaraki city
   */
  updateUser?: Maybe<MeResponse>
}

export type MutationAnalyticsEventArgs = {
  input: Scalars['JSON']
}

export type MutationAnalyticsEventsArgs = {
  input: Array<Scalars['JSON']>
}

export type MutationAuthArgs = {
  uid: Scalars['String']
}

export type MutationCreateSchoolArgs = {
  input: CreateSchoolInput
}

export type MutationLinkSchoolTestYearArgs = {
  input: SchoolLinkTestYearInput
}

export type MutationRegisterGradeArgs = {
  input: RegisterSchoolGradeInput
}

export type MutationRegisterUserArgs = {
  input: RegisterUserInput
}

export type MutationSchoolAddStudentArgs = {
  input: SchoolAddStudentInput
}

export type MutationSchoolEditStudentArgs = {
  input: SchoolEditStudentInput
}

export type MutationStudentLinkSchoolArgs = {
  input: StudentLinkSchoolInput
}

export type MutationTeacherLinkSchoolArgs = {
  input: TeacherLinkSchoolInput
}

export type MutationUpdateSchoolArgs = {
  input: UpdateSchoolInput
}

export type MutationUpdateTeacherRoleArgs = {
  input: UpdateTeacherRoleInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

/** # OperationResponse */
export type OperationResponse = {
  __typename?: 'OperationResponse'
  error?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

/** # PageInfo */
export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor: Scalars['String']
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor: Scalars['String']
}

/** # PostalCode */
export type PostalCode = {
  __typename?: 'PostalCode'
  _id: Scalars['ID']
  cityName: Scalars['String']
  districtName: Scalars['String']
  groupCode: Scalars['Int']
  oldPostalCode: Scalars['Int']
  postalCode: Scalars['Int']
  prefectureName: Scalars['String']
}

/** # PostalCodesInput */
export type PostalCodesInput = {
  cityName?: InputMaybe<Scalars['String']>
  districtName?: InputMaybe<Scalars['String']>
  from?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  postalCode?: InputMaybe<Scalars['Int']>
  prefectureName?: InputMaybe<Scalars['String']>
}

export type PostalCodesResponse = {
  __typename?: 'PostalCodesResponse'
  count: Scalars['Int']
  data: Array<PostalCode>
  error?: Maybe<Scalars['String']>
}

/** # Prefecture */
export type Prefecture = {
  __typename?: 'Prefecture'
  _id: Scalars['ID']
  area: Scalars['JSON']
  code: Scalars['Int']
  name: Scalars['String']
  name_en: Scalars['String']
  name_hiragana: Scalars['String']
}

/** # PrefecturesInput */
export type PrefecturesInput = {
  areaId?: InputMaybe<Scalars['String']>
  codes?: InputMaybe<Array<Scalars['Int']>>
  ids?: InputMaybe<Array<Scalars['ID']>>
  names?: InputMaybe<Array<Scalars['String']>>
}

export type PrefecturesResponse = {
  __typename?: 'PrefecturesResponse'
  count: Scalars['Int']
  data: Array<Prefecture>
  error?: Maybe<Scalars['String']>
}

export type QrCodeStudentInput = {
  qrCode: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  /**
   * # General
   *  done
   */
  areas?: Maybe<AreasResponse>
  /** done */
  cities?: Maybe<CitiesResponse>
  /**
   * SchoolAdmin, Teacher
   * done
   */
  grades?: Maybe<SchoolGradesResponse>
  /** done */
  japanSchoolCategories?: Maybe<JapanSchoolCategoriesResponse>
  /** done */
  japanSchoolTypes?: Maybe<JapanSchoolTypesResponse>
  /** done */
  japanSchools?: Maybe<JapanSchoolsResponse>
  meSchools?: Maybe<SchoolsResponse>
  /** done */
  postalCodes?: Maybe<PostalCodesResponse>
  /** done */
  prefectures?: Maybe<PrefecturesResponse>
  /**
   * # kid
   *  done
   */
  qrCodeStudent?: Maybe<UserResponse>
  /** done */
  reportAverageScoreGraphSummary?: Maybe<ReportAverageScoreGraphSummaryResponse>
  /** done */
  reportBodySummary?: Maybe<ReportBodySummaryResponse>
  /**
   * # Reports
   *  done
   */
  reportCountSummary?: Maybe<ReportCountSummaryResponse>
  /** done */
  reportCrossAnalysisSummary?: Maybe<ReportCrossAnalysisSummaryResponse>
  /** done */
  reportQuestionnaireSummary?: Maybe<ReportQuestionnaireSummaryResponse>
  /** done */
  reportSchoolPerformanceSummary?: Maybe<ReportSchoolPerformanceSummaryResponse>
  /** done */
  reportSchoolProgressSummary?: Maybe<ReportSchoolProgressSummaryResponse>
  /** done */
  reportTestComparisonSummary?: Maybe<ReportTestComparisonSummaryResponse>
  /** done */
  reportTestRankSummary?: Maybe<ReportTestRankSummaryResponse>
  /** done */
  reportTestRankTargetSummary?: Maybe<ReportTestRankTargetSummaryResponse>
  /** done */
  reportTestScoreChartSummary?: Maybe<ReportTestScoreChartSummaryResponse>
  /** done */
  reportTestSummary?: Maybe<ReportTestSummaryResponse>
  /** done */
  reportUploadList?: Maybe<ReportUploadListResponse>
  /** done */
  schoolReportTestScoreChartSummary?: Maybe<SchoolReportTestScoreChartSummaryResponse>
  /** done */
  schoolReportTestSummary?: Maybe<SchoolReportTestSummaryResponse>
  /** Admin */
  schools?: Maybe<SchoolsResponse>
  /** done */
  searchPrefecturesHints?: Maybe<SearchHintsResponse>
  searchStudent?: Maybe<UserResponse>
  searchTeacher?: Maybe<UserResponse>
  sport?: Maybe<SportsResponse>
  sports?: Maybe<SportsResponse>
  /** done */
  studentInvitationCodes?: Maybe<StudentInvitationCodesResponse>
  /** done */
  students?: Maybe<UsersResponse>
  /** done */
  teachers?: Maybe<UsersResponse>
}

export type QueryAreasArgs = {
  input?: InputMaybe<AreasInput>
}

export type QueryCitiesArgs = {
  input: CitiesInput
}

export type QueryGradesArgs = {
  input: SchoolGradesInput
}

export type QueryJapanSchoolsArgs = {
  input: JapanSchoolsInput
}

export type QueryPostalCodesArgs = {
  input: PostalCodesInput
}

export type QueryPrefecturesArgs = {
  input?: InputMaybe<PrefecturesInput>
}

export type QueryQrCodeStudentArgs = {
  input: QrCodeStudentInput
}

export type QueryReportAverageScoreGraphSummaryArgs = {
  input: ReportAverageScoreGraphSummaryInput
}

export type QueryReportBodySummaryArgs = {
  input: ReportBodySummaryInput
}

export type QueryReportCountSummaryArgs = {
  input: ReportCountSummaryInput
}

export type QueryReportCrossAnalysisSummaryArgs = {
  input: ReportCrossAnalysisSummaryInput
}

export type QueryReportQuestionnaireSummaryArgs = {
  input: ReportQuestionnaireSummaryInput
}

export type QueryReportSchoolPerformanceSummaryArgs = {
  input: ReportSchoolPerformanceSummaryInput
}

export type QueryReportSchoolProgressSummaryArgs = {
  input: ReportSchoolProgressSummaryInput
}

export type QueryReportTestComparisonSummaryArgs = {
  input: ReportTestComparisonSummaryInput
}

export type QueryReportTestRankSummaryArgs = {
  input: ReportTestRankSummaryInput
}

export type QueryReportTestRankTargetSummaryArgs = {
  input: ReportTestRankTargetSummaryInput
}

export type QueryReportTestScoreChartSummaryArgs = {
  input: ReportTestScoreChartSummaryInput
}

export type QueryReportTestSummaryArgs = {
  input: ReportTestSummaryInput
}

export type QueryReportUploadListArgs = {
  input: ReportUploadListInput
}

export type QuerySchoolReportTestScoreChartSummaryArgs = {
  input: SchoolReportTestScoreChartSummaryInput
}

export type QuerySchoolReportTestSummaryArgs = {
  input: SchoolReportTestSummaryInput
}

export type QuerySchoolsArgs = {
  input: SchoolsInput
}

export type QuerySearchPrefecturesHintsArgs = {
  input: SearchPrefecturesHintsInput
}

export type QuerySportArgs = {
  input: SportInput
}

export type QuerySportsArgs = {
  input: SportsInput
}

export type QueryStudentInvitationCodesArgs = {
  input: StudentInvitationCodesInput
}

export type QueryStudentsArgs = {
  input: StudentsInput
}

export type QueryTeachersArgs = {
  input: TeachersInput
}

export type QuestionnaireResponse = {
  __typename?: 'QuestionnaireResponse'
  k: Scalars['Int']
  v: Scalars['Int']
}

export type QuestionnaireResult = {
  __typename?: 'QuestionnaireResult'
  belongsToSportsClub?: Maybe<Scalars['Int']>
  breakfast?: Maybe<Scalars['Int']>
  dailyExcerciseTime?: Maybe<Scalars['Int']>
  excerciseActivity?: Maybe<Scalars['Int']>
  height?: Maybe<Scalars['Float']>
  sittingHeight?: Maybe<Scalars['Float']>
  sleepTime?: Maybe<Scalars['Int']>
  tvTime?: Maybe<Scalars['Int']>
  weight?: Maybe<Scalars['Float']>
}

export type QuestionnaireV2Question = {
  __typename?: 'QuestionnaireV2Question'
  options: Array<Scalars['String']>
  question: Scalars['String']
}

/** # QuestionnaireV2QuestionInput */
export type QuestionnaireV2QuestionInput = {
  options: Array<Scalars['String']>
  question: Scalars['String']
}

export type QuestionnaireV2Result = {
  __typename?: 'QuestionnaireV2Result'
  answers?: Maybe<Array<Scalars['Int']>>
  height?: Maybe<Scalars['Float']>
  sittingHeight?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

export type QuestionnaireV3Result = {
  __typename?: 'QuestionnaireV3Result'
  q1?: Maybe<Scalars['String']>
  q2?: Maybe<Scalars['String']>
  q3?: Maybe<Scalars['String']>
  q4?: Maybe<Scalars['String']>
  q5?: Maybe<Scalars['String']>
  q6?: Maybe<Scalars['String']>
  q7?: Maybe<Scalars['String']>
  q8?: Maybe<Scalars['String']>
  q9?: Maybe<Scalars['String']>
  q10?: Maybe<Scalars['String']>
  q11?: Maybe<Scalars['String']>
  q12?: Maybe<Scalars['String']>
  q13?: Maybe<Scalars['String']>
  q14?: Maybe<Scalars['String']>
  q15?: Maybe<Scalars['String']>
  q16?: Maybe<Scalars['String']>
  q17?: Maybe<Scalars['String']>
  q18?: Maybe<Scalars['String']>
  q19?: Maybe<Scalars['String']>
  q20?: Maybe<Scalars['String']>
  q21?: Maybe<Scalars['String']>
  q22?: Maybe<Scalars['String']>
  q23?: Maybe<Scalars['String']>
  q24?: Maybe<Scalars['String']>
  q25?: Maybe<Scalars['String']>
  q26?: Maybe<Scalars['String']>
  q27?: Maybe<Scalars['String']>
  q28?: Maybe<Scalars['String']>
  q29?: Maybe<Scalars['String']>
  q30?: Maybe<Scalars['String']>
  q31?: Maybe<Scalars['String']>
  q32?: Maybe<Scalars['String']>
  q33?: Maybe<Scalars['String']>
  q34?: Maybe<Scalars['String']>
  q35?: Maybe<Scalars['String']>
  q36?: Maybe<Scalars['String']>
  q37?: Maybe<Scalars['String']>
  q38?: Maybe<Scalars['String']>
  q39?: Maybe<Scalars['String']>
  q40?: Maybe<Scalars['String']>
  q41?: Maybe<Scalars['String']>
  q42?: Maybe<Scalars['String']>
  q43?: Maybe<Scalars['String']>
  q44?: Maybe<Scalars['String']>
  q45?: Maybe<Scalars['String']>
  q46?: Maybe<Scalars['String']>
  q47?: Maybe<Scalars['String']>
  q48?: Maybe<Scalars['String']>
  q49?: Maybe<Scalars['String']>
  q50?: Maybe<Scalars['String']>
  q51?: Maybe<Scalars['String']>
}

/** # RegisterSchoolGradeInput */
export type RegisterSchoolGradeInput = {
  numberOfStudentsPerClass: Array<Scalars['Int']>
  schoolGrade: Scalars['Int']
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # RegisterUserInput */
export type RegisterUserInput = {
  attributes: UserAttributesInput
  uid: Scalars['String']
}

export type ReportAverageScoreGraph = {
  __typename?: 'ReportAverageScoreGraph'
  bending?: Maybe<Scalars['Float']>
  grip?: Maybe<Scalars['Float']>
  handballThrow?: Maybe<Scalars['Float']>
  shuttleRun?: Maybe<Scalars['Float']>
  sideJump?: Maybe<Scalars['Float']>
  sitUps?: Maybe<Scalars['Float']>
  sprintRun?: Maybe<Scalars['Float']>
  standingJump?: Maybe<Scalars['Float']>
  totalScore?: Maybe<Scalars['Float']>
}

export type ReportAverageScoreGraphByCategory = {
  __typename?: 'ReportAverageScoreGraphByCategory'
  reports?: Maybe<Array<ReportAverageScoreGraphByYear>>
}

export type ReportAverageScoreGraphByGrade = {
  __typename?: 'ReportAverageScoreGraphByGrade'
  grade?: Maybe<Scalars['Int']>
  score?: Maybe<ReportAverageScoreGraph>
}

export type ReportAverageScoreGraphByYear = {
  __typename?: 'ReportAverageScoreGraphByYear'
  grades?: Maybe<Array<ReportAverageScoreGraphByGrade>>
  year?: Maybe<Scalars['Int']>
}

export type ReportAverageScoreGraphSummary = {
  __typename?: 'ReportAverageScoreGraphSummary'
  elementarySchoolReport?: Maybe<ReportAverageScoreGraphByCategory>
  highSchoolReport?: Maybe<ReportAverageScoreGraphByCategory>
  juniorHighSchoolReport?: Maybe<ReportAverageScoreGraphByCategory>
}

export type ReportAverageScoreGraphSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
}

/** # ReportAverageScoreGraphSummary */
export type ReportAverageScoreGraphSummaryResponse = {
  __typename?: 'ReportAverageScoreGraphSummaryResponse'
  data?: Maybe<ReportAverageScoreGraphSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportBody = {
  __typename?: 'ReportBody'
  grade?: Maybe<Scalars['Int']>
  heightAvg?: Maybe<Scalars['Float']>
  heightDev?: Maybe<Scalars['Float']>
  sampleCount?: Maybe<Scalars['Int']>
  weightAvg?: Maybe<Scalars['Float']>
  weightDev?: Maybe<Scalars['Float']>
}

export type ReportBodyByCategory = {
  __typename?: 'ReportBodyByCategory'
  reports?: Maybe<Array<ReportBody>>
}

export type ReportBodySummary = {
  __typename?: 'ReportBodySummary'
  allDayHighSchoolReport?: Maybe<ReportBodyByCategory>
  elementarySchoolReport?: Maybe<ReportBodyByCategory>
  fixTimeHighSchoolReport?: Maybe<ReportBodyByCategory>
  juniorHighSchoolReport?: Maybe<ReportBodyByCategory>
}

export type ReportBodySummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  cityName?: InputMaybe<Scalars['String']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportBodySummary */
export type ReportBodySummaryResponse = {
  __typename?: 'ReportBodySummaryResponse'
  data?: Maybe<ReportBodySummary>
  error?: Maybe<Scalars['String']>
}

export type ReportCount = {
  __typename?: 'ReportCount'
  samplingCount?: Maybe<Scalars['Int']>
  totalCount?: Maybe<Scalars['Int']>
}

export type ReportCountByCategory = {
  __typename?: 'ReportCountByCategory'
  reports?: Maybe<Array<ReportCountByGrade>>
  schoolCount?: Maybe<Scalars['Int']>
}

export type ReportCountByGrade = {
  __typename?: 'ReportCountByGrade'
  female?: Maybe<ReportCount>
  grade?: Maybe<Scalars['Int']>
  male?: Maybe<ReportCount>
}

export type ReportCountSummary = {
  __typename?: 'ReportCountSummary'
  allDayHighSchoolReport?: Maybe<ReportCountByCategory>
  elementarySchoolReport?: Maybe<ReportCountByCategory>
  fixTimeHighSchoolReport?: Maybe<ReportCountByCategory>
  juniorHighSchoolReport?: Maybe<ReportCountByCategory>
}

export type ReportCountSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  cityName?: InputMaybe<Scalars['String']>
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportCountSummary */
export type ReportCountSummaryResponse = {
  __typename?: 'ReportCountSummaryResponse'
  data?: Maybe<ReportCountSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportCrossAnalysisByAnswer = {
  __typename?: 'ReportCrossAnalysisByAnswer'
  answer?: Maybe<Scalars['Int']>
  averages?: Maybe<Array<ReportScore>>
  deviations?: Maybe<Array<ReportScore>>
  totalCount?: Maybe<Scalars['Int']>
}

export type ReportCrossAnalysisByGrade = {
  __typename?: 'ReportCrossAnalysisByGrade'
  answers?: Maybe<Array<ReportCrossAnalysisByAnswer>>
  grade?: Maybe<Scalars['Int']>
  schoolCategory?: Maybe<Scalars['String']>
}

export type ReportCrossAnalysisByQuestionnaire = {
  __typename?: 'ReportCrossAnalysisByQuestionnaire'
  dataKey?: Maybe<Scalars['String']>
  grades?: Maybe<Array<ReportCrossAnalysisByGrade>>
}

export type ReportCrossAnalysisSummary = {
  __typename?: 'ReportCrossAnalysisSummary'
  reports?: Maybe<Array<ReportCrossAnalysisByQuestionnaire>>
}

export type ReportCrossAnalysisSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportCrossAnalysisSummary */
export type ReportCrossAnalysisSummaryResponse = {
  __typename?: 'ReportCrossAnalysisSummaryResponse'
  data?: Maybe<ReportCrossAnalysisSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportQuestionnaire = {
  __typename?: 'ReportQuestionnaire'
  q1?: Maybe<Array<QuestionnaireResponse>>
  q2?: Maybe<Array<QuestionnaireResponse>>
  q3?: Maybe<Array<QuestionnaireResponse>>
  q4?: Maybe<Array<QuestionnaireResponse>>
  q5?: Maybe<Array<QuestionnaireResponse>>
  q6?: Maybe<Array<QuestionnaireResponse>>
  q7?: Maybe<Array<QuestionnaireResponse>>
}

export type ReportQuestionnaireByCategory = {
  __typename?: 'ReportQuestionnaireByCategory'
  reports?: Maybe<Array<ReportQuestionnaireByGrade>>
}

export type ReportQuestionnaireByGrade = {
  __typename?: 'ReportQuestionnaireByGrade'
  countByAnswer?: Maybe<ReportQuestionnaire>
  grade?: Maybe<Scalars['Int']>
}

export type ReportQuestionnaireSummary = {
  __typename?: 'ReportQuestionnaireSummary'
  elementarySchoolReport?: Maybe<ReportQuestionnaireByCategory>
  highSchoolReport?: Maybe<ReportQuestionnaireByCategory>
  juniorHighSchoolReport?: Maybe<ReportQuestionnaireByCategory>
}

export type ReportQuestionnaireSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportQuestionnaireSummary */
export type ReportQuestionnaireSummaryResponse = {
  __typename?: 'ReportQuestionnaireSummaryResponse'
  data?: Maybe<ReportQuestionnaireSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportSchoolEntry = {
  __typename?: 'ReportSchoolEntry'
  cityName?: Maybe<Scalars['String']>
  organizationName?: Maybe<Scalars['String']>
  prefectureSchoolCode?: Maybe<Scalars['String']>
  schoolName?: Maybe<Scalars['String']>
  studentCount?: Maybe<Scalars['Int']>
  uploadedAt?: Maybe<Scalars['DateTime']>
}

export type ReportSchoolPerformance = {
  __typename?: 'ReportSchoolPerformance'
  prefectureSchoolCode?: Maybe<Scalars['Int']>
  scale?: Maybe<Scalars['Int']>
  schoolName?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['Float']>
  studentCount?: Maybe<Scalars['Int']>
}

export type ReportSchoolPerformanceSummary = {
  __typename?: 'ReportSchoolPerformanceSummary'
  elementarySchool?: Maybe<Array<ReportSchoolPerformance>>
  highSchool?: Maybe<Array<ReportSchoolPerformance>>
  juniorHighSchool?: Maybe<Array<ReportSchoolPerformance>>
}

export type ReportSchoolPerformanceSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  prefectureCode: Scalars['Int']
  year: Scalars['Int']
}

/** # ReportSchoolPerformanceSummary */
export type ReportSchoolPerformanceSummaryResponse = {
  __typename?: 'ReportSchoolPerformanceSummaryResponse'
  data?: Maybe<ReportSchoolPerformanceSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportSchoolProgress = {
  __typename?: 'ReportSchoolProgress'
  prefectureSchoolCode?: Maybe<Scalars['Int']>
  scale?: Maybe<Scalars['Int']>
  schoolName?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['Float']>
  studentCount?: Maybe<Scalars['Int']>
}

export type ReportSchoolProgressSummary = {
  __typename?: 'ReportSchoolProgressSummary'
  elementarySchool?: Maybe<Array<ReportSchoolProgress>>
  highSchool?: Maybe<Array<ReportSchoolProgress>>
  juniorHighSchool?: Maybe<Array<ReportSchoolProgress>>
}

export type ReportSchoolProgressSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  prefectureCode: Scalars['Int']
  year: Scalars['Int']
}

/** # ReportSchoolProgressSummary */
export type ReportSchoolProgressSummaryResponse = {
  __typename?: 'ReportSchoolProgressSummaryResponse'
  data?: Maybe<ReportSchoolProgressSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportScore = {
  __typename?: 'ReportScore'
  score?: Maybe<Scalars['Float']>
  scoreType?: Maybe<Scalars['String']>
}

export type ReportTest = {
  __typename?: 'ReportTest'
  bending?: Maybe<Scalars['Float']>
  grip?: Maybe<Scalars['Float']>
  handballThrow?: Maybe<Scalars['Float']>
  shuttleRun?: Maybe<Scalars['Float']>
  sideJump?: Maybe<Scalars['Float']>
  sitUps?: Maybe<Scalars['Float']>
  sprintRun?: Maybe<Scalars['Float']>
  standingJump?: Maybe<Scalars['Float']>
  totalScore?: Maybe<Scalars['Float']>
}

export type ReportTestByCategory = {
  __typename?: 'ReportTestByCategory'
  reports?: Maybe<Array<ReportTestByGrade>>
}

export type ReportTestByGrade = {
  __typename?: 'ReportTestByGrade'
  average?: Maybe<ReportTest>
  deviation?: Maybe<ReportTest>
  grade?: Maybe<Scalars['Int']>
}

export type ReportTestComparison = {
  __typename?: 'ReportTestComparison'
  bending?: Maybe<Scalars['Boolean']>
  grip?: Maybe<Scalars['Boolean']>
  handballThrow?: Maybe<Scalars['Boolean']>
  shuttleRun?: Maybe<Scalars['Boolean']>
  sideJump?: Maybe<Scalars['Boolean']>
  sitUps?: Maybe<Scalars['Boolean']>
  sprintRun?: Maybe<Scalars['Boolean']>
  standingJump?: Maybe<Scalars['Boolean']>
  totalScore?: Maybe<Scalars['Boolean']>
}

export type ReportTestComparisonByCategory = {
  __typename?: 'ReportTestComparisonByCategory'
  reports?: Maybe<Array<ReportTestComparisonByGrade>>
}

export type ReportTestComparisonByGrade = {
  __typename?: 'ReportTestComparisonByGrade'
  grade?: Maybe<Scalars['Int']>
  national?: Maybe<ReportTestComparison>
  prefecture?: Maybe<ReportTestComparison>
}

export type ReportTestComparisonSummary = {
  __typename?: 'ReportTestComparisonSummary'
  elementarySchoolReport?: Maybe<ReportTestComparisonByCategory>
  highSchoolReport?: Maybe<ReportTestComparisonByCategory>
  juniorHighSchoolReport?: Maybe<ReportTestComparisonByCategory>
}

export type ReportTestComparisonSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportTestComparisonSummary */
export type ReportTestComparisonSummaryResponse = {
  __typename?: 'ReportTestComparisonSummaryResponse'
  data?: Maybe<ReportTestComparisonSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportTestRank = {
  __typename?: 'ReportTestRank'
  rankA?: Maybe<Scalars['Float']>
  rankB?: Maybe<Scalars['Float']>
  rankC?: Maybe<Scalars['Float']>
  rankD?: Maybe<Scalars['Float']>
  rankE?: Maybe<Scalars['Float']>
}

export type ReportTestRankByCategory = {
  __typename?: 'ReportTestRankByCategory'
  reports?: Maybe<Array<ReportTestRankByGrade>>
}

export type ReportTestRankByGrade = {
  __typename?: 'ReportTestRankByGrade'
  countByRank?: Maybe<ReportTestRank>
  grade?: Maybe<Scalars['Int']>
  rateByRank?: Maybe<ReportTestRank>
  totalCount?: Maybe<Scalars['Int']>
}

export type ReportTestRankSummary = {
  __typename?: 'ReportTestRankSummary'
  allDayHighSchoolReport?: Maybe<ReportTestRankByCategory>
  elementarySchoolReport?: Maybe<ReportTestRankByCategory>
  fixTimeHighSchoolReport?: Maybe<ReportTestRankByCategory>
  juniorHighSchoolReport?: Maybe<ReportTestRankByCategory>
}

export type ReportTestRankSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportTestRankSummary */
export type ReportTestRankSummaryResponse = {
  __typename?: 'ReportTestRankSummaryResponse'
  data?: Maybe<ReportTestRankSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportTestRankTargetByYear = {
  __typename?: 'ReportTestRankTargetByYear'
  elementarySchoolRates?: Maybe<Array<Scalars['Float']>>
  highSchoolRates?: Maybe<Array<Scalars['Float']>>
  juniorHighSchoolRates?: Maybe<Array<Scalars['Float']>>
  totalRates?: Maybe<Array<Scalars['Float']>>
  year?: Maybe<Scalars['Int']>
}

export type ReportTestRankTargetSummary = {
  __typename?: 'ReportTestRankTargetSummary'
  national?: Maybe<Array<ReportTestRankTargetByYear>>
  reports?: Maybe<Array<ReportTestRankTargetByYear>>
}

export type ReportTestRankTargetSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  prefectureCode: Scalars['Int']
}

/** # ReportTestRankTargetSummary */
export type ReportTestRankTargetSummaryResponse = {
  __typename?: 'ReportTestRankTargetSummaryResponse'
  data?: Maybe<ReportTestRankTargetSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportTestScoreChart = {
  __typename?: 'ReportTestScoreChart'
  bending?: Maybe<Scalars['Float']>
  grip?: Maybe<Scalars['Float']>
  handballThrow?: Maybe<Scalars['Float']>
  height?: Maybe<Scalars['Float']>
  shuttleRun?: Maybe<Scalars['Float']>
  sideJump?: Maybe<Scalars['Float']>
  sitUps?: Maybe<Scalars['Float']>
  sprintRun?: Maybe<Scalars['Float']>
  standingJump?: Maybe<Scalars['Float']>
  totalScore?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

export type ReportTestScoreChartByCategory = {
  __typename?: 'ReportTestScoreChartByCategory'
  reports?: Maybe<Array<ReportTestScoreChartByGrade>>
}

export type ReportTestScoreChartByGrade = {
  __typename?: 'ReportTestScoreChartByGrade'
  grade?: Maybe<Scalars['Int']>
  prefecture?: Maybe<ReportTestScoreChart>
}

export type ReportTestScoreChartSummary = {
  __typename?: 'ReportTestScoreChartSummary'
  elementarySchoolReport?: Maybe<ReportTestScoreChartByCategory>
  highSchoolReport?: Maybe<ReportTestScoreChartByCategory>
  juniorHighSchoolReport?: Maybe<ReportTestScoreChartByCategory>
}

export type ReportTestScoreChartSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportTestScoreChartSummary */
export type ReportTestScoreChartSummaryResponse = {
  __typename?: 'ReportTestScoreChartSummaryResponse'
  data?: Maybe<ReportTestScoreChartSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportTestSummary = {
  __typename?: 'ReportTestSummary'
  allDayHighSchoolReport?: Maybe<ReportTestByCategory>
  elementarySchoolReport?: Maybe<ReportTestByCategory>
  fixTimeHighSchoolReport?: Maybe<ReportTestByCategory>
  juniorHighSchoolReport?: Maybe<ReportTestByCategory>
}

export type ReportTestSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  cityName?: InputMaybe<Scalars['String']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportTestSummary */
export type ReportTestSummaryResponse = {
  __typename?: 'ReportTestSummaryResponse'
  data?: Maybe<ReportTestSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportUploadListInput = {
  from?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  prefectureCode: Scalars['Int']
  year: Scalars['Int']
}

/** # ReportUploadList */
export type ReportUploadListResponse = {
  __typename?: 'ReportUploadListResponse'
  count: Scalars['Int']
  data?: Maybe<Array<ReportSchoolEntry>>
  error?: Maybe<Scalars['String']>
  totalCount: Scalars['Int']
}

/** # School */
export type School = {
  __typename?: 'School'
  _id: Scalars['ID']
  attributes: SchoolAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  relationships: SchoolRelationships
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SchoolAddStudentInput = {
  attributes: UserAttributesInput
  schoolAttendanceNumber: Scalars['Int']
  schoolClass: Scalars['Int']
  schoolGrade: Scalars['Int']
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolAttributes */
export type SchoolAttributes = {
  __typename?: 'SchoolAttributes'
  cityName?: Maybe<Scalars['String']>
  codeGenerate?: Maybe<Array<CodeGenerateSchool>>
  districtName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  gradeAdminInvitationCode?: Maybe<Scalars['String']>
  isStudentInputActive?: Maybe<Scalars['Boolean']>
  onboardComplete?: Maybe<Scalars['Boolean']>
  prefectureCode: Scalars['Int']
  questions?: Maybe<Array<QuestionnaireV2Question>>
  schoolAddress?: Maybe<Scalars['String']>
  schoolAdminEmail?: Maybe<Scalars['String']>
  schoolAdminInvitationCode?: Maybe<Scalars['String']>
  schoolAdminName?: Maybe<Scalars['String']>
  schoolCategoryCode: Scalars['String']
  schoolCode: Scalars['String']
  schoolName: Scalars['String']
  schoolTypeCode: Scalars['Int']
  teacherInvitationCode?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  testYears?: Maybe<Array<Scalars['Int']>>
  videoQualitySetting?: Maybe<Scalars['Boolean']>
  website?: Maybe<Scalars['String']>
}

/** # SchoolAttributesInput */
export type SchoolAttributesInput = {
  email?: InputMaybe<Scalars['String']>
  isStudentInputActive?: InputMaybe<Scalars['Boolean']>
  questions?: InputMaybe<Array<QuestionnaireV2QuestionInput>>
  schoolAdminEmail?: InputMaybe<Scalars['String']>
  schoolAdminName?: InputMaybe<Scalars['String']>
  telephone?: InputMaybe<Scalars['String']>
  videoQualitySetting?: InputMaybe<Scalars['Boolean']>
  website?: InputMaybe<Scalars['String']>
}

export enum SchoolCategory {
  ElementarySchool = 'ELEMENTARY_SCHOOL',
  HighSchool = 'HIGH_SCHOOL',
  JuniorHighSchool = 'JUNIOR_HIGH_SCHOOL',
  Other = 'OTHER',
}

export type SchoolClass = {
  __typename?: 'SchoolClass'
  _id: Scalars['ID']
  attributes: SchoolClassAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  relationships: SchoolClassRelationships
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SchoolClassAttributes = {
  __typename?: 'SchoolClassAttributes'
  prefecture: Scalars['String']
  schoolName: Scalars['String']
}

export type SchoolClassConnection = {
  __typename?: 'SchoolClassConnection'
  edges: Array<SchoolClassEdge>
  pageInfo: PageInfo
}

export type SchoolClassEdge = {
  __typename?: 'SchoolClassEdge'
  cursor: Scalars['String']
  node: SchoolClass
}

export type SchoolClassRelationships = {
  __typename?: 'SchoolClassRelationships'
  foo?: Maybe<Scalars['String']>
}

export type SchoolEditStudentInput = {
  attributes: UserAttributesInput
  schoolAttendanceNumber: Scalars['Int']
  schoolClass: Scalars['Int']
  schoolGrade: Scalars['Int']
  schoolId: Scalars['ID']
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolGrade */
export type SchoolGrade = {
  __typename?: 'SchoolGrade'
  numberOfClasses: Scalars['Int']
  numberOfStudentsPerClass: Array<Scalars['Int']>
  schoolGrade: Scalars['Int']
  testYear: Scalars['Int']
}

/** # SchoolGradesInput */
export type SchoolGradesInput = {
  schoolGrade?: InputMaybe<Scalars['Int']>
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolGradesResponse */
export type SchoolGradesResponse = {
  __typename?: 'SchoolGradesResponse'
  count: Scalars['Int']
  data: Array<SchoolGrade>
  error?: Maybe<Scalars['String']>
}

/** # SchoolInvitationCode */
export type SchoolInvitationCode = {
  __typename?: 'SchoolInvitationCode'
  code: Scalars['String']
  schoolClass: Scalars['Int']
  schoolGrade: Scalars['Int']
  students?: Maybe<Array<Maybe<MetaStudent>>>
  testYear: Scalars['Int']
}

export enum SchoolInvitationCodeType {
  GradeAdmin = 'GRADE_ADMIN',
  SchoolAdmin = 'SCHOOL_ADMIN',
  Teacher = 'TEACHER',
}

/** # SchoolLinkTestYearInput */
export type SchoolLinkTestYearInput = {
  schoolId: Scalars['String']
  testYear: Scalars['Int']
}

/** # SchoolRelationships */
export type SchoolRelationships = {
  __typename?: 'SchoolRelationships'
  japanSchool?: Maybe<JapanSchool>
  students?: Maybe<Array<User>>
  teachers?: Maybe<Array<User>>
}

export type SchoolReportTestByGrade = {
  __typename?: 'SchoolReportTestByGrade'
  bending_avg?: Maybe<Scalars['Float']>
  bending_std?: Maybe<Scalars['Float']>
  count?: Maybe<Scalars['Int']>
  grade?: Maybe<Scalars['Int']>
  grip_avg?: Maybe<Scalars['Float']>
  grip_std?: Maybe<Scalars['Float']>
  handballThrow_avg?: Maybe<Scalars['Float']>
  handballThrow_std?: Maybe<Scalars['Float']>
  height_avg?: Maybe<Scalars['Float']>
  height_std?: Maybe<Scalars['Float']>
  points_avg?: Maybe<Scalars['Float']>
  points_std?: Maybe<Scalars['Float']>
  rank_a?: Maybe<Scalars['Int']>
  rank_b?: Maybe<Scalars['Int']>
  rank_c?: Maybe<Scalars['Int']>
  rank_d?: Maybe<Scalars['Int']>
  rank_e?: Maybe<Scalars['Int']>
  shuttleRun_avg?: Maybe<Scalars['Float']>
  shuttleRun_std?: Maybe<Scalars['Float']>
  sideJump_avg?: Maybe<Scalars['Float']>
  sideJump_std?: Maybe<Scalars['Float']>
  sitUps_avg?: Maybe<Scalars['Float']>
  sitUps_std?: Maybe<Scalars['Float']>
  sprintRun_avg?: Maybe<Scalars['Float']>
  sprintRun_std?: Maybe<Scalars['Float']>
  standingJump_avg?: Maybe<Scalars['Float']>
  standingJump_std?: Maybe<Scalars['Float']>
  testYear?: Maybe<Scalars['Int']>
  weight_avg?: Maybe<Scalars['Float']>
  weight_std?: Maybe<Scalars['Float']>
}

export type SchoolReportTestScoreChartByGrade = {
  __typename?: 'SchoolReportTestScoreChartByGrade'
  chart?: Maybe<ReportTestScoreChart>
  grade?: Maybe<Scalars['Int']>
  testYear?: Maybe<Scalars['Int']>
}

export type SchoolReportTestScoreChartSummary = {
  __typename?: 'SchoolReportTestScoreChartSummary'
  reports?: Maybe<Array<SchoolReportTestScoreChartByGrade>>
}

export type SchoolReportTestScoreChartSummaryInput = {
  gender: Scalars['String']
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolReportTestScoreChartSummary */
export type SchoolReportTestScoreChartSummaryResponse = {
  __typename?: 'SchoolReportTestScoreChartSummaryResponse'
  data?: Maybe<SchoolReportTestScoreChartSummary>
  error?: Maybe<Scalars['String']>
}

export type SchoolReportTestSummary = {
  __typename?: 'SchoolReportTestSummary'
  reports?: Maybe<Array<SchoolReportTestByGrade>>
}

export type SchoolReportTestSummaryInput = {
  gender: Scalars['String']
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolReportTestSummary */
export type SchoolReportTestSummaryResponse = {
  __typename?: 'SchoolReportTestSummaryResponse'
  data?: Maybe<SchoolReportTestSummary>
  error?: Maybe<Scalars['String']>
}

/** # SchoolResponse */
export type SchoolResponse = {
  __typename?: 'SchoolResponse'
  data?: Maybe<School>
  error?: Maybe<Scalars['String']>
}

export enum SchoolType {
  Other = 'OTHER',
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type SchoolUnlinkStudentInput = {
  schoolAttendanceNumber: Scalars['Int']
  schoolClass: Scalars['Int']
  schoolGrade: Scalars['Int']
  schoolId: Scalars['ID']
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolUnlinkTeacherInput */
export type SchoolUnlinkTeacherInput = {
  schoolId: Scalars['ID']
  teacherId: Scalars['ID']
}

/** # SchoolsInput */
export type SchoolsInput = {
  cityName?: InputMaybe<Scalars['String']>
  districtName?: InputMaybe<Scalars['String']>
  from?: InputMaybe<Scalars['Int']>
  ids?: InputMaybe<Array<Scalars['ID']>>
  limit?: InputMaybe<Scalars['Int']>
  prefectureCode?: InputMaybe<Scalars['Int']>
  schoolCategoryCode?: InputMaybe<Scalars['String']>
  schoolCodes?: InputMaybe<Array<Scalars['String']>>
  schoolTypeCode?: InputMaybe<Scalars['Int']>
}

/** # SchoolsResponse */
export type SchoolsResponse = {
  __typename?: 'SchoolsResponse'
  count: Scalars['Int']
  data: Array<School>
  error?: Maybe<Scalars['String']>
  totalCount: Scalars['Int']
}

/** # SearchHintsInput */
export type SearchHintsInput = {
  limit?: InputMaybe<Scalars['Int']>
  term: Scalars['String']
}

export type SearchHintsResponse = {
  __typename?: 'SearchHintsResponse'
  count: Scalars['Int']
  data?: Maybe<Array<Hint>>
  error?: Maybe<Scalars['String']>
}

/** # SearchPrefecturesHintsInput */
export type SearchPrefecturesHintsInput = {
  areaId?: InputMaybe<Scalars['ID']>
  limit?: InputMaybe<Scalars['Int']>
  term: Scalars['String']
}

/** # ShuttleRunInput */
export type ShuttleRunInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  shuttleRunCount?: InputMaybe<Scalars['Float']>
  shuttleRunGainOxygen?: InputMaybe<Scalars['Float']>
}

export type ShuttleRunResult = {
  __typename?: 'ShuttleRunResult'
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  shuttleRunCount?: Maybe<Scalars['Float']>
  shuttleRunGainOxygen?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

/** # SideJumpInput */
export type SideJumpInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  sideJump1?: InputMaybe<Scalars['Float']>
  sideJump2?: InputMaybe<Scalars['Float']>
}

export type SideJumpResult = {
  __typename?: 'SideJumpResult'
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  sideJump1?: Maybe<Scalars['Float']>
  sideJump2?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

/** # SitUpsInput */
export type SitUpsInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  sitUps?: InputMaybe<Scalars['Float']>
}

export type SitUpsResult = {
  __typename?: 'SitUpsResult'
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  sitUps?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

export type SizeResult = {
  __typename?: 'SizeResult'
  height?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

export type Sport = {
  __typename?: 'Sport'
  _id: Scalars['ID']
  attributes: SportAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SportAttributes = {
  __typename?: 'SportAttributes'
  key: Scalars['String']
  maxValue: Scalars['Int']
  minValue: Scalars['Int']
  name: Scalars['String']
  sortOrder: SortOrder
  unit: Scalars['String']
}

export type SportConnection = {
  __typename?: 'SportConnection'
  edges: Array<SportEdge>
  pageInfo: PageInfo
}

export type SportEdge = {
  __typename?: 'SportEdge'
  cursor: Scalars['String']
  node: Sport
}

export type SportInput = {
  _id: Scalars['ID']
}

export type SportsInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  school?: InputMaybe<Scalars['ID']>
}

export type SportsResponse = {
  __typename?: 'SportsResponse'
  count: Scalars['Int']
  data: Array<Sport>
  error?: Maybe<Scalars['String']>
}

/** # SprintRunInput */
export type SprintRunInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  sprintRunSeconds?: InputMaybe<Scalars['Float']>
}

export type SprintRunResult = {
  __typename?: 'SprintRunResult'
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  sprintRunSeconds?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

/** # StandingJumpInput */
export type StandingJumpInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  standingJump1?: InputMaybe<Scalars['Float']>
  standingJump2?: InputMaybe<Scalars['Float']>
}

export type StandingJumpResult = {
  __typename?: 'StandingJumpResult'
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  standingJump1?: Maybe<Scalars['Float']>
  standingJump2?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

/** # StudentInvitationCodeGradesInput */
export type StudentInvitationCodeGradesInput = {
  classes: Scalars['Int']
  grade: Scalars['Int']
}

/** # StudentInvitationCodesInput */
export type StudentInvitationCodesInput = {
  schoolGrades: Array<StudentInvitationCodeGradesInput>
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # StudentInvitationCodesResponse */
export type StudentInvitationCodesResponse = {
  __typename?: 'StudentInvitationCodesResponse'
  data?: Maybe<Array<SchoolInvitationCode>>
  error?: Maybe<Scalars['String']>
}

/** # StudentLinkSchoolInput */
export type StudentLinkSchoolInput = {
  invitationCode: Scalars['String']
}

export type StudentLinkSchoolResponse = {
  __typename?: 'StudentLinkSchoolResponse'
  error?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

/** # StudentQuestionnaireV2Input */
export type StudentQuestionnaireV2Input = {
  answers?: InputMaybe<Array<Scalars['Int']>>
  height: Scalars['Float']
  sittingHeight?: InputMaybe<Scalars['Float']>
  testYear: Scalars['Int']
  weight: Scalars['Float']
}

/** # StudentQuestionnarieInput */
export type StudentQuestionnarieInput = {
  belongsToSportsClub: Scalars['Int']
  breakfast: Scalars['Int']
  dailyExcerciseTime: Scalars['Int']
  excerciseActivity: Scalars['Int']
  height: Scalars['Float']
  sittingHeight?: InputMaybe<Scalars['Float']>
  sleepTime: Scalars['Int']
  testYear: Scalars['Int']
  tvTime: Scalars['Int']
  weight: Scalars['Float']
}

/** StudentsInput */
export type StudentsInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  schoolClass?: InputMaybe<Scalars['Int']>
  schoolGrade?: InputMaybe<Scalars['Int']>
  schoolId: Scalars['ID']
  testYear?: InputMaybe<Scalars['Int']>
}

/** # TeacherLinkSchoolInput */
export type TeacherLinkSchoolInput = {
  attributes: UserAttributesInput
  invitationCode: Scalars['String']
}

export type TeacherLinkSchoolResponse = {
  __typename?: 'TeacherLinkSchoolResponse'
  error?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

/** TeachersInput */
export type TeachersInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  role?: InputMaybe<UserRole>
  schoolId: Scalars['ID']
  testYear?: InputMaybe<Scalars['Int']>
}

export type TestResult = {
  __typename?: 'TestResult'
  age?: Maybe<Scalars['Int']>
  bending?: Maybe<BendingResult>
  enduranceRun?: Maybe<EnduranceRunResult>
  gender?: Maybe<Scalars['String']>
  grip?: Maybe<GripResult>
  handballThrow?: Maybe<HandballThrowResult>
  points?: Maybe<Scalars['Int']>
  questionnaireV3?: Maybe<QuestionnaireV3Result>
  rank?: Maybe<Scalars['String']>
  shuttleRun?: Maybe<ShuttleRunResult>
  sideJump?: Maybe<SideJumpResult>
  sitUps?: Maybe<SitUpsResult>
  sizeTest?: Maybe<SizeResult>
  sprintRun?: Maybe<SprintRunResult>
  standingJump?: Maybe<StandingJumpResult>
  testYear?: Maybe<Scalars['Int']>
}

export type TestYear = {
  __typename?: 'TestYear'
  _id: Scalars['ID']
  attributes: TestYearAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type TestYearAttributes = {
  __typename?: 'TestYearAttributes'
  era: Era
  year: Scalars['Int']
}

export type TestYearConnection = {
  __typename?: 'TestYearConnection'
  edges: Array<TestYearEdge>
  pageInfo: PageInfo
}

export type TestYearEdge = {
  __typename?: 'TestYearEdge'
  cursor: Scalars['String']
  node: TestYear
}

export type UpdateSchoolAttributesInput = {
  email?: InputMaybe<Scalars['String']>
  inputtedCountCondition?: InputMaybe<Scalars['String']>
  isStudentInputActive?: InputMaybe<Scalars['Boolean']>
  schoolAdminEmail?: InputMaybe<Scalars['String']>
  schoolAdminName?: InputMaybe<Scalars['String']>
  telephone?: InputMaybe<Scalars['String']>
  videoQualitySetting?: InputMaybe<Scalars['Boolean']>
  /** Updated by admin */
  website?: InputMaybe<Scalars['String']>
}

/** # UpdateSchoolInput */
export type UpdateSchoolInput = {
  attributes: UpdateSchoolAttributesInput
  schoolId: Scalars['ID']
}

export type UpdateTeacherRoleInput = {
  role: UserRole
  schoolClass?: InputMaybe<Scalars['Int']>
  schoolGrade?: InputMaybe<Scalars['Int']>
  schoolId: Scalars['String']
  teacherEmail: Scalars['String']
}

/** # UpdateUserInput */
export type UpdateUserInput = {
  attributes: UserAttributesInput
}

/** # User */
export type User = {
  __typename?: 'User'
  _id: Scalars['ID']
  attributes: UserAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  relationships: UserRelationships
  updatedAt?: Maybe<Scalars['DateTime']>
  username?: Maybe<Scalars['String']>
}

/** # UserAttributes */
export type UserAttributes = {
  __typename?: 'UserAttributes'
  birthday?: Maybe<Scalars['DateTime']>
  dayBirth?: Maybe<Scalars['Int']>
  email?: Maybe<Scalars['String']>
  familyName?: Maybe<Scalars['String']>
  familyNameHiragana?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  givenName?: Maybe<Scalars['String']>
  givenNameHiragana?: Maybe<Scalars['String']>
  listAveragePoints?: Maybe<Array<ListAveragePoints>>
  listAveragePointsByBirthMonth?: Maybe<Array<ListAveragePoints>>
  monthBirth?: Maybe<Scalars['Int']>
  prefectureCode?: Maybe<Scalars['Int']>
  role?: Maybe<UserRole>
  schoolAttendanceNumber?: Maybe<Scalars['Int']>
  schoolCategoryCode?: Maybe<Scalars['String']>
  schoolClass?: Maybe<Scalars['Int']>
  schoolGrade?: Maybe<Scalars['Int']>
  schoolName?: Maybe<Scalars['String']>
  testResults?: Maybe<Array<TestResult>>
  yearBirth?: Maybe<Scalars['Int']>
}

/** # UserAttributesInput */
export type UserAttributesInput = {
  attendanceNumber?: InputMaybe<Scalars['Int']>
  birthday?: InputMaybe<Scalars['String']>
  clubActivity?: InputMaybe<ClubActivity>
  email?: InputMaybe<Scalars['String']>
  familyName?: InputMaybe<Scalars['String']>
  familyNameHiragana?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  givenName?: InputMaybe<Scalars['String']>
  givenNameHiragana?: InputMaybe<Scalars['String']>
  otherClubActivity?: InputMaybe<Scalars['String']>
  prefectureCode?: InputMaybe<Scalars['Int']>
}

export enum UserGender {
  Female = 'FEMALE',
  Male = 'MALE',
}

export type UserLogAttributes = {
  __typename?: 'UserLogAttributes'
  eventType: Scalars['String']
  eventValue_date?: Maybe<Scalars['DateTime']>
  eventValue_enum?: Maybe<Scalars['String']>
  eventValue_int?: Maybe<Scalars['Int']>
  eventValue_meta?: Maybe<Scalars['JSON']>
  eventValue_string?: Maybe<Scalars['String']>
}

export type UserLogRelationships = {
  __typename?: 'UserLogRelationships'
  user: User
}

export type UserLogs = {
  __typename?: 'UserLogs'
  _id: Scalars['ID']
  attributes: UserLogAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  relationships: UserLogRelationships
  updatedAt?: Maybe<Scalars['DateTime']>
}

/** # UserRelationships */
export type UserRelationships = {
  __typename?: 'UserRelationships'
  schools?: Maybe<Array<School>>
}

/** # UserResponse */
export type UserResponse = {
  __typename?: 'UserResponse'
  data?: Maybe<User>
  error?: Maybe<Scalars['String']>
}

export enum UserRole {
  Admin = 'ADMIN',
  GradeAdmin = 'GRADE_ADMIN',
  Guest = 'GUEST',
  LocalGovernment = 'LOCAL_GOVERNMENT',
  SchoolAdmin = 'SCHOOL_ADMIN',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
}

export type UserSchoolCode = {
  __typename?: 'UserSchoolCode'
  code: Scalars['String']
}

export type UserSchoolYear = {
  __typename?: 'UserSchoolYear'
  attendanceNumber?: Maybe<Scalars['Int']>
  clubActivity?: Maybe<ClubActivity>
  isCurrent?: Maybe<Scalars['Boolean']>
  otherClubActivity?: Maybe<Scalars['String']>
  schoolCategoryCode?: Maybe<Scalars['String']>
  schoolClass?: Maybe<Scalars['Int']>
  schoolCode?: Maybe<Scalars['String']>
  schoolGrade?: Maybe<Scalars['Int']>
  schoolName?: Maybe<Scalars['String']>
  schoolTypeCode?: Maybe<Scalars['Int']>
  year?: Maybe<Scalars['Int']>
}

export type UserSchoolYearResponse = {
  __typename?: 'UserSchoolYearResponse'
  data?: Maybe<UserSchoolYear>
  error?: Maybe<Scalars['String']>
}

/** # UsersResponse */
export type UsersResponse = {
  __typename?: 'UsersResponse'
  count: Scalars['Int']
  data: Array<User>
  error?: Maybe<Scalars['String']>
  totalCount: Scalars['Int']
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type UpdateSchoolMutationVariables = Exact<{
  input: UpdateSchoolInput
}>

export type UpdateSchoolMutation = {
  __typename?: 'Mutation'
  updateSchool?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type RegisterGradeMutationVariables = Exact<{
  input: RegisterSchoolGradeInput
}>

export type RegisterGradeMutation = {
  __typename?: 'Mutation'
  registerGrade?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type TeacherLinkSchoolMutationVariables = Exact<{
  input: TeacherLinkSchoolInput
}>

export type TeacherLinkSchoolMutation = {
  __typename?: 'Mutation'
  teacherLinkSchool?: {
    __typename?: 'TeacherLinkSchoolResponse'
    token?: string | null
    error?: string | null
  } | null
}

export type StudentLinkSchoolMutationVariables = Exact<{
  input: StudentLinkSchoolInput
}>

export type StudentLinkSchoolMutation = {
  __typename?: 'Mutation'
  studentLinkSchool?: {
    __typename?: 'StudentLinkSchoolResponse'
    token?: string | null
    error?: string | null
  } | null
}

export type AnalyticsEventMutationVariables = Exact<{
  input: Scalars['JSON']
}>

export type AnalyticsEventMutation = {
  __typename?: 'Mutation'
  analyticsEvent: boolean
}

export type AnalyticsEventsMutationVariables = Exact<{
  input: Array<Scalars['JSON']> | Scalars['JSON']
}>

export type AnalyticsEventsMutation = {
  __typename?: 'Mutation'
  analyticsEvents: boolean
}

export type AreasQueryVariables = Exact<{
  input?: InputMaybe<AreasInput>
}>

export type AreasQuery = {
  __typename?: 'Query'
  areas?: {
    __typename?: 'AreasResponse'
    data: Array<{ __typename?: 'Area'; _id: string; name: string }>
  } | null
}

export type PrefecturesQueryVariables = Exact<{
  input?: InputMaybe<PrefecturesInput>
}>

export type PrefecturesQuery = {
  __typename?: 'Query'
  prefectures?: {
    __typename?: 'PrefecturesResponse'
    data: Array<{
      __typename?: 'Prefecture'
      _id: string
      name: string
      code: number
      area: any
    }>
  } | null
}

export type CitiesQueryVariables = Exact<{
  input: CitiesInput
}>

export type CitiesQuery = {
  __typename?: 'Query'
  cities?: {
    __typename?: 'CitiesResponse'
    data: Array<{ __typename?: 'City'; _id: string; name: string }>
  } | null
}

export type JapanSchoolCategoriesQueryVariables = Exact<{
  [key: string]: never
}>

export type JapanSchoolCategoriesQuery = {
  __typename?: 'Query'
  japanSchoolCategories?: {
    __typename?: 'JapanSchoolCategoriesResponse'
    data?: Array<{
      __typename?: 'JapanSchoolCategory'
      code: string
      name: string
    }> | null
  } | null
}

export type JapanSchoolTypesQueryVariables = Exact<{ [key: string]: never }>

export type JapanSchoolTypesQuery = {
  __typename?: 'Query'
  japanSchoolTypes?: {
    __typename?: 'JapanSchoolTypesResponse'
    data?: Array<{
      __typename?: 'JapanSchoolType'
      code: number
      name: string
    }> | null
  } | null
}

export type SchoolsQueryVariables = Exact<{
  input: SchoolsInput
}>

export type SchoolsQuery = {
  __typename?: 'Query'
  schools?: {
    __typename?: 'SchoolsResponse'
    count: number
    totalCount: number
    error?: string | null
    data: Array<{ __typename?: 'School'; _id: string }>
  } | null
}

export type GradesQueryVariables = Exact<{
  input: SchoolGradesInput
}>

export type GradesQuery = {
  __typename?: 'Query'
  grades?: {
    __typename?: 'SchoolGradesResponse'
    count: number
    error?: string | null
    data: Array<{
      __typename?: 'SchoolGrade'
      testYear: number
      schoolGrade: number
      numberOfClasses: number
      numberOfStudentsPerClass: Array<number>
    }>
  } | null
}

export type TeachersQueryVariables = Exact<{
  input: TeachersInput
}>

export type TeachersQuery = {
  __typename?: 'Query'
  teachers?: {
    __typename?: 'UsersResponse'
    count: number
    totalCount: number
    error?: string | null
    data: Array<{
      __typename?: 'User'
      _id: string
      attributes: {
        __typename?: 'UserAttributes'
        givenName?: string | null
        givenNameHiragana?: string | null
        familyName?: string | null
        familyNameHiragana?: string | null
        email?: string | null
        role?: UserRole | null
        schoolGrade?: number | null
        schoolClass?: number | null
      }
    }>
  } | null
}

export type StudentsQueryVariables = Exact<{
  input: StudentsInput
}>

export type StudentsQuery = {
  __typename?: 'Query'
  students?: {
    __typename?: 'UsersResponse'
    count: number
    totalCount: number
    error?: string | null
    data: Array<{
      __typename?: 'User'
      _id: string
      username?: string | null
      attributes: {
        __typename?: 'UserAttributes'
        givenName?: string | null
        givenNameHiragana?: string | null
        familyName?: string | null
        familyNameHiragana?: string | null
        birthday?: any | null
        yearBirth?: number | null
        monthBirth?: number | null
        dayBirth?: number | null
        gender?: string | null
        schoolName?: string | null
        schoolGrade?: number | null
        schoolClass?: number | null
        schoolAttendanceNumber?: number | null
        email?: string | null
        testResults?: Array<{
          __typename?: 'TestResult'
          testYear?: number | null
          points?: number | null
          rank?: string | null
          age?: number | null
          grip?: {
            __typename?: 'GripResult'
            gripLeft1?: number | null
            gripLeft2?: number | null
            gripRight1?: number | null
            gripRight2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
          } | null
          sitUps?: {
            __typename?: 'SitUpsResult'
            sitUps?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
          } | null
          bending?: {
            __typename?: 'BendingResult'
            bending1?: number | null
            bending2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
          } | null
          sideJump?: {
            __typename?: 'SideJumpResult'
            sideJump1?: number | null
            sideJump2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
          } | null
          shuttleRun?: {
            __typename?: 'ShuttleRunResult'
            shuttleRunCount?: number | null
            shuttleRunGainOxygen?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
          } | null
          sprintRun?: {
            __typename?: 'SprintRunResult'
            sprintRunSeconds?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
          } | null
          standingJump?: {
            __typename?: 'StandingJumpResult'
            standingJump1?: number | null
            standingJump2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
          } | null
          handballThrow?: {
            __typename?: 'HandballThrowResult'
            handballThrow1?: number | null
            handballThrow2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
          } | null
          enduranceRun?: {
            __typename?: 'EnduranceRunResult'
            runningTime?: string | null
            runningTimeSeconds?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
          } | null
          sizeTest?: {
            __typename?: 'SizeResult'
            height?: number | null
            weight?: number | null
          } | null
          questionnaireV3?: {
            __typename?: 'QuestionnaireV3Result'
            q1?: string | null
            q2?: string | null
            q3?: string | null
            q4?: string | null
            q5?: string | null
            q6?: string | null
            q7?: string | null
            q8?: string | null
            q9?: string | null
            q10?: string | null
            q11?: string | null
            q12?: string | null
            q13?: string | null
            q14?: string | null
            q15?: string | null
            q16?: string | null
            q17?: string | null
            q18?: string | null
            q19?: string | null
            q20?: string | null
            q21?: string | null
            q22?: string | null
            q23?: string | null
            q24?: string | null
            q25?: string | null
            q26?: string | null
            q27?: string | null
            q28?: string | null
            q29?: string | null
            q30?: string | null
            q31?: string | null
            q32?: string | null
            q33?: string | null
            q34?: string | null
            q35?: string | null
            q36?: string | null
            q37?: string | null
            q38?: string | null
            q39?: string | null
            q40?: string | null
            q41?: string | null
            q42?: string | null
            q43?: string | null
            q44?: string | null
            q45?: string | null
            q46?: string | null
            q47?: string | null
            q48?: string | null
            q49?: string | null
            q50?: string | null
          } | null
        }> | null
        listAveragePoints?: Array<{
          __typename?: 'ListAveragePoints'
          key: string
          title: string
          value?: number | null
        }> | null
        listAveragePointsByBirthMonth?: Array<{
          __typename?: 'ListAveragePoints'
          key: string
          title: string
          value?: number | null
        }> | null
      }
    }>
  } | null
}

export type ReportCountSummaryQueryVariables = Exact<{
  input: ReportCountSummaryInput
}>

export type ReportCountSummaryQuery = {
  __typename?: 'Query'
  reportCountSummary?: {
    __typename?: 'ReportCountSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportCountSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportCountByCategory'
        schoolCount?: number | null
        reports?: Array<{
          __typename?: 'ReportCountByGrade'
          grade?: number | null
          male?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
          female?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportCountByCategory'
        schoolCount?: number | null
        reports?: Array<{
          __typename?: 'ReportCountByGrade'
          grade?: number | null
          male?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
          female?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
        }> | null
      } | null
      allDayHighSchoolReport?: {
        __typename?: 'ReportCountByCategory'
        schoolCount?: number | null
        reports?: Array<{
          __typename?: 'ReportCountByGrade'
          grade?: number | null
          male?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
          female?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
        }> | null
      } | null
      fixTimeHighSchoolReport?: {
        __typename?: 'ReportCountByCategory'
        schoolCount?: number | null
        reports?: Array<{
          __typename?: 'ReportCountByGrade'
          grade?: number | null
          male?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
          female?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportBodySummaryQueryVariables = Exact<{
  input: ReportBodySummaryInput
}>

export type ReportBodySummaryQuery = {
  __typename?: 'Query'
  reportBodySummary?: {
    __typename?: 'ReportBodySummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportBodySummary'
      elementarySchoolReport?: {
        __typename?: 'ReportBodyByCategory'
        reports?: Array<{
          __typename?: 'ReportBody'
          grade?: number | null
          sampleCount?: number | null
          heightAvg?: number | null
          weightAvg?: number | null
          heightDev?: number | null
          weightDev?: number | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportBodyByCategory'
        reports?: Array<{
          __typename?: 'ReportBody'
          grade?: number | null
          sampleCount?: number | null
          heightAvg?: number | null
          weightAvg?: number | null
          heightDev?: number | null
          weightDev?: number | null
        }> | null
      } | null
      allDayHighSchoolReport?: {
        __typename?: 'ReportBodyByCategory'
        reports?: Array<{
          __typename?: 'ReportBody'
          grade?: number | null
          sampleCount?: number | null
          heightAvg?: number | null
          weightAvg?: number | null
          heightDev?: number | null
          weightDev?: number | null
        }> | null
      } | null
      fixTimeHighSchoolReport?: {
        __typename?: 'ReportBodyByCategory'
        reports?: Array<{
          __typename?: 'ReportBody'
          grade?: number | null
          sampleCount?: number | null
          heightAvg?: number | null
          weightAvg?: number | null
          heightDev?: number | null
          weightDev?: number | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportTestSummaryQueryVariables = Exact<{
  input: ReportTestSummaryInput
}>

export type ReportTestSummaryQuery = {
  __typename?: 'Query'
  reportTestSummary?: {
    __typename?: 'ReportTestSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportTestSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportTestByCategory'
        reports?: Array<{
          __typename?: 'ReportTestByGrade'
          grade?: number | null
          average?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
          deviation?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportTestByCategory'
        reports?: Array<{
          __typename?: 'ReportTestByGrade'
          grade?: number | null
          average?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
          deviation?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
        }> | null
      } | null
      allDayHighSchoolReport?: {
        __typename?: 'ReportTestByCategory'
        reports?: Array<{
          __typename?: 'ReportTestByGrade'
          grade?: number | null
          average?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
          deviation?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
        }> | null
      } | null
      fixTimeHighSchoolReport?: {
        __typename?: 'ReportTestByCategory'
        reports?: Array<{
          __typename?: 'ReportTestByGrade'
          grade?: number | null
          average?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
          deviation?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportTestComparisonSummaryQueryVariables = Exact<{
  input: ReportTestComparisonSummaryInput
}>

export type ReportTestComparisonSummaryQuery = {
  __typename?: 'Query'
  reportTestComparisonSummary?: {
    __typename?: 'ReportTestComparisonSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportTestComparisonSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportTestComparisonByCategory'
        reports?: Array<{
          __typename?: 'ReportTestComparisonByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
          national?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportTestComparisonByCategory'
        reports?: Array<{
          __typename?: 'ReportTestComparisonByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
          national?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
        }> | null
      } | null
      highSchoolReport?: {
        __typename?: 'ReportTestComparisonByCategory'
        reports?: Array<{
          __typename?: 'ReportTestComparisonByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
          national?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportAverageScoreGraphSummaryQueryVariables = Exact<{
  input: ReportAverageScoreGraphSummaryInput
}>

export type ReportAverageScoreGraphSummaryQuery = {
  __typename?: 'Query'
  reportAverageScoreGraphSummary?: {
    __typename?: 'ReportAverageScoreGraphSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportAverageScoreGraphSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportAverageScoreGraphByCategory'
        reports?: Array<{
          __typename?: 'ReportAverageScoreGraphByYear'
          year?: number | null
          grades?: Array<{
            __typename?: 'ReportAverageScoreGraphByGrade'
            grade?: number | null
            score?: {
              __typename?: 'ReportAverageScoreGraph'
              grip?: number | null
              sitUps?: number | null
              bending?: number | null
              sideJump?: number | null
              shuttleRun?: number | null
              sprintRun?: number | null
              standingJump?: number | null
              handballThrow?: number | null
              totalScore?: number | null
            } | null
          }> | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportAverageScoreGraphByCategory'
        reports?: Array<{
          __typename?: 'ReportAverageScoreGraphByYear'
          year?: number | null
          grades?: Array<{
            __typename?: 'ReportAverageScoreGraphByGrade'
            grade?: number | null
            score?: {
              __typename?: 'ReportAverageScoreGraph'
              grip?: number | null
              sitUps?: number | null
              bending?: number | null
              sideJump?: number | null
              shuttleRun?: number | null
              sprintRun?: number | null
              standingJump?: number | null
              handballThrow?: number | null
              totalScore?: number | null
            } | null
          }> | null
        }> | null
      } | null
      highSchoolReport?: {
        __typename?: 'ReportAverageScoreGraphByCategory'
        reports?: Array<{
          __typename?: 'ReportAverageScoreGraphByYear'
          year?: number | null
          grades?: Array<{
            __typename?: 'ReportAverageScoreGraphByGrade'
            grade?: number | null
            score?: {
              __typename?: 'ReportAverageScoreGraph'
              grip?: number | null
              sitUps?: number | null
              bending?: number | null
              sideJump?: number | null
              shuttleRun?: number | null
              sprintRun?: number | null
              standingJump?: number | null
              handballThrow?: number | null
              totalScore?: number | null
            } | null
          }> | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportTestScoreChartSummaryQueryVariables = Exact<{
  input: ReportTestScoreChartSummaryInput
}>

export type ReportTestScoreChartSummaryQuery = {
  __typename?: 'Query'
  reportTestScoreChartSummary?: {
    __typename?: 'ReportTestScoreChartSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportTestScoreChartSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportTestScoreChartByCategory'
        reports?: Array<{
          __typename?: 'ReportTestScoreChartByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestScoreChart'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
            height?: number | null
            weight?: number | null
          } | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportTestScoreChartByCategory'
        reports?: Array<{
          __typename?: 'ReportTestScoreChartByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestScoreChart'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
            height?: number | null
            weight?: number | null
          } | null
        }> | null
      } | null
      highSchoolReport?: {
        __typename?: 'ReportTestScoreChartByCategory'
        reports?: Array<{
          __typename?: 'ReportTestScoreChartByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestScoreChart'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
            height?: number | null
            weight?: number | null
          } | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportTestRankTargetSummaryQueryVariables = Exact<{
  input: ReportTestRankTargetSummaryInput
}>

export type ReportTestRankTargetSummaryQuery = {
  __typename?: 'Query'
  reportTestRankTargetSummary?: {
    __typename?: 'ReportTestRankTargetSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportTestRankTargetSummary'
      reports?: Array<{
        __typename?: 'ReportTestRankTargetByYear'
        year?: number | null
        totalRates?: Array<number> | null
        elementarySchoolRates?: Array<number> | null
        juniorHighSchoolRates?: Array<number> | null
        highSchoolRates?: Array<number> | null
      }> | null
      national?: Array<{
        __typename?: 'ReportTestRankTargetByYear'
        year?: number | null
        totalRates?: Array<number> | null
        elementarySchoolRates?: Array<number> | null
        juniorHighSchoolRates?: Array<number> | null
        highSchoolRates?: Array<number> | null
      }> | null
    } | null
  } | null
}

export type ReportCrossAnalysisSummaryQueryVariables = Exact<{
  input: ReportCrossAnalysisSummaryInput
}>

export type ReportCrossAnalysisSummaryQuery = {
  __typename?: 'Query'
  reportCrossAnalysisSummary?: {
    __typename?: 'ReportCrossAnalysisSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportCrossAnalysisSummary'
      reports?: Array<{
        __typename?: 'ReportCrossAnalysisByQuestionnaire'
        dataKey?: string | null
        grades?: Array<{
          __typename?: 'ReportCrossAnalysisByGrade'
          schoolCategory?: string | null
          grade?: number | null
          answers?: Array<{
            __typename?: 'ReportCrossAnalysisByAnswer'
            answer?: number | null
            totalCount?: number | null
            averages?: Array<{
              __typename?: 'ReportScore'
              score?: number | null
              scoreType?: string | null
            }> | null
            deviations?: Array<{
              __typename?: 'ReportScore'
              score?: number | null
              scoreType?: string | null
            }> | null
          }> | null
        }> | null
      }> | null
    } | null
  } | null
}

export type ReportSchoolPerformanceSummaryQueryVariables = Exact<{
  input: ReportSchoolPerformanceSummaryInput
}>

export type ReportSchoolPerformanceSummaryQuery = {
  __typename?: 'Query'
  reportSchoolPerformanceSummary?: {
    __typename?: 'ReportSchoolPerformanceSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportSchoolPerformanceSummary'
      elementarySchool?: Array<{
        __typename?: 'ReportSchoolPerformance'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
      juniorHighSchool?: Array<{
        __typename?: 'ReportSchoolPerformance'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
      highSchool?: Array<{
        __typename?: 'ReportSchoolPerformance'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
    } | null
  } | null
}

export type ReportSchoolProgressSummaryQueryVariables = Exact<{
  input: ReportSchoolProgressSummaryInput
}>

export type ReportSchoolProgressSummaryQuery = {
  __typename?: 'Query'
  reportSchoolProgressSummary?: {
    __typename?: 'ReportSchoolProgressSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportSchoolProgressSummary'
      elementarySchool?: Array<{
        __typename?: 'ReportSchoolProgress'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
      juniorHighSchool?: Array<{
        __typename?: 'ReportSchoolProgress'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
      highSchool?: Array<{
        __typename?: 'ReportSchoolProgress'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
    } | null
  } | null
}

export type ReportUploadListQueryVariables = Exact<{
  input: ReportUploadListInput
}>

export type ReportUploadListQuery = {
  __typename?: 'Query'
  reportUploadList?: {
    __typename?: 'ReportUploadListResponse'
    count: number
    totalCount: number
    error?: string | null
    data?: Array<{
      __typename?: 'ReportSchoolEntry'
      prefectureSchoolCode?: string | null
      schoolName?: string | null
      studentCount?: number | null
      cityName?: string | null
      organizationName?: string | null
      uploadedAt?: any | null
    }> | null
  } | null
}

export type StudentInvitationCodesQueryVariables = Exact<{
  input: StudentInvitationCodesInput
}>

export type StudentInvitationCodesQuery = {
  __typename?: 'Query'
  studentInvitationCodes?: {
    __typename?: 'StudentInvitationCodesResponse'
    error?: string | null
    data?: Array<{
      __typename?: 'SchoolInvitationCode'
      schoolGrade: number
      schoolClass: number
      code: string
      testYear: number
      students?: Array<{
        __typename?: 'MetaStudent'
        attendanceNumber: number
        studentInvitationCode?: string | null
        name: string
        familyName?: string | null
        givenName?: string | null
      } | null> | null
    }> | null
  } | null
}

export type SchoolReportTestSummaryQueryVariables = Exact<{
  input: SchoolReportTestSummaryInput
}>

export type SchoolReportTestSummaryQuery = {
  __typename?: 'Query'
  schoolReportTestSummary?: {
    __typename?: 'SchoolReportTestSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'SchoolReportTestSummary'
      reports?: Array<{
        __typename?: 'SchoolReportTestByGrade'
        testYear?: number | null
        grade?: number | null
        count?: number | null
        height_avg?: number | null
        weight_avg?: number | null
        grip_avg?: number | null
        sitUps_avg?: number | null
        bending_avg?: number | null
        sideJump_avg?: number | null
        shuttleRun_avg?: number | null
        sprintRun_avg?: number | null
        standingJump_avg?: number | null
        handballThrow_avg?: number | null
        points_avg?: number | null
        height_std?: number | null
        weight_std?: number | null
        grip_std?: number | null
        sitUps_std?: number | null
        bending_std?: number | null
        sideJump_std?: number | null
        shuttleRun_std?: number | null
        sprintRun_std?: number | null
        standingJump_std?: number | null
        handballThrow_std?: number | null
        points_std?: number | null
        rank_a?: number | null
        rank_b?: number | null
        rank_c?: number | null
        rank_d?: number | null
        rank_e?: number | null
      }> | null
    } | null
  } | null
}

export type SchoolReportTestScoreChartSummaryQueryVariables = Exact<{
  input: SchoolReportTestScoreChartSummaryInput
}>

export type SchoolReportTestScoreChartSummaryQuery = {
  __typename?: 'Query'
  schoolReportTestScoreChartSummary?: {
    __typename?: 'SchoolReportTestScoreChartSummaryResponse'
    data?: {
      __typename?: 'SchoolReportTestScoreChartSummary'
      reports?: Array<{
        __typename?: 'SchoolReportTestScoreChartByGrade'
        testYear?: number | null
        grade?: number | null
        chart?: {
          __typename?: 'ReportTestScoreChart'
          grip?: number | null
          sitUps?: number | null
          bending?: number | null
          sideJump?: number | null
          shuttleRun?: number | null
          sprintRun?: number | null
          standingJump?: number | null
          handballThrow?: number | null
          totalScore?: number | null
          height?: number | null
          weight?: number | null
        } | null
      }> | null
    } | null
  } | null
}

export type QrCodeStudentQueryVariables = Exact<{
  input: QrCodeStudentInput
}>

export type QrCodeStudentQuery = {
  __typename?: 'Query'
  qrCodeStudent?: {
    __typename?: 'UserResponse'
    error?: string | null
    data?: {
      __typename?: 'User'
      _id: string
      attributes: {
        __typename?: 'UserAttributes'
        givenName?: string | null
        givenNameHiragana?: string | null
        familyName?: string | null
        familyNameHiragana?: string | null
        schoolName?: string | null
        schoolGrade?: number | null
        schoolClass?: number | null
        schoolAttendanceNumber?: number | null
        yearBirth?: number | null
        monthBirth?: number | null
        dayBirth?: number | null
      }
    } | null
  } | null
}

export const UpdateSchoolDocument = gql`
  mutation UpdateSchool($input: UpdateSchoolInput!) {
    updateSchool(input: $input) {
      success
      error
    }
  }
`
export type UpdateSchoolMutationFn = Apollo.MutationFunction<
  UpdateSchoolMutation,
  UpdateSchoolMutationVariables
>

/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSchoolMutation,
    UpdateSchoolMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSchoolMutation,
    UpdateSchoolMutationVariables
  >(UpdateSchoolDocument, options)
}
export type UpdateSchoolMutationHookResult = ReturnType<
  typeof useUpdateSchoolMutation
>
export type UpdateSchoolMutationResult =
  Apollo.MutationResult<UpdateSchoolMutation>
export type UpdateSchoolMutationOptions = Apollo.BaseMutationOptions<
  UpdateSchoolMutation,
  UpdateSchoolMutationVariables
>
export const RegisterGradeDocument = gql`
  mutation RegisterGrade($input: RegisterSchoolGradeInput!) {
    registerGrade(input: $input) {
      success
      error
    }
  }
`
export type RegisterGradeMutationFn = Apollo.MutationFunction<
  RegisterGradeMutation,
  RegisterGradeMutationVariables
>

/**
 * __useRegisterGradeMutation__
 *
 * To run a mutation, you first call `useRegisterGradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterGradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerGradeMutation, { data, loading, error }] = useRegisterGradeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterGradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterGradeMutation,
    RegisterGradeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegisterGradeMutation,
    RegisterGradeMutationVariables
  >(RegisterGradeDocument, options)
}
export type RegisterGradeMutationHookResult = ReturnType<
  typeof useRegisterGradeMutation
>
export type RegisterGradeMutationResult =
  Apollo.MutationResult<RegisterGradeMutation>
export type RegisterGradeMutationOptions = Apollo.BaseMutationOptions<
  RegisterGradeMutation,
  RegisterGradeMutationVariables
>
export const TeacherLinkSchoolDocument = gql`
  mutation TeacherLinkSchool($input: TeacherLinkSchoolInput!) {
    teacherLinkSchool(input: $input) {
      token
      error
    }
  }
`
export type TeacherLinkSchoolMutationFn = Apollo.MutationFunction<
  TeacherLinkSchoolMutation,
  TeacherLinkSchoolMutationVariables
>

/**
 * __useTeacherLinkSchoolMutation__
 *
 * To run a mutation, you first call `useTeacherLinkSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherLinkSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherLinkSchoolMutation, { data, loading, error }] = useTeacherLinkSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeacherLinkSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TeacherLinkSchoolMutation,
    TeacherLinkSchoolMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TeacherLinkSchoolMutation,
    TeacherLinkSchoolMutationVariables
  >(TeacherLinkSchoolDocument, options)
}
export type TeacherLinkSchoolMutationHookResult = ReturnType<
  typeof useTeacherLinkSchoolMutation
>
export type TeacherLinkSchoolMutationResult =
  Apollo.MutationResult<TeacherLinkSchoolMutation>
export type TeacherLinkSchoolMutationOptions = Apollo.BaseMutationOptions<
  TeacherLinkSchoolMutation,
  TeacherLinkSchoolMutationVariables
>
export const StudentLinkSchoolDocument = gql`
  mutation StudentLinkSchool($input: StudentLinkSchoolInput!) {
    studentLinkSchool(input: $input) {
      token
      error
    }
  }
`
export type StudentLinkSchoolMutationFn = Apollo.MutationFunction<
  StudentLinkSchoolMutation,
  StudentLinkSchoolMutationVariables
>

/**
 * __useStudentLinkSchoolMutation__
 *
 * To run a mutation, you first call `useStudentLinkSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentLinkSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentLinkSchoolMutation, { data, loading, error }] = useStudentLinkSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentLinkSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StudentLinkSchoolMutation,
    StudentLinkSchoolMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StudentLinkSchoolMutation,
    StudentLinkSchoolMutationVariables
  >(StudentLinkSchoolDocument, options)
}
export type StudentLinkSchoolMutationHookResult = ReturnType<
  typeof useStudentLinkSchoolMutation
>
export type StudentLinkSchoolMutationResult =
  Apollo.MutationResult<StudentLinkSchoolMutation>
export type StudentLinkSchoolMutationOptions = Apollo.BaseMutationOptions<
  StudentLinkSchoolMutation,
  StudentLinkSchoolMutationVariables
>
export const AnalyticsEventDocument = gql`
  mutation AnalyticsEvent($input: JSON!) {
    analyticsEvent(input: $input)
  }
`
export type AnalyticsEventMutationFn = Apollo.MutationFunction<
  AnalyticsEventMutation,
  AnalyticsEventMutationVariables
>

/**
 * __useAnalyticsEventMutation__
 *
 * To run a mutation, you first call `useAnalyticsEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyticsEventMutation, { data, loading, error }] = useAnalyticsEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnalyticsEventMutation,
    AnalyticsEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AnalyticsEventMutation,
    AnalyticsEventMutationVariables
  >(AnalyticsEventDocument, options)
}
export type AnalyticsEventMutationHookResult = ReturnType<
  typeof useAnalyticsEventMutation
>
export type AnalyticsEventMutationResult =
  Apollo.MutationResult<AnalyticsEventMutation>
export type AnalyticsEventMutationOptions = Apollo.BaseMutationOptions<
  AnalyticsEventMutation,
  AnalyticsEventMutationVariables
>
export const AnalyticsEventsDocument = gql`
  mutation AnalyticsEvents($input: [JSON!]!) {
    analyticsEvents(input: $input)
  }
`
export type AnalyticsEventsMutationFn = Apollo.MutationFunction<
  AnalyticsEventsMutation,
  AnalyticsEventsMutationVariables
>

/**
 * __useAnalyticsEventsMutation__
 *
 * To run a mutation, you first call `useAnalyticsEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyticsEventsMutation, { data, loading, error }] = useAnalyticsEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnalyticsEventsMutation,
    AnalyticsEventsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AnalyticsEventsMutation,
    AnalyticsEventsMutationVariables
  >(AnalyticsEventsDocument, options)
}
export type AnalyticsEventsMutationHookResult = ReturnType<
  typeof useAnalyticsEventsMutation
>
export type AnalyticsEventsMutationResult =
  Apollo.MutationResult<AnalyticsEventsMutation>
export type AnalyticsEventsMutationOptions = Apollo.BaseMutationOptions<
  AnalyticsEventsMutation,
  AnalyticsEventsMutationVariables
>
export const AreasDocument = gql`
  query Areas($input: AreasInput) {
    areas(input: $input) {
      data {
        _id
        name
      }
    }
  }
`

/**
 * __useAreasQuery__
 *
 * To run a query within a React component, call `useAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreasQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<AreasQuery, AreasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AreasQuery, AreasQueryVariables>(
    AreasDocument,
    options,
  )
}
export function useAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AreasQuery, AreasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AreasQuery, AreasQueryVariables>(
    AreasDocument,
    options,
  )
}
export type AreasQueryHookResult = ReturnType<typeof useAreasQuery>
export type AreasLazyQueryHookResult = ReturnType<typeof useAreasLazyQuery>
export type AreasQueryResult = Apollo.QueryResult<
  AreasQuery,
  AreasQueryVariables
>
export const PrefecturesDocument = gql`
  query Prefectures($input: PrefecturesInput) {
    prefectures(input: $input) {
      data {
        _id
        name
        code
        area
      }
    }
  }
`

/**
 * __usePrefecturesQuery__
 *
 * To run a query within a React component, call `usePrefecturesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrefecturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrefecturesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrefecturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PrefecturesQuery,
    PrefecturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PrefecturesQuery, PrefecturesQueryVariables>(
    PrefecturesDocument,
    options,
  )
}
export function usePrefecturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrefecturesQuery,
    PrefecturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PrefecturesQuery, PrefecturesQueryVariables>(
    PrefecturesDocument,
    options,
  )
}
export type PrefecturesQueryHookResult = ReturnType<typeof usePrefecturesQuery>
export type PrefecturesLazyQueryHookResult = ReturnType<
  typeof usePrefecturesLazyQuery
>
export type PrefecturesQueryResult = Apollo.QueryResult<
  PrefecturesQuery,
  PrefecturesQueryVariables
>
export const CitiesDocument = gql`
  query Cities($input: CitiesInput!) {
    cities(input: $input) {
      data {
        _id
        name
      }
    }
  }
`

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCitiesQuery(
  baseOptions: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(
    CitiesDocument,
    options,
  )
}
export function useCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(
    CitiesDocument,
    options,
  )
}
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>
export type CitiesQueryResult = Apollo.QueryResult<
  CitiesQuery,
  CitiesQueryVariables
>
export const JapanSchoolCategoriesDocument = gql`
  query JapanSchoolCategories {
    japanSchoolCategories {
      data {
        code
        name
      }
    }
  }
`

/**
 * __useJapanSchoolCategoriesQuery__
 *
 * To run a query within a React component, call `useJapanSchoolCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJapanSchoolCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJapanSchoolCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJapanSchoolCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JapanSchoolCategoriesQuery,
    JapanSchoolCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    JapanSchoolCategoriesQuery,
    JapanSchoolCategoriesQueryVariables
  >(JapanSchoolCategoriesDocument, options)
}
export function useJapanSchoolCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JapanSchoolCategoriesQuery,
    JapanSchoolCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    JapanSchoolCategoriesQuery,
    JapanSchoolCategoriesQueryVariables
  >(JapanSchoolCategoriesDocument, options)
}
export type JapanSchoolCategoriesQueryHookResult = ReturnType<
  typeof useJapanSchoolCategoriesQuery
>
export type JapanSchoolCategoriesLazyQueryHookResult = ReturnType<
  typeof useJapanSchoolCategoriesLazyQuery
>
export type JapanSchoolCategoriesQueryResult = Apollo.QueryResult<
  JapanSchoolCategoriesQuery,
  JapanSchoolCategoriesQueryVariables
>
export const JapanSchoolTypesDocument = gql`
  query JapanSchoolTypes {
    japanSchoolTypes {
      data {
        code
        name
      }
    }
  }
`

/**
 * __useJapanSchoolTypesQuery__
 *
 * To run a query within a React component, call `useJapanSchoolTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJapanSchoolTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJapanSchoolTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJapanSchoolTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JapanSchoolTypesQuery,
    JapanSchoolTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JapanSchoolTypesQuery, JapanSchoolTypesQueryVariables>(
    JapanSchoolTypesDocument,
    options,
  )
}
export function useJapanSchoolTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JapanSchoolTypesQuery,
    JapanSchoolTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    JapanSchoolTypesQuery,
    JapanSchoolTypesQueryVariables
  >(JapanSchoolTypesDocument, options)
}
export type JapanSchoolTypesQueryHookResult = ReturnType<
  typeof useJapanSchoolTypesQuery
>
export type JapanSchoolTypesLazyQueryHookResult = ReturnType<
  typeof useJapanSchoolTypesLazyQuery
>
export type JapanSchoolTypesQueryResult = Apollo.QueryResult<
  JapanSchoolTypesQuery,
  JapanSchoolTypesQueryVariables
>
export const SchoolsDocument = gql`
  query Schools($input: SchoolsInput!) {
    schools(input: $input) {
      data {
        _id
      }
      count
      totalCount
      error
    }
  }
`

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolsQuery(
  baseOptions: Apollo.QueryHookOptions<SchoolsQuery, SchoolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SchoolsQuery, SchoolsQueryVariables>(
    SchoolsDocument,
    options,
  )
}
export function useSchoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolsQuery,
    SchoolsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SchoolsQuery, SchoolsQueryVariables>(
    SchoolsDocument,
    options,
  )
}
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>
export type SchoolsQueryResult = Apollo.QueryResult<
  SchoolsQuery,
  SchoolsQueryVariables
>
export const GradesDocument = gql`
  query Grades($input: SchoolGradesInput!) {
    grades(input: $input) {
      data {
        testYear
        schoolGrade
        numberOfClasses
        numberOfStudentsPerClass
      }
      count
      error
    }
  }
`

/**
 * __useGradesQuery__
 *
 * To run a query within a React component, call `useGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGradesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGradesQuery(
  baseOptions: Apollo.QueryHookOptions<GradesQuery, GradesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GradesQuery, GradesQueryVariables>(
    GradesDocument,
    options,
  )
}
export function useGradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GradesQuery, GradesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GradesQuery, GradesQueryVariables>(
    GradesDocument,
    options,
  )
}
export type GradesQueryHookResult = ReturnType<typeof useGradesQuery>
export type GradesLazyQueryHookResult = ReturnType<typeof useGradesLazyQuery>
export type GradesQueryResult = Apollo.QueryResult<
  GradesQuery,
  GradesQueryVariables
>
export const TeachersDocument = gql`
  query Teachers($input: TeachersInput!) {
    teachers(input: $input) {
      data {
        _id
        attributes {
          givenName
          givenNameHiragana
          familyName
          familyNameHiragana
          email
          role
          schoolGrade
          schoolClass
        }
      }
      count
      totalCount
      error
    }
  }
`

/**
 * __useTeachersQuery__
 *
 * To run a query within a React component, call `useTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeachersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeachersQuery(
  baseOptions: Apollo.QueryHookOptions<TeachersQuery, TeachersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TeachersQuery, TeachersQueryVariables>(
    TeachersDocument,
    options,
  )
}
export function useTeachersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeachersQuery,
    TeachersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TeachersQuery, TeachersQueryVariables>(
    TeachersDocument,
    options,
  )
}
export type TeachersQueryHookResult = ReturnType<typeof useTeachersQuery>
export type TeachersLazyQueryHookResult = ReturnType<
  typeof useTeachersLazyQuery
>
export type TeachersQueryResult = Apollo.QueryResult<
  TeachersQuery,
  TeachersQueryVariables
>
export const StudentsDocument = gql`
  query Students($input: StudentsInput!) {
    students(input: $input) {
      data {
        _id
        username
        attributes {
          givenName
          givenNameHiragana
          familyName
          familyNameHiragana
          birthday
          yearBirth
          monthBirth
          dayBirth
          gender
          schoolName
          schoolGrade
          schoolClass
          schoolAttendanceNumber
          email
          testResults {
            testYear
            points
            rank
            age
            grip {
              gripLeft1
              gripLeft2
              gripRight1
              gripRight2
              isNotMeasurable
              value
              points
              memo
              rating
            }
            sitUps {
              sitUps
              isNotMeasurable
              value
              points
              memo
              rating
            }
            bending {
              bending1
              bending2
              isNotMeasurable
              value
              points
              memo
              rating
            }
            sideJump {
              sideJump1
              sideJump2
              isNotMeasurable
              value
              points
              memo
              rating
            }
            shuttleRun {
              shuttleRunCount
              shuttleRunGainOxygen
              isNotMeasurable
              value
              points
              memo
              rating
            }
            sprintRun {
              sprintRunSeconds
              isNotMeasurable
              value
              points
              memo
              rating
            }
            standingJump {
              standingJump1
              standingJump2
              isNotMeasurable
              value
              points
              memo
              rating
            }
            handballThrow {
              handballThrow1
              handballThrow2
              isNotMeasurable
              value
              points
              memo
              rating
            }
            enduranceRun {
              runningTime
              runningTimeSeconds
              isNotMeasurable
              value
              points
              memo
              rating
            }
            sizeTest {
              height
              weight
            }
            questionnaireV3 {
              q1
              q2
              q3
              q4
              q5
              q6
              q7
              q8
              q9
              q10
              q11
              q12
              q13
              q14
              q15
              q16
              q17
              q18
              q19
              q20
              q21
              q22
              q23
              q24
              q25
              q26
              q27
              q28
              q29
              q30
              q31
              q32
              q33
              q34
              q35
              q36
              q37
              q38
              q39
              q40
              q41
              q42
              q43
              q44
              q45
              q46
              q47
              q48
              q49
              q50
            }
          }
          listAveragePoints {
            key
            title
            value
          }
          listAveragePointsByBirthMonth {
            key
            title
            value
          }
        }
      }
      count
      totalCount
      error
    }
  }
`

/**
 * __useStudentsQuery__
 *
 * To run a query within a React component, call `useStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentsQuery(
  baseOptions: Apollo.QueryHookOptions<StudentsQuery, StudentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StudentsQuery, StudentsQueryVariables>(
    StudentsDocument,
    options,
  )
}
export function useStudentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudentsQuery,
    StudentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StudentsQuery, StudentsQueryVariables>(
    StudentsDocument,
    options,
  )
}
export type StudentsQueryHookResult = ReturnType<typeof useStudentsQuery>
export type StudentsLazyQueryHookResult = ReturnType<
  typeof useStudentsLazyQuery
>
export type StudentsQueryResult = Apollo.QueryResult<
  StudentsQuery,
  StudentsQueryVariables
>
export const ReportCountSummaryDocument = gql`
  query ReportCountSummary($input: ReportCountSummaryInput!) {
    reportCountSummary(input: $input) {
      data {
        elementarySchoolReport {
          schoolCount
          reports {
            grade
            male {
              totalCount
              samplingCount
            }
            female {
              totalCount
              samplingCount
            }
          }
        }
        juniorHighSchoolReport {
          schoolCount
          reports {
            grade
            male {
              totalCount
              samplingCount
            }
            female {
              totalCount
              samplingCount
            }
          }
        }
        allDayHighSchoolReport {
          schoolCount
          reports {
            grade
            male {
              totalCount
              samplingCount
            }
            female {
              totalCount
              samplingCount
            }
          }
        }
        fixTimeHighSchoolReport {
          schoolCount
          reports {
            grade
            male {
              totalCount
              samplingCount
            }
            female {
              totalCount
              samplingCount
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportCountSummaryQuery__
 *
 * To run a query within a React component, call `useReportCountSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCountSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCountSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportCountSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportCountSummaryQuery,
    ReportCountSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportCountSummaryQuery,
    ReportCountSummaryQueryVariables
  >(ReportCountSummaryDocument, options)
}
export function useReportCountSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportCountSummaryQuery,
    ReportCountSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportCountSummaryQuery,
    ReportCountSummaryQueryVariables
  >(ReportCountSummaryDocument, options)
}
export type ReportCountSummaryQueryHookResult = ReturnType<
  typeof useReportCountSummaryQuery
>
export type ReportCountSummaryLazyQueryHookResult = ReturnType<
  typeof useReportCountSummaryLazyQuery
>
export type ReportCountSummaryQueryResult = Apollo.QueryResult<
  ReportCountSummaryQuery,
  ReportCountSummaryQueryVariables
>
export const ReportBodySummaryDocument = gql`
  query ReportBodySummary($input: ReportBodySummaryInput!) {
    reportBodySummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            grade
            sampleCount
            heightAvg
            weightAvg
            heightDev
            weightDev
          }
        }
        juniorHighSchoolReport {
          reports {
            grade
            sampleCount
            heightAvg
            weightAvg
            heightDev
            weightDev
          }
        }
        allDayHighSchoolReport {
          reports {
            grade
            sampleCount
            heightAvg
            weightAvg
            heightDev
            weightDev
          }
        }
        fixTimeHighSchoolReport {
          reports {
            grade
            sampleCount
            heightAvg
            weightAvg
            heightDev
            weightDev
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportBodySummaryQuery__
 *
 * To run a query within a React component, call `useReportBodySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportBodySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportBodySummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportBodySummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportBodySummaryQuery,
    ReportBodySummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportBodySummaryQuery,
    ReportBodySummaryQueryVariables
  >(ReportBodySummaryDocument, options)
}
export function useReportBodySummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportBodySummaryQuery,
    ReportBodySummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportBodySummaryQuery,
    ReportBodySummaryQueryVariables
  >(ReportBodySummaryDocument, options)
}
export type ReportBodySummaryQueryHookResult = ReturnType<
  typeof useReportBodySummaryQuery
>
export type ReportBodySummaryLazyQueryHookResult = ReturnType<
  typeof useReportBodySummaryLazyQuery
>
export type ReportBodySummaryQueryResult = Apollo.QueryResult<
  ReportBodySummaryQuery,
  ReportBodySummaryQueryVariables
>
export const ReportTestSummaryDocument = gql`
  query ReportTestSummary($input: ReportTestSummaryInput!) {
    reportTestSummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            grade
            average {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            deviation {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
        juniorHighSchoolReport {
          reports {
            grade
            average {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            deviation {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
        allDayHighSchoolReport {
          reports {
            grade
            average {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            deviation {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
        fixTimeHighSchoolReport {
          reports {
            grade
            average {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            deviation {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportTestSummaryQuery__
 *
 * To run a query within a React component, call `useReportTestSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTestSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTestSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTestSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportTestSummaryQuery,
    ReportTestSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportTestSummaryQuery,
    ReportTestSummaryQueryVariables
  >(ReportTestSummaryDocument, options)
}
export function useReportTestSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportTestSummaryQuery,
    ReportTestSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportTestSummaryQuery,
    ReportTestSummaryQueryVariables
  >(ReportTestSummaryDocument, options)
}
export type ReportTestSummaryQueryHookResult = ReturnType<
  typeof useReportTestSummaryQuery
>
export type ReportTestSummaryLazyQueryHookResult = ReturnType<
  typeof useReportTestSummaryLazyQuery
>
export type ReportTestSummaryQueryResult = Apollo.QueryResult<
  ReportTestSummaryQuery,
  ReportTestSummaryQueryVariables
>
export const ReportTestComparisonSummaryDocument = gql`
  query ReportTestComparisonSummary($input: ReportTestComparisonSummaryInput!) {
    reportTestComparisonSummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            national {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
        juniorHighSchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            national {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
        highSchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            national {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportTestComparisonSummaryQuery__
 *
 * To run a query within a React component, call `useReportTestComparisonSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTestComparisonSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTestComparisonSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTestComparisonSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportTestComparisonSummaryQuery,
    ReportTestComparisonSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportTestComparisonSummaryQuery,
    ReportTestComparisonSummaryQueryVariables
  >(ReportTestComparisonSummaryDocument, options)
}
export function useReportTestComparisonSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportTestComparisonSummaryQuery,
    ReportTestComparisonSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportTestComparisonSummaryQuery,
    ReportTestComparisonSummaryQueryVariables
  >(ReportTestComparisonSummaryDocument, options)
}
export type ReportTestComparisonSummaryQueryHookResult = ReturnType<
  typeof useReportTestComparisonSummaryQuery
>
export type ReportTestComparisonSummaryLazyQueryHookResult = ReturnType<
  typeof useReportTestComparisonSummaryLazyQuery
>
export type ReportTestComparisonSummaryQueryResult = Apollo.QueryResult<
  ReportTestComparisonSummaryQuery,
  ReportTestComparisonSummaryQueryVariables
>
export const ReportAverageScoreGraphSummaryDocument = gql`
  query ReportAverageScoreGraphSummary(
    $input: ReportAverageScoreGraphSummaryInput!
  ) {
    reportAverageScoreGraphSummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            year
            grades {
              grade
              score {
                grip
                sitUps
                bending
                sideJump
                shuttleRun
                sprintRun
                standingJump
                handballThrow
                totalScore
              }
            }
          }
        }
        juniorHighSchoolReport {
          reports {
            year
            grades {
              grade
              score {
                grip
                sitUps
                bending
                sideJump
                shuttleRun
                sprintRun
                standingJump
                handballThrow
                totalScore
              }
            }
          }
        }
        highSchoolReport {
          reports {
            year
            grades {
              grade
              score {
                grip
                sitUps
                bending
                sideJump
                shuttleRun
                sprintRun
                standingJump
                handballThrow
                totalScore
              }
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportAverageScoreGraphSummaryQuery__
 *
 * To run a query within a React component, call `useReportAverageScoreGraphSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportAverageScoreGraphSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportAverageScoreGraphSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportAverageScoreGraphSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportAverageScoreGraphSummaryQuery,
    ReportAverageScoreGraphSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportAverageScoreGraphSummaryQuery,
    ReportAverageScoreGraphSummaryQueryVariables
  >(ReportAverageScoreGraphSummaryDocument, options)
}
export function useReportAverageScoreGraphSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportAverageScoreGraphSummaryQuery,
    ReportAverageScoreGraphSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportAverageScoreGraphSummaryQuery,
    ReportAverageScoreGraphSummaryQueryVariables
  >(ReportAverageScoreGraphSummaryDocument, options)
}
export type ReportAverageScoreGraphSummaryQueryHookResult = ReturnType<
  typeof useReportAverageScoreGraphSummaryQuery
>
export type ReportAverageScoreGraphSummaryLazyQueryHookResult = ReturnType<
  typeof useReportAverageScoreGraphSummaryLazyQuery
>
export type ReportAverageScoreGraphSummaryQueryResult = Apollo.QueryResult<
  ReportAverageScoreGraphSummaryQuery,
  ReportAverageScoreGraphSummaryQueryVariables
>
export const ReportTestScoreChartSummaryDocument = gql`
  query ReportTestScoreChartSummary($input: ReportTestScoreChartSummaryInput!) {
    reportTestScoreChartSummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
              height
              weight
            }
          }
        }
        juniorHighSchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
              height
              weight
            }
          }
        }
        highSchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
              height
              weight
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportTestScoreChartSummaryQuery__
 *
 * To run a query within a React component, call `useReportTestScoreChartSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTestScoreChartSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTestScoreChartSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTestScoreChartSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportTestScoreChartSummaryQuery,
    ReportTestScoreChartSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportTestScoreChartSummaryQuery,
    ReportTestScoreChartSummaryQueryVariables
  >(ReportTestScoreChartSummaryDocument, options)
}
export function useReportTestScoreChartSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportTestScoreChartSummaryQuery,
    ReportTestScoreChartSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportTestScoreChartSummaryQuery,
    ReportTestScoreChartSummaryQueryVariables
  >(ReportTestScoreChartSummaryDocument, options)
}
export type ReportTestScoreChartSummaryQueryHookResult = ReturnType<
  typeof useReportTestScoreChartSummaryQuery
>
export type ReportTestScoreChartSummaryLazyQueryHookResult = ReturnType<
  typeof useReportTestScoreChartSummaryLazyQuery
>
export type ReportTestScoreChartSummaryQueryResult = Apollo.QueryResult<
  ReportTestScoreChartSummaryQuery,
  ReportTestScoreChartSummaryQueryVariables
>
export const ReportTestRankTargetSummaryDocument = gql`
  query ReportTestRankTargetSummary($input: ReportTestRankTargetSummaryInput!) {
    reportTestRankTargetSummary(input: $input) {
      data {
        reports {
          year
          totalRates
          elementarySchoolRates
          juniorHighSchoolRates
          highSchoolRates
        }
        national {
          year
          totalRates
          elementarySchoolRates
          juniorHighSchoolRates
          highSchoolRates
        }
      }
      error
    }
  }
`

/**
 * __useReportTestRankTargetSummaryQuery__
 *
 * To run a query within a React component, call `useReportTestRankTargetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTestRankTargetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTestRankTargetSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTestRankTargetSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportTestRankTargetSummaryQuery,
    ReportTestRankTargetSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportTestRankTargetSummaryQuery,
    ReportTestRankTargetSummaryQueryVariables
  >(ReportTestRankTargetSummaryDocument, options)
}
export function useReportTestRankTargetSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportTestRankTargetSummaryQuery,
    ReportTestRankTargetSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportTestRankTargetSummaryQuery,
    ReportTestRankTargetSummaryQueryVariables
  >(ReportTestRankTargetSummaryDocument, options)
}
export type ReportTestRankTargetSummaryQueryHookResult = ReturnType<
  typeof useReportTestRankTargetSummaryQuery
>
export type ReportTestRankTargetSummaryLazyQueryHookResult = ReturnType<
  typeof useReportTestRankTargetSummaryLazyQuery
>
export type ReportTestRankTargetSummaryQueryResult = Apollo.QueryResult<
  ReportTestRankTargetSummaryQuery,
  ReportTestRankTargetSummaryQueryVariables
>
export const ReportCrossAnalysisSummaryDocument = gql`
  query ReportCrossAnalysisSummary($input: ReportCrossAnalysisSummaryInput!) {
    reportCrossAnalysisSummary(input: $input) {
      data {
        reports {
          dataKey
          grades {
            schoolCategory
            grade
            answers {
              answer
              totalCount
              averages {
                score
                scoreType
              }
              deviations {
                score
                scoreType
              }
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportCrossAnalysisSummaryQuery__
 *
 * To run a query within a React component, call `useReportCrossAnalysisSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCrossAnalysisSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCrossAnalysisSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportCrossAnalysisSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportCrossAnalysisSummaryQuery,
    ReportCrossAnalysisSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportCrossAnalysisSummaryQuery,
    ReportCrossAnalysisSummaryQueryVariables
  >(ReportCrossAnalysisSummaryDocument, options)
}
export function useReportCrossAnalysisSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportCrossAnalysisSummaryQuery,
    ReportCrossAnalysisSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportCrossAnalysisSummaryQuery,
    ReportCrossAnalysisSummaryQueryVariables
  >(ReportCrossAnalysisSummaryDocument, options)
}
export type ReportCrossAnalysisSummaryQueryHookResult = ReturnType<
  typeof useReportCrossAnalysisSummaryQuery
>
export type ReportCrossAnalysisSummaryLazyQueryHookResult = ReturnType<
  typeof useReportCrossAnalysisSummaryLazyQuery
>
export type ReportCrossAnalysisSummaryQueryResult = Apollo.QueryResult<
  ReportCrossAnalysisSummaryQuery,
  ReportCrossAnalysisSummaryQueryVariables
>
export const ReportSchoolPerformanceSummaryDocument = gql`
  query ReportSchoolPerformanceSummary(
    $input: ReportSchoolPerformanceSummaryInput!
  ) {
    reportSchoolPerformanceSummary(input: $input) {
      data {
        elementarySchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
        juniorHighSchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
        highSchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
      }
      error
    }
  }
`

/**
 * __useReportSchoolPerformanceSummaryQuery__
 *
 * To run a query within a React component, call `useReportSchoolPerformanceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportSchoolPerformanceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportSchoolPerformanceSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportSchoolPerformanceSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportSchoolPerformanceSummaryQuery,
    ReportSchoolPerformanceSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportSchoolPerformanceSummaryQuery,
    ReportSchoolPerformanceSummaryQueryVariables
  >(ReportSchoolPerformanceSummaryDocument, options)
}
export function useReportSchoolPerformanceSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportSchoolPerformanceSummaryQuery,
    ReportSchoolPerformanceSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportSchoolPerformanceSummaryQuery,
    ReportSchoolPerformanceSummaryQueryVariables
  >(ReportSchoolPerformanceSummaryDocument, options)
}
export type ReportSchoolPerformanceSummaryQueryHookResult = ReturnType<
  typeof useReportSchoolPerformanceSummaryQuery
>
export type ReportSchoolPerformanceSummaryLazyQueryHookResult = ReturnType<
  typeof useReportSchoolPerformanceSummaryLazyQuery
>
export type ReportSchoolPerformanceSummaryQueryResult = Apollo.QueryResult<
  ReportSchoolPerformanceSummaryQuery,
  ReportSchoolPerformanceSummaryQueryVariables
>
export const ReportSchoolProgressSummaryDocument = gql`
  query ReportSchoolProgressSummary($input: ReportSchoolProgressSummaryInput!) {
    reportSchoolProgressSummary(input: $input) {
      data {
        elementarySchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
        juniorHighSchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
        highSchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
      }
      error
    }
  }
`

/**
 * __useReportSchoolProgressSummaryQuery__
 *
 * To run a query within a React component, call `useReportSchoolProgressSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportSchoolProgressSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportSchoolProgressSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportSchoolProgressSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportSchoolProgressSummaryQuery,
    ReportSchoolProgressSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportSchoolProgressSummaryQuery,
    ReportSchoolProgressSummaryQueryVariables
  >(ReportSchoolProgressSummaryDocument, options)
}
export function useReportSchoolProgressSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportSchoolProgressSummaryQuery,
    ReportSchoolProgressSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportSchoolProgressSummaryQuery,
    ReportSchoolProgressSummaryQueryVariables
  >(ReportSchoolProgressSummaryDocument, options)
}
export type ReportSchoolProgressSummaryQueryHookResult = ReturnType<
  typeof useReportSchoolProgressSummaryQuery
>
export type ReportSchoolProgressSummaryLazyQueryHookResult = ReturnType<
  typeof useReportSchoolProgressSummaryLazyQuery
>
export type ReportSchoolProgressSummaryQueryResult = Apollo.QueryResult<
  ReportSchoolProgressSummaryQuery,
  ReportSchoolProgressSummaryQueryVariables
>
export const ReportUploadListDocument = gql`
  query ReportUploadList($input: ReportUploadListInput!) {
    reportUploadList(input: $input) {
      data {
        prefectureSchoolCode
        schoolName
        studentCount
        cityName
        organizationName
        uploadedAt
      }
      count
      totalCount
      error
    }
  }
`

/**
 * __useReportUploadListQuery__
 *
 * To run a query within a React component, call `useReportUploadListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportUploadListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportUploadListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportUploadListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportUploadListQuery,
    ReportUploadListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportUploadListQuery, ReportUploadListQueryVariables>(
    ReportUploadListDocument,
    options,
  )
}
export function useReportUploadListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportUploadListQuery,
    ReportUploadListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportUploadListQuery,
    ReportUploadListQueryVariables
  >(ReportUploadListDocument, options)
}
export type ReportUploadListQueryHookResult = ReturnType<
  typeof useReportUploadListQuery
>
export type ReportUploadListLazyQueryHookResult = ReturnType<
  typeof useReportUploadListLazyQuery
>
export type ReportUploadListQueryResult = Apollo.QueryResult<
  ReportUploadListQuery,
  ReportUploadListQueryVariables
>
export const StudentInvitationCodesDocument = gql`
  query StudentInvitationCodes($input: StudentInvitationCodesInput!) {
    studentInvitationCodes(input: $input) {
      data {
        schoolGrade
        schoolClass
        code
        testYear
        students {
          attendanceNumber
          studentInvitationCode
          name
          familyName
          givenName
        }
      }
      error
    }
  }
`

/**
 * __useStudentInvitationCodesQuery__
 *
 * To run a query within a React component, call `useStudentInvitationCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentInvitationCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentInvitationCodesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentInvitationCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    StudentInvitationCodesQuery,
    StudentInvitationCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    StudentInvitationCodesQuery,
    StudentInvitationCodesQueryVariables
  >(StudentInvitationCodesDocument, options)
}
export function useStudentInvitationCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudentInvitationCodesQuery,
    StudentInvitationCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    StudentInvitationCodesQuery,
    StudentInvitationCodesQueryVariables
  >(StudentInvitationCodesDocument, options)
}
export type StudentInvitationCodesQueryHookResult = ReturnType<
  typeof useStudentInvitationCodesQuery
>
export type StudentInvitationCodesLazyQueryHookResult = ReturnType<
  typeof useStudentInvitationCodesLazyQuery
>
export type StudentInvitationCodesQueryResult = Apollo.QueryResult<
  StudentInvitationCodesQuery,
  StudentInvitationCodesQueryVariables
>
export const SchoolReportTestSummaryDocument = gql`
  query SchoolReportTestSummary($input: SchoolReportTestSummaryInput!) {
    schoolReportTestSummary(input: $input) {
      data {
        reports {
          testYear
          grade
          count
          height_avg
          weight_avg
          grip_avg
          sitUps_avg
          bending_avg
          sideJump_avg
          shuttleRun_avg
          sprintRun_avg
          standingJump_avg
          handballThrow_avg
          points_avg
          height_std
          weight_std
          grip_std
          sitUps_std
          bending_std
          sideJump_std
          shuttleRun_std
          sprintRun_std
          standingJump_std
          handballThrow_std
          points_std
          rank_a
          rank_b
          rank_c
          rank_d
          rank_e
        }
      }
      error
    }
  }
`

/**
 * __useSchoolReportTestSummaryQuery__
 *
 * To run a query within a React component, call `useSchoolReportTestSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolReportTestSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolReportTestSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolReportTestSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SchoolReportTestSummaryQuery,
    SchoolReportTestSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SchoolReportTestSummaryQuery,
    SchoolReportTestSummaryQueryVariables
  >(SchoolReportTestSummaryDocument, options)
}
export function useSchoolReportTestSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolReportTestSummaryQuery,
    SchoolReportTestSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchoolReportTestSummaryQuery,
    SchoolReportTestSummaryQueryVariables
  >(SchoolReportTestSummaryDocument, options)
}
export type SchoolReportTestSummaryQueryHookResult = ReturnType<
  typeof useSchoolReportTestSummaryQuery
>
export type SchoolReportTestSummaryLazyQueryHookResult = ReturnType<
  typeof useSchoolReportTestSummaryLazyQuery
>
export type SchoolReportTestSummaryQueryResult = Apollo.QueryResult<
  SchoolReportTestSummaryQuery,
  SchoolReportTestSummaryQueryVariables
>
export const SchoolReportTestScoreChartSummaryDocument = gql`
  query SchoolReportTestScoreChartSummary(
    $input: SchoolReportTestScoreChartSummaryInput!
  ) {
    schoolReportTestScoreChartSummary(input: $input) {
      data {
        reports {
          testYear
          grade
          chart {
            grip
            sitUps
            bending
            sideJump
            shuttleRun
            sprintRun
            standingJump
            handballThrow
            totalScore
            height
            weight
          }
        }
      }
    }
  }
`

/**
 * __useSchoolReportTestScoreChartSummaryQuery__
 *
 * To run a query within a React component, call `useSchoolReportTestScoreChartSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolReportTestScoreChartSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolReportTestScoreChartSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolReportTestScoreChartSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SchoolReportTestScoreChartSummaryQuery,
    SchoolReportTestScoreChartSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SchoolReportTestScoreChartSummaryQuery,
    SchoolReportTestScoreChartSummaryQueryVariables
  >(SchoolReportTestScoreChartSummaryDocument, options)
}
export function useSchoolReportTestScoreChartSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolReportTestScoreChartSummaryQuery,
    SchoolReportTestScoreChartSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchoolReportTestScoreChartSummaryQuery,
    SchoolReportTestScoreChartSummaryQueryVariables
  >(SchoolReportTestScoreChartSummaryDocument, options)
}
export type SchoolReportTestScoreChartSummaryQueryHookResult = ReturnType<
  typeof useSchoolReportTestScoreChartSummaryQuery
>
export type SchoolReportTestScoreChartSummaryLazyQueryHookResult = ReturnType<
  typeof useSchoolReportTestScoreChartSummaryLazyQuery
>
export type SchoolReportTestScoreChartSummaryQueryResult = Apollo.QueryResult<
  SchoolReportTestScoreChartSummaryQuery,
  SchoolReportTestScoreChartSummaryQueryVariables
>
export const QrCodeStudentDocument = gql`
  query QRCodeStudent($input: QRCodeStudentInput!) {
    qrCodeStudent(input: $input) {
      data {
        _id
        attributes {
          givenName
          givenNameHiragana
          familyName
          familyNameHiragana
          schoolName
          schoolGrade
          schoolClass
          schoolAttendanceNumber
          yearBirth
          monthBirth
          dayBirth
        }
      }
      error
    }
  }
`

/**
 * __useQrCodeStudentQuery__
 *
 * To run a query within a React component, call `useQrCodeStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQrCodeStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQrCodeStudentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQrCodeStudentQuery(
  baseOptions: Apollo.QueryHookOptions<
    QrCodeStudentQuery,
    QrCodeStudentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<QrCodeStudentQuery, QrCodeStudentQueryVariables>(
    QrCodeStudentDocument,
    options,
  )
}
export function useQrCodeStudentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QrCodeStudentQuery,
    QrCodeStudentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<QrCodeStudentQuery, QrCodeStudentQueryVariables>(
    QrCodeStudentDocument,
    options,
  )
}
export type QrCodeStudentQueryHookResult = ReturnType<
  typeof useQrCodeStudentQuery
>
export type QrCodeStudentLazyQueryHookResult = ReturnType<
  typeof useQrCodeStudentLazyQuery
>
export type QrCodeStudentQueryResult = Apollo.QueryResult<
  QrCodeStudentQuery,
  QrCodeStudentQueryVariables
>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
}
export default result
